import { Code, Flex } from "@mantine/core";
import React from "react";

function DocumentationRepos() {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden pb-[6em]">
      <header className="text-[2.5em] mt-4 text-white overflow-hidden">
        <span className="bg-[#1a1a1a] p-4 px-6 text-gray-400 border-[#333333]">
          deep-refactor repos
        </span>
      </header>
      <text className="text-gray-400 mt-8 w-3/4">
        The <span className="font-bold">repos</span> command provides a list of
        all your projects that are integrated with Deep Refactor. It outputs
        each project's name, programming languages used, creation date, and last
        modified timestamp.
      </text>
      <header className="text-[1.5em] text-white mt-8">Usage:</header>
      <Flex>
        <Code className="mt-4 p-2 px-4">deep-refactor repos</Code>
      </Flex>
      <header className="text-[1.5em] text-white mt-8">Output Example:</header>
      <div className="mt-4 p-2 px-4 bg-gray-900 border border-[#333333] rounded-sm">
        <text className="text-gray-400">
          Project Name: ProjectX
          <br />
          Languages: Python, JavaScript
          <br />
          Creation Date: 2023-01-01 12:00
          <br />
          Last Modified: 2023-01-15 16:30
          <br />
            <br />
          Project Name: ProjectY
          <br />
          ...
        </text>
      </div>
      <text className="text-violet-700 mt-8 w-3/4">
        Use this command to quickly assess and navigate through your portfolio
        of projects and to determine which project information you can view from the command line.
      </text>
    </div>
  );
}

export default DocumentationRepos;

import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Card,
  Flex,
  Menu,
  Modal,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import GitHub from "../Images/GithubLogo.svg";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { IconAnalyze, IconChevronDown, IconTrash } from "@tabler/icons-react";
import { FaCode, FaBug, FaLock, FaSitemap, FaSyncAlt } from "react-icons/fa";
import { SlDocs } from "react-icons/sl";
import { GoPackageDependencies } from "react-icons/go";
import { TbBrandSpeedtest } from "react-icons/tb";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { AiOutlineUser } from "react-icons/ai";
import { BsGraphUpArrow } from "react-icons/bs";
import BackgroundGradient from "../Images/BackgroundGradient.jpg";

function RepositoryCard({
  data,
  setAnalyzedRepos,
  setUserData,
  userData,
  activeJob,
}) {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [opened, { open, close }] = useDisclosure(false);

  const [isReanalysisOpen, setIsReanalysisOpen] = useState(false);

  const [enteredName, setEnteredName] = useState("");
  const [canDelete, setCanDelete] = useState(false);

  console.log(activeJob);

  const iconMapper = {
    CodeQuality: <FaCode />,
    DocumentationQuality: <SlDocs />,
    BugDetection: <FaBug />,
    SecurityAnalysis: <FaLock />,
    TestCoverage: <TbBrandSpeedtest />,
    PerformanceAnalysis: <BsGraphUpArrow />,
    AccessibilityAnalysis: <AiOutlineUser />,
    ArchitectureAnalysis: <FaSitemap />,
    ContinuousIntegration: <FaSyncAlt />,
    DependencyAnalysis: <GoPackageDependencies />,
  };

  function formatTimeAgo(timestamp) {
    const now = new Date();
    const modifiedTime = new Date(timestamp);
    console.log("now", now);
    console.log("modifiedTime", modifiedTime);
    const timeDifference = now - modifiedTime;

    // Define time intervals in milliseconds
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    const month = 30 * day;
    const year = 365 * day;

    if (timeDifference < minute) {
      return "just now";
    } else if (timeDifference < hour) {
      const minutesAgo = Math.floor(timeDifference / minute);
      return `${minutesAgo}m ago`;
    } else if (timeDifference < day) {
      const hoursAgo = Math.floor(timeDifference / hour);
      return `${hoursAgo}h ago`;
    } else if (timeDifference < month) {
      const daysAgo = Math.floor(timeDifference / day);
      return `${daysAgo}d ago`;
    } else if (timeDifference < year) {
      const monthsAgo = Math.floor(timeDifference / month);
      return `${monthsAgo}mo ago`;
    } else {
      const yearsAgo = Math.floor(timeDifference / year);
      return `${yearsAgo}y ago`;
    }
  }

  const formattedTime = formatTimeAgo(data?.last_modified);
  console.log("Data", data);

  function getUserFriendlyTitle(promptId) {
    if (!promptId) return "";

    const formattedId = promptId
      .split(/(?=[A-Z])/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");

    return formattedId;
  }

  async function handleReanalysis() {
    const token = await getAccessTokenSilently();
    try {
      const response = await axios.post(
        "https://api.deeprefactor.dev/start-reanalysis",
        {
          repoID: data?.repo_id,
          projectId: data?.project_id,
          choice: "FullAnalysis",
          choices: data?.prompts,
          project_name: data?.project_name,
          languages: data?.languages,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 500) {
        const newState = { ...userData };
        newState.analysis_count = userData.analysis_count + 1;
        setUserData(newState);
        window.location.reload(false);
      } else {
        console.error("Unexpected status code received:", response.status);
        alert("Failed to reanalyze the repository. Please try again.");
      }
    } catch (error) {
      console.error("Unexpected status code received:", error);
      alert("Failed to reanalyze the repository. Please try again.");
    }
  }

  async function handleDeleteRepo(repo_id) {
    const token = await getAccessTokenSilently();
    try {
      const response = await axios.delete(
        `https://api.deeprefactor.dev/model/repo/${repo_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        setAnalyzedRepos((prevRepos) =>
          prevRepos.filter((repo) => repo.repo_id !== repo_id)
        );
        const newState = { ...userData };
        newState.repo_count = userData.repo_count - 1;
        setUserData(newState);
        close();
      } else {
        console.error("Unexpected status code received:", response.status);
        alert("Failed to delete the repository. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting the repository:", error);
      alert("Failed to delete the repository. Please try again.");
    }
  }

  const handleInputChange = (event) => {
    const value = event.target.value;
    setEnteredName(value);

    if (value.toLowerCase() === data?.project_name.toLowerCase()) {
      setCanDelete(true);
    } else {
      setCanDelete(false);
    }
  };

  return (
    <>
      <Card
        style={{ cursor: "pointer", position: "relative", overflow: "visible", borderColor: "#373737" }}
        radius={"md"}
        shadow={"sm"}
        miw={410}
        w={435}
        h={250}
        withBorder
        className="Card"
        bg={'#1E1E1E'}
      >
        <Flex
          direction={"column"}
          align={"center"}
          justify={"space-between"}
          w={"100%"}
          h={"100%"}
          onClick={() => {
            navigate(`/analytics/${data?.repo_id}`);
          }}
        >
          <Flex align={"center"} w={"100%"} h={"35%"}>
            <Avatar
              radius={"50%"}
              color={"#7521BC"}
              size={"3.5em"}
              src={GitHub}
              style={{ border: 'solid 1px #CDCDCD', filter: 'invert(1)' }}
            ></Avatar>
            <Flex
              justify={"center"}
              direction={"column"}
              w={"100%"}
              h={"100%"}
              pl={"lg"}
            >
              <Text
                fz={"1.2em"}
                truncate="end"
                fw={800}
                lineClamp={1}
                maw={"82.5%"}
                color="#E0E0E0"
              >
                {data?.project_name}
              </Text>
              <Flex mt={"xs"} align={"center"} gap={"xs"} w={"100%"}>
                {data?.languages.map((language) => {
                  return (
                    <Badge variant={"outline"} color={"violet.8"}>
                      <Text>{language}</Text>
                    </Badge>
                  );
                })}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            pl={"xl"}
            pr={'xl'}
            w={"100%"}
            h={"45%"}
            direction={"column"}
            justify={"center"}
          >
            <Flex mt={'0.5em'} w={'100%'} gap={'10px'}>
              <Text color="#E0E0E0" fw={300}  w={'40%'}>Critical Issues:</Text><Text color="violet.8" align="end" w={'10%'} fw={500}>{data?.comment_counts.Critical || 0}</Text>
            </Flex>
            <Flex w={'100%'} gap={'sm'}>
              <Text color="#E0E0E0" fw={300} w={'40%'}>Medium Issues:</Text><Text color="violet.8" align="end" w={'10%'} fw={500}>{data?.comment_counts.Medium || 0}</Text>
            </Flex>
            <Flex w={'100%'} gap={'sm'}>
              <Text color="#E0E0E0" fw={300} w={'40%'}>Low Issues:</Text><Text color="violet.8"  align="end" w={'10%'} fw={500}>{data?.comment_counts.Low || 0}</Text>
            </Flex>
          </Flex>
          <Flex w={"100%"} h={"20%"} align={"flex-end"} justify={"flex-start"}>
            <Text color="#E0E0E0" fw={"light"} size={"sm"}>
              Last updated {formattedTime}
            </Text>
          </Flex>
        </Flex>
        <Menu
          shadow="xs"
          style={{ position: "absolute", top: "15px", right: "15px" }}
          position="bottom"
          transitionProps={{ transition: "rotate-right", duration: 150 }}
        >
          <Menu.Target>
            <ActionIcon>
              <IconChevronDown size={"1em"} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Actions</Menu.Label>
            {/* <Menu.Item
              disabled={activeJob !== undefined}
              onClick={() => setIsReanalysisOpen(true)}
              icon={<IconAnalyze size={"0.95em"} />}
            >
              Reanalyze
            </Menu.Item> */}
            <Menu.Divider mt={"1em"} />
            <Menu.Item
              onClick={open}
              icon={<IconTrash size={"0.95em"} />}
              color="red.7"
            >
              Delete Repository
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Card>
      <Modal size={"32.5vw"} opened={opened} onClose={close} centered>
        <Flex
          pl={"1.5em"}
          pr={"1.5em"}
          direction={"column"}
          w={"100%"}
          h={"100%"}
          pb="2em"
        >
          <Title order={3} align="left">
            Are you sure you want to delete repository{" "}
            <Text
              component="span"
              inherit
              variant="gradient"
              gradient={{ from: "violet.7", to: "violet.9" }}
            >
              {data?.project_name}
            </Text>
            ?
          </Title>
          <Text mt={"1.5em"}>
            This will{" "}
            <Text component="span" inherit fw={"bold"}>
              delete all
            </Text>{" "}
            feedback and resources associated with the repository and{" "}
            <Text component="span" inherit fw={"bold"}>
              cannot be undone.
            </Text>
          </Text>
          <Text mt={"3em"}>
            Type '
            <Text component="span" color="violet.8" inherit fw={"bold"}>
              {data?.project_name}
            </Text>
            ' to confirm
          </Text>
          <TextInput
            mt={"0.5em"}
            onChange={handleInputChange}
            value={enteredName}
          />
          <Flex mt={"2.5em"} align={"center"} justify={"flex-start"}>
            <Button
              disabled={!canDelete}
              onClick={() => handleDeleteRepo(data?.repo_id)}
              variant="outline"
              color="red.7"
            >
              Delete Repository
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <Modal
        size={"32.5vw"}
        opened={isReanalysisOpen}
        onClose={() => setIsReanalysisOpen(false)}
        centered
      >
        <Flex
          pl={"1.5em"}
          pr={"1.5em"}
          direction={"column"}
          w={"100%"}
          h={"100%"}
          pb="2em"
        >
          <Title order={3} align="left">
            <Text
              component="span"
              inherit
              variant="gradient"
              gradient={{ from: "violet.7", to: "violet.9" }}
            >
              {data?.project_name}
            </Text>{" "}
            Reanalysis - Confirmation Required
          </Title>
          <Text mt={"1.5em"}>
            Initiating a reanalysis of{" "}
            <Text component="span" inherit fw={"bold"}>
              {data?.project_name}
            </Text>{" "}
            will replace all existing feedback with new insights.
          </Text>
          <Text mt={'1.5em'}>This action is
            irreversible and will also integrate any recent changes made to the
            GitHub repository.</Text>
          <Flex mt={"2.5em"} align={"center"} justify={"flex-start"}>
            <Button
              onClick={() => handleReanalysis()}
              variant="filled"
              color="violet.7"
            >
              Confirm Reanalysis
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}

export default RepositoryCard;

import { Code, Flex } from "@mantine/core";
import React from "react";

function DocumentationFeedback() {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden pb-[6em]">
      <header className="text-[2.5em] mt-4 text-white">
        <span className="bg-[#1a1a1a] p-2 px-6 text-gray-400 border-[#333333]">
          deep-refactor feedback &lt;project_name&gt; &lt;file_path&gt;
        </span>
      </header>
      <text className="text-gray-400 mt-8 w-3/4">
        Use the <span className="font-bold">feedback</span> command to obtain
        specific comments and suggestions on a file within a project.
      </text>
      <header className="text-[1.5em] text-white mt-8">
        Required Arguments:
      </header>
      <ol className="text-gray-400 mt-4 list-decimal list-inside">
        <li className="mt-4">
          <span className="font-bold">&lt;project_name&gt;:</span> The name of
          the project you want feedback on.
        </li>
        <li className="mt-4">
          <span className="font-bold">&lt;file_path&gt;:</span> The path to the
          file within your project.
        </li>
      </ol>
      <header className="text-[1.5em] text-white mt-8">Usage:</header>
      <Flex>
        <Code className="mt-4 p-2 px-4">
          deep-refactor feedback &lt;project_name&gt; &lt;file_path&gt;
        </Code>
      </Flex>
      <header className="text-[1.5em] text-white mt-8">Example:</header>
      <Flex>
        <Code className="mt-4 p-2 px-4">
          deep-refactor feedback ProjectX src/main.py
        </Code>
      </Flex>
      <header className="text-[1.5em] mt-6 text-white">Output Example:</header>
      <div className="mt-4 p-2 px-4 bg-gray-900 border border-[#333333] rounded-sm">
        <text className="text-gray-400">
          Type: Code Quality <br />
          Severity: Low <br />
          Comment: Consider using named imports for better code readability{" "}
          <br />
          Recommendation: Instead of importing React as a default import, use
          named imports to improve code readability. For example, import React{" "}
          from 'react'; <br />
          Line(s): 1 <br />
          <br />
          Type: Code Quality <br />
          Severity: Low <br />
          Comment: Consider using a more descriptive name for the 'index.css'
          file <br />
          Recommendation: Rename 'index.css' to a more descriptive name that
          reflects its purpose or content. <br />
          Line(s): 3 <br />
        </text>
      </div>
      <text className="text-violet-700 mt-8 w-3/4">
        The command will provide detailed feedback for the specified file in the
        given project.
      </text>
    </div>
  );
}

export default DocumentationFeedback;

import React from "react";
import { Code, Flex } from "@mantine/core";

function DocumentationClearFeedback() {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden pb-[6em]">
      <header className="text-[2.5em] mt-4 text-white">
        <span className="bg-[#1a1a1a] p-2 px-6 text-gray-400 border-[#333333]">
          deep-refactor clear-feedback
        </span>
      </header>
      <text className="text-gray-400 mt-8 w-3/4">
        The <span className="font-bold">clear-feedback</span> command is used to permanently delete all locally stored feedback. This operation is not reversible, so use it with caution.
      </text>
      <header className="text-[1.5em] text-white mt-8">Usage:</header>
      <Flex>
        <Code className="mt-4 p-2 px-4">
          deep-refactor clear-feedback
        </Code>
      </Flex>
      <header className="text-[1.5em] mt-6 text-white">Warning:</header>
      <text className="text-red-600 mt-4 w-3/4">
        Executing this command will remove all feedback files stored on your local machine. This action cannot be undone.
      </text>
      <header className="text-[1.5em] text-white mt-8">Output Example:</header>
      
      <text className="text-violet-700 mt-8 w-3/4">
        Note: Clearing local feedback does not affect any data stored on the Deep Refactor platform.
      </text>
    </div>
  );
}

export default DocumentationClearFeedback;

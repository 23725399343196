import React from "react";

function SVGCardIcon({ id }) {
  switch (id) {
    case 0:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_238_1284)">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M50 0H0V100C0 155.228 44.7715 200 100 200C155.228 200 200 155.228 200 100V0H150C122.386 0 100 22.3858 100 50C100 22.3858 77.6142 0 50 0Z"
              fill="url(#paint0_linear_238_1284)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_238_1284"
              x1="100"
              y1="0"
              x2="100"
              y2="200"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop stop-color="#A7B5FF" />{" "}
              <stop offset="1" stop-color="#F3ACFF" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_238_1284">
              {" "}
              <rect width="200" height="200" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
    case 1:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_234_920)">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 100L4.37114e-06 0L100 4.37114e-06C100 55.2285 55.2285 100 0 100ZM200 100C200 44.7716 155.228 1.88558e-05 100 4.37114e-06L100 100L6.5969e-06 100C1.76868e-06 155.228 44.7715 200 100 200H200L200 100ZM199.961 100L100 100L100 200C100 144.785 144.75 100.021 199.961 100Z"
              fill="url(#paint0_linear_234_920)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_234_920"
              x1="157.5"
              y1="32"
              x2="44"
              y2="147.5"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop offset="0.0509862" stop-color="#FFB6E1" />{" "}
              <stop offset="1" stop-color="#FBE3EA" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_234_920">
              {" "}
              <rect width="200" height="200" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
    case 2:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_235_970)">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 100L7.62939e-06 0H100H200V100C144.78 100 100.013 55.2417 100 0.0239258C99.987 55.2417 55.2204 100 0 100ZM100 200C100 144.771 144.772 100 200 100V200H100ZM100 200C100 144.771 55.2285 100 0 100V200H100Z"
              fill="url(#paint0_linear_235_970)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_235_970"
              x1="20.5"
              y1="16"
              x2="100"
              y2="200"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop stop-color="#ACAAFF" />{" "}
              <stop offset="1" stop-color="#C0E8FF" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_235_970">
              {" "}
              <rect width="200" height="200" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
    case 3:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_238_1184)">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.00242398 200C0.000809232 199.834 0 199.667 0 199.5C0 174.111 18.7366 153.097 43.1371 149.533C18.7701 146.188 0 125.286 0 100C0 72.3858 22.3858 50 50 50H50.5C22.7765 50 0.270718 27.6601 0.00242398 0H199.998C199.729 27.6601 177.224 50 149.5 50H150C177.614 50 200 72.3858 200 100C200 125.286 181.23 146.188 156.863 149.533C181.263 153.097 200 174.111 200 199.5C200 199.667 199.999 199.834 199.998 200H0.00242398Z"
              fill="url(#paint0_linear_238_1184)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_238_1184"
              x1="177"
              y1="-9.23648e-06"
              x2="39.5"
              y2="152.5"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop stop-color="#B0B9FF" />{" "}
              <stop offset="1" stop-color="#E7E9FF" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_238_1184">
              {" "}
              <rect width="200" height="200" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
    case 4:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_236_1011)">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 200L1.63717e-05 100L2.18557e-06 -1.52588e-05L100 -1.96299e-05L200 -8.74228e-06L200 100L200 200L100 200L0 200ZM100 149.973C100.015 122.371 122.395 100 150 100C122.386 100 100 77.6142 100 50C100 77.6142 77.6142 100 50 100C77.6052 100 99.9854 122.371 100 149.973ZM50 126C63.2548 126 74 136.745 74 150C74 163.255 63.2548 174 50 174C36.7452 174 26 163.255 26 150C26 136.745 36.7452 126 50 126ZM74 50C74 63.2548 63.2548 74 50 74C36.7452 74 26 63.2548 26 50C26 36.7451 36.7452 26 50 26C63.2548 26 74 36.7451 74 50ZM126 150C126 136.745 136.745 126 150 126C163.255 126 174 136.745 174 150C174 163.255 163.255 174 150 174C136.745 174 126 163.255 126 150ZM126 50C126 63.2548 136.745 74 150 74C163.255 74 174 63.2548 174 50C174 36.7452 163.255 26 150 26C136.745 26 126 36.7451 126 50Z"
              fill="url(#paint0_linear_236_1011)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_236_1011"
              x1="-4.37114e-06"
              y1="100"
              x2="200"
              y2="100"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop stop-color="#DF99F7" />{" "}
              <stop offset="1" stop-color="#FFDBB0" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_236_1011">
              {" "}
              <rect
                width="200"
                height="200"
                fill="white"
                transform="translate(0 200) rotate(-90)"
              />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
    case 5:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_234_854)">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M44 0H0V150C0 177.614 22.3858 200 50 200H94V50C94 22.3858 71.6142 0 44 0ZM156 0C128.386 0 106 22.3858 106 50V200H150C177.614 200 200 177.614 200 150V0H156Z"
              fill="url(#paint0_linear_234_854)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_234_854"
              x1="100"
              y1="0"
              x2="100"
              y2="200"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop stop-color="#DF99F7" />{" "}
              <stop offset="1" stop-color="#FFDBB0" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_234_854">
              {" "}
              <rect width="200" height="200" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
    case 6:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32 100.641C32 68.391 54.1651 41.3515 84 34.1102V1.28125C36.3772 8.98855 0 50.5392 0 100.641C0 150.742 36.3772 192.293 84 200V167.171C54.1651 159.93 32 132.89 32 100.641ZM200 100.641C200 150.742 163.623 192.293 116 200V167.171C145.835 159.93 168 132.89 168 100.641C168 68.391 145.835 41.3515 116 34.1102V1.28125C163.623 8.98855 200 50.5392 200 100.641Z"
            fill="url(#paint0_linear_231_555)"
          />{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_231_555"
              x1="157.5"
              y1="33.0763"
              x2="44.7421"
              y2="148.561"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop offset="0.0509862" stop-color="#FFB6E1" />{" "}
              <stop offset="1" stop-color="#FBE3EA" />{" "}
            </linearGradient>{" "}
          </defs>{" "}
        </svg>
      );
    case 7:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_221_10)">
            {" "}
            <path
              d="M0 0H100C155.228 0 200 44.7715 200 100V200H100C44.7715 200 0 155.228 0 100V0Z"
              fill="url(#paint0_linear_221_10)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_221_10"
              x1="100"
              y1="0"
              x2="100"
              y2="200"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop stop-color="#A7B5FF" />{" "}
              <stop offset="1" stop-color="#F3ACFF" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_221_10">
              {" "}
              <rect width="200" height="200" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
    case 8:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <path
            d="M165.963 134.037C160.496 139.504 151.631 139.504 146.164 134.037L122.027 109.899C116.559 104.432 116.559 95.5678 122.027 90.1005L146.164 65.9631C151.631 60.4957 160.496 60.4957 165.963 65.9631L190.101 90.1004C195.568 95.5678 195.568 104.432 190.101 109.899L165.963 134.037ZM53.8359 134.037C48.3686 139.504 39.5042 139.504 34.0369 134.037L9.89952 109.899C4.43218 104.432 4.43217 95.5678 9.89951 90.1005L34.0369 65.9631C39.5042 60.4957 48.3686 60.4957 53.8359 65.9631L77.9733 90.1004C83.4406 95.5678 83.4406 104.432 77.9733 109.899L53.8359 134.037ZM109.9 190.1C104.432 195.568 95.5679 195.568 90.1005 190.1L65.9631 165.963C60.4958 160.496 60.4958 151.631 65.9631 146.164L90.1005 122.027C95.5679 116.559 104.432 116.559 109.9 122.027L134.037 146.164C139.504 151.631 139.504 160.496 134.037 165.963L109.9 190.1ZM109.9 77.9732C104.432 83.4405 95.5679 83.4406 90.1005 77.9732L65.9631 53.8358C60.4958 48.3685 60.4958 39.5042 65.9631 34.0368L90.1005 9.89946C95.5679 4.43212 104.432 4.43211 109.9 9.89945L134.037 34.0368C139.504 39.5042 139.504 48.3685 134.037 53.8358L109.9 77.9732Z"
            fill="url(#paint0_linear_105_379)"
          />{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_105_379"
              x1="154.166"
              y1="35.9433"
              x2="47.2475"
              y2="144.745"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop offset="0.0509862" stop-color="#FFB6E1" />{" "}
              <stop offset="1" stop-color="#FBE3EA" />{" "}
            </linearGradient>{" "}
          </defs>{" "}
        </svg>
      );
    case 9:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <path
            d="M0 47.4235C0 38.0237 6.53608 29.9057 15.7703 28.1488C36.4827 24.2081 73.3424 18 100 18C126.658 18 163.517 24.2081 184.23 28.1488C193.464 29.9057 200 38.0237 200 47.4235V150.374C200 159.424 193.931 167.333 185.12 169.396C164.683 174.181 127.351 181.934 100 181.934C72.6487 181.934 35.3172 174.181 14.8798 169.396C6.06883 167.333 0 159.424 0 150.374V47.4235Z"
            fill="url(#paint0_linear_105_435)"
          />{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_105_435"
              x1="100"
              y1="18"
              x2="100"
              y2="181.934"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop stop-color="#A7B5FF" />{" "}
              <stop offset="1" stop-color="#F3ACFF" />{" "}
            </linearGradient>{" "}
          </defs>{" "}
        </svg>
      );
    case 10:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_105_666)">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M100 22C100 9.84974 90.1503 0 78 0H22C9.84974 0 0 9.84972 0 22V78.7194C0 90.8697 9.84974 100.719 22 100.719H78C90.1503 100.719 100 110.569 100 122.719V178C100 190.15 109.85 200 122 200H178C190.15 200 200 190.15 200 178V121.28C200 109.13 190.15 99.2805 178 99.2805H122C109.85 99.2805 100 89.4308 100 77.2805V22Z"
              fill="url(#paint0_linear_105_666)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_105_666"
              x1="14"
              y1="26"
              x2="179"
              y2="179.5"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop stop-color="#E9B8FF" />{" "}
              <stop offset="1" stop-color="#F9ECFF" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_105_666">
              {" "}
              <rect width="200" height="200" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
    case 11:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_119_246)">
            {" "}
            <path
              d="M99.9937 200V184.439C49.0583 184.439 0 150.932 0 100H15.5495C15.5495 49.0678 49.0583 0 99.9937 0V15.5612C150.929 15.5612 200 49.0678 200 100H184.451C184.451 150.932 150.929 200 99.9937 200Z"
              fill="url(#paint0_linear_119_246)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_119_246"
              x1="157.5"
              y1="32"
              x2="44"
              y2="147.5"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop offset="0.0509862" stop-color="#FFB6E1" />{" "}
              <stop offset="1" stop-color="#FBE3EA" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_119_246">
              {" "}
              <rect width="200" height="200" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
    case 12:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_133_21)">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M99.9759 100C44.7585 99.987 -2.80187e-06 55.2204 -7.62939e-06 1.74846e-05L200 0C200 55.2204 155.242 99.987 100.024 100C155.242 100.013 200 144.78 200 200H1.11288e-06C1.11288e-06 144.78 44.7585 100.013 99.9759 100Z"
              fill="url(#paint0_linear_133_21)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_133_21"
              x1="157.5"
              y1="32"
              x2="44"
              y2="147.5"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop offset="0.0509862" stop-color="#FFB6E1" />{" "}
              <stop offset="1" stop-color="#FBE3EA" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_133_21">
              {" "}
              <rect width="200" height="200" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
    default:
      return (
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <g clip-path="url(#clip0_133_21)">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M99.9759 100C44.7585 99.987 -2.80187e-06 55.2204 -7.62939e-06 1.74846e-05L200 0C200 55.2204 155.242 99.987 100.024 100C155.242 100.013 200 144.78 200 200H1.11288e-06C1.11288e-06 144.78 44.7585 100.013 99.9759 100Z"
              fill="url(#paint0_linear_133_21)"
            />{" "}
          </g>{" "}
          <defs>
            {" "}
            <linearGradient
              id="paint0_linear_133_21"
              x1="157.5"
              y1="32"
              x2="44"
              y2="147.5"
              gradientUnits="userSpaceOnUse"
            >
              {" "}
              <stop offset="0.0509862" stop-color="#FFB6E1" />{" "}
              <stop offset="1" stop-color="#FBE3EA" />{" "}
            </linearGradient>{" "}
            <clipPath id="clip0_133_21">
              {" "}
              <rect width="200" height="200" fill="white" />{" "}
            </clipPath>{" "}
          </defs>{" "}
        </svg>
      );
  }
}

export default SVGCardIcon;

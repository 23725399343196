import React, { useState } from "react";
import "../App.css";
import DeepRefactorLogo from "../Images/DeepRefactorLogo.png";
import { Image, TextInput, Button } from "@mantine/core";
import { FaDesktop, FaSearch } from "react-icons/fa";
import DocumentationIntro from "../Components/DocumentationIntro";
import DocumentationLogin from "../Components/DocumentationLogin";
import DocumentationLogout from "../Components/DocumentationLogout";
import DocumentationRepos from "../Components/DocumentationRepos";
import DocumentationRepo from "../Components/DocumentationRepo";
import DocumentationFeedback from "../Components/DocumentationFeedback";
import DocumentationAnalyze from "../Components/DocumentationAnalyze";
import DocumentationLocalFeedback from "../Components/DocumentationLocalFeedback";
import DocumentationClearFeedback from "../Components/DocumentationClearFeedback";
import DocumentationPlatformAnalysis from "../Components/DocumentationPlatformAnalysis";
import DocumentationLocalAnalysis from "../Components/DocumentationLocalAnalysis";
import { useNavigate } from "react-router-dom";

const DocumentationPage = () => {
  const commandData = [
    {
      command: "login",
      text: "Authenticate with your API key. You will be prompted to enter the key.",
      code: "deep-refactor-cli login",
      subtext:
        "This command logs you into the Deep Refactor CLI. It is necessary for performing operations that require API access.",
    },
    {
      command: "logout",
      text: "Log out of the CLI and clear your session.",
      code: "deep-refactor-cli logout",
      subtext:
        "Executing this command will securely log you out of the CLI, clearing your session information.",
    },
    {
      command: "repos",
      text: "Lists all repositories associated with your account.",
      code: "deep-refactor-cli repos",
      subtext:
        "Use this command to fetch a list of all your projects and their details like project name, programming languages, creation date, and last modified date.",
    },
    {
      command: "repo",
      text: "Get detailed information about a specific repository.",
      code: "deep-refactor-cli repo [project_name]",
      subtext:
        "Use this command to fetch detailed information about a specific project by providing the project name.",
    },
    {
      command: "feedback",
      text: "Retrieve feedback for a specific file in a project.",
      code: "deep-refactor-cli feedback [project_name] [file_path]",
      subtext:
        "Use this command to get detailed feedback about a specific file in a project. You need to specify the project name and the file path.",
    },
    {
      command: "analyze",
      text: "Analyze a file for specific criteria.",
      code: "deep-refactor-cli analyze [file_path] [analysis_types]",
      subtext:
        "Use this command to analyze a file. Specify the file path and the types of analysis to perform, such as code quality or bug detection.",
    },
    {
      command: "local-feedback",
      text: "Retrieve locally saved feedback for a file.",
      code: "deep-refactor-cli local-feedback [file_path]",
      subtext:
        "This command retrieves feedback that is saved locally for a specific file. Provide the file path as an argument.",
    },
    {
      command: "clear-feedback",
      text: "Clear all saved feedback files.",
      code: "deep-refactor-cli clear-feedback",
      subtext:
        "Use this command to clear all feedback files that are saved locally. This operation cannot be undone.",
    },
  ];

  const [activePage, setActivePage] = useState("Introduction");

  const handleNavItemClick = (page) => {
    setActivePage(page);
  };

  const renderActivePage = () => {
    switch (activePage) {
      case "Introduction":
        return <DocumentationIntro />;
      case "Login":
        return <DocumentationLogin />;
      case "Logout":
        return <DocumentationLogout />;
      case "Repos":
        return <DocumentationRepos />;
      case "Repo":
        return <DocumentationRepo />;
      case "Feedback":
        return <DocumentationFeedback />;
      case "Analyze":
        return <DocumentationAnalyze />;
      case "LocalFeedback":
        return <DocumentationLocalFeedback />;
      case "ClearFeedback":
        return <DocumentationClearFeedback />;
      case "PlatformAnalysis":
        return <DocumentationPlatformAnalysis />;
      case "LocalAnalysis":
        return <DocumentationLocalAnalysis />;

      default:
        return <DocumentationIntro />;
    }
  };

  const navigate = useNavigate();

  return (
    <div className="h-full w-full overflow-hidden bg-[#121212] flex flex-col">
      <div className="flex justify-between items-center p-4 bg-[#1a1a1a] border-b border-[#333333]">
        <Image
          onClick={() => {
            navigate("/");
          }}
          className="cursor-pointer"
          src={DeepRefactorLogo}
          width={225}
        />
        <header className="text-violet-600 font-semibold text-[1.25em]">
          Command Line Interface Documentation
        </header>
      </div>

      <div className="w-full h-full flex flex-row">
        <div className="flex flex-col items-center w-[300px] bg-[#1a1a1a] h-full border-r border-[#333333] p-4 pt-[2em] pb-[3em]">
          <div
            className={`w-full pl-4 p-2 font-medium text-white cursor-pointer border-l-2 ${
              activePage === "Introduction"
                ? "border-violet-500"
                : "border-[#2B3029]"
            }`}
            onClick={() => handleNavItemClick("Introduction")}
          >
            Introduction
          </div>

          <div className="w-full pl-4 p-2 font-medium font-white border-l-2 cursor-default  text-violet-700 border-[#2B3029]">
            Authentication
          </div>
          <div
            onClick={() => handleNavItemClick("Login")}
            className={`w-full pl-8 p-2 font-medium font-white text-gray-600 cursor-pointer border-l-2 ${
              activePage === "Login" ? "border-violet-500" : "border-[#2B3029]"
            }`}
          >
            login
          </div>
          <div
            onClick={() => handleNavItemClick("Logout")}
            className={`w-full pl-8 p-2 font-medium font-white text-gray-600 cursor-pointer border-l-2 ${
              activePage === "Logout" ? "border-violet-500" : "border-[#2B3029]"
            }`}
          >
            logout
          </div>
          <div className="w-full pl-4 p-2 font-medium font-white border-l-2 cursor-default text-violet-700 border-[#2B3029]">
            Reference
          </div>
          <div
            onClick={() => handleNavItemClick("Repos")}
            className={`w-full pl-8 p-2 font-medium font-white border-l-2 text-gray-600 cursor-pointer border-[#2B3029] ${
              activePage === "Repos" ? "border-violet-500" : "border-[#2B3029]"
            }`}
          >
            repos
          </div>
          <div
            onClick={() => handleNavItemClick("Repo")}
            className={`w-full pl-8 p-2 font-medium font-white text-gray-600 cursor-pointer border-l-2 ${
              activePage === "Repo" ? "border-violet-500" : "border-[#2B3029]"
            }`}
          >
            repo
          </div>
          <div
            onClick={() => handleNavItemClick("Feedback")}
            className={`w-full pl-8 p-2 font-medium font-white text-gray-600 cursor-pointer border-l-2 ${
              activePage === "Feedback"
                ? "border-violet-500"
                : "border-[#2B3029]"
            }`}
          >
            feedback
          </div>
          <div
            onClick={() => handleNavItemClick("Analyze")}
            className={`w-full pl-8 p-2 font-medium font-white text-gray-600 cursor-pointer border-l-2 ${
              activePage === "Analyze"
                ? "border-violet-500"
                : "border-[#2B3029]"
            }`}
          >
            analyze
          </div>
          <div
            onClick={() => handleNavItemClick("LocalFeedback")}
            className={`w-full pl-8 p-2 font-medium font-white text-gray-600 cursor-pointer border-l-2 ${
              activePage === "LocalFeedback"
                ? "border-violet-500"
                : "border-[#2B3029]"
            }`}
          >
            local-feedback
          </div>
          <div
            onClick={() => handleNavItemClick("ClearFeedback")}
            className={`w-full pl-8 p-2 font-medium font-white text-gray-600 cursor-pointer border-l-2 ${
              activePage === "ClearFeedback"
                ? "border-violet-500"
                : "border-[#2B3029]"
            }`}
          >
            clear-feedback
          </div>
          <div
            className={`w-full pl-4 p-2 font-medium text-violet-700 cursor-default border-l-2 border-[#2B3029]`}
          >
            Example Workflows
          </div>
          <div
            onClick={() => handleNavItemClick("PlatformAnalysis")}
            className={`w-full pl-8 p-2 font-medium font-white text-gray-600 cursor-pointer border-l-2 ${
              activePage === "PlatformAnalysis"
                ? "border-violet-500"
                : "border-[#2B3029]"
            }`}
          >
            Platform Analysis
          </div>
          <div
            onClick={() => handleNavItemClick("LocalAnalysis")}
            className={`w-full pl-8 p-2 font-medium font-white text-gray-600 cursor-pointer border-l-2 ${
              activePage === "LocalAnalysis"
                ? "border-violet-500"
                : "border-[#2B3029]"
            }`}
          >
            Local Analysis
          </div>
        </div>
        <div className="Doc-Background flex flex-col bg-[#1a1a1a]/[0.35] p-[2em] pb-[3em] pl-[4em] w-full h-full">
          {renderActivePage()}
        </div>
      </div>
    </div>
  );
};

export default DocumentationPage;

import React, { useEffect, useState, MouseEvent, useCallback, useRef } from "react";
import { Title, Text, Flex, Divider, ActionIcon } from "@mantine/core";

import DeepRefactorLogo from "../Images/DeepRefactorLogo.png";
import { FaDice, FaDownload } from "react-icons/fa";
import { IconDownload } from "@tabler/icons-react";
import SVGCardIcon from "../Components/SVGCardIcon";

function ProfileCard({ userInfo }) {
  const [gradientColors, setGradientColors] = useState({
    color1: "",
    color2: "",
  });
  const [svgNumber, setSVGNumber] = useState(0);

  const generateGradientFromEmail = (email) => {
    let hash = 0;
    for (let i = 0; i < email.length; i++) {
      hash = email.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Constrain hue between 200-280 for variations of purple, blue, cyan
    const hue1 = (Math.abs(hash) % 80) + 200; // This will give you a hue between 200 and 280
    const hue2 = (hue1 + 80) % 360; // This ensures the second color is different enough

    // You can adjust saturation and lightness as needed, here are 100% and 50% for full color saturation and moderate brightness
    const saturation = "100%";
    const lightness = "50%";

    const color1 = `hsl(${hue1}, ${saturation}, ${lightness})`;
    const color2 = `hsl(${hue2}, ${saturation}, ${lightness})`;
    const uniqueNumber = Math.abs(hash) % 13;

    return { color1, color2, uniqueNumber };
  };

  useEffect(() => {
    const { color1, color2, uniqueNumber } = generateGradientFromEmail(
      userInfo?.email
    );
    setGradientColors({ color1, color2 });
    setSVGNumber(uniqueNumber);
  }, [userInfo]);

  const [rotate, setRotate] = useState({ x: 0, y: 0 });
  const cardRef = useRef(null); // Reference to the card element

  // A throttle function to limit the number of event calls
  const throttle = (callback, delay) => {
    let lastCall = 0;
    return (...args) => {
      const now = new Date().getTime();
      if (now - lastCall < delay) return;
      lastCall = now;
      callback(...args);
    };
  };

  // Event handler for mouse movement
  const onMouseMove = throttle((e) => {
    if (cardRef.current) {
      const box = cardRef.current.getBoundingClientRect();
      const x = e.clientX - box.left - box.width / 2;
      const y = e.clientY - box.top - box.height / 2;
      const rotateX = (y / box.height) * 20; // Tilt strength, adjust as needed
      const rotateY = (x / box.width) * 20; // Tilt strength, adjust as needed
      setRotate({ x: rotateX, y: rotateY });
    }
  }, 100);

  // Reset the rotation when the mouse leaves the card
  const onMouseLeave = () => {
    setRotate({ x: 0, y: 0 });
  };

  return (
    <>
      <style>
        {`
      .glow-effect::after {
        content: '';
        position: absolute;
        top: 2px; right: 2px; bottom: 2px; left: 2px;
        z-index: -1;
        margin: -1px;
        border-radius: inherit;
        background: linear-gradient(45deg, ${gradientColors.color1}, ${gradientColors.color2});
        filter: blur(6px);
        animation: glowing 2s infinite alternate;
      }
    `}
      </style>
      <div
        ref={cardRef}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        className="relative z-20 h-[475px] w-[325px] p-6 flex items-center justify-center flex-col rounded-lg bg-[#121212] border overflow-hidden border-[#333333] card glow-effect"
        style={{
          transform: `perspective(1000px) rotateX(${rotate.x}deg) rotateY(${rotate.y}deg)`,
          transition: "transform 0.1s", // Smoother transition, adjust timing as needed
        }}
      >
        <div className="w-full h-[50%] flex items-center justify-center">
          <SVGCardIcon id={svgNumber} />
        </div>

        <div className="h-[50%] flex justify-center flex-col w-full pt-4">
          <p
            className="text-lg font-extrabold"
            style={{
              backgroundImage: `linear-gradient(to right, ${gradientColors.color1}, ${gradientColors.color2})`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {userInfo?.email}
          </p>
          <p className="text-sm mb-2 mt-1 font-bold text-gray-300">
            Innovator • Engineer
          </p>
          <div className="w-2/5 mb-2 mt-1 h-px bg-gray-600" />
          <div className="w-full mt-2 flex justify-between items-center">
            <p className="text-sm font-regular text-gray-300">Repositories </p>
            <p className="text-sm font-regular text-gray-300">{userInfo?.repo_count || 0}</p>
          </div>
          <div className="w-full mt-2 flex justify-between items-center">
            <p className="text-sm font-regular text-gray-300">
              Developer Rating{" "}
            </p>
            <p className="text-sm font-regular text-gray-300">{userInfo?.score || 0.0}</p>
          </div>
        </div>
        <div className="w-full mt-2 flex justify-between items-center">
          <img src={DeepRefactorLogo} className="w-auto h-[20px]" />
          {/* <div className="flex justify-center gap-2 items-center">
            <ActionIcon size={"sm"} variant="transparent" color="#144EA5">
              <IconDownload color="#7521BC" />
            </ActionIcon>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ProfileCard;

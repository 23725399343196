import React from "react";

function DocumentationIntro() {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden pb-[6em]">
      <header className="text-[2.5em] text-white">
        Introduction to Deep Refactor CLI
      </header>
      <header className="text-[1.5em] text-white mt-8">
        Purpose of the CLI
      </header>
      <text className="text-gray-400 mt-4 w-3/4">
        The Deep Refactor CLI is designed to simplify and enhance the
        development experience by offering real-time, AI-powered code analysis
        directly from the command line. Whether you're a solo developer or part
        of a team, our CLI integrates into your workflow to help identify issues
        early, improve code quality, and streamline your development process.
      </text>
      <header className="text-[1.5em] text-white mt-8">Benefits</header>
      <text className="text-gray-400 mt-4">
        <strong className="text-violet-500">Real-time Analysis:</strong>{" "}
        Instantly receive feedback on your code quality, documentation,
        performance, and more.
      </text>
      <text className="text-gray-400 mt-4">
        <strong className="text-violet-500">Seamless Integration:</strong> Works
        with your existing development environment without the need for complex
        setup.
      </text>
      <text className="text-gray-400 mt-4">
        <strong className="text-violet-500">AI-Powered Insights:</strong>{" "}
        Leverage advanced AI techniques to detect potential bugs and security
        vulnerabilities before they become problems.
      </text>
      <text className="text-gray-400 mt-4">
        <strong className="text-violet-500">Automated Suggestions:</strong> Get
        actionable recommendations to improve your code and documentation.
      </text>
      <header className="text-[1.5em] text-white mt-8">Getting Started</header>
      <text className="text-gray-400 mt-4">
        Using Deep Refactor CLI is straightforward – no extensive setup or
        infrastructure is required. Simply:
      </text>
      <ol className="text-gray-400 gap-sm mt-4 list-decimal list-inside">
        <li>
          Create an account at {" "}
          <a
            href="https://deeprefactor.dev"
            className="text-violet-500 hover:underline"
          >
            Deep Refactor
          </a>
        </li>
        <li className="mt-4">
          Install the Python package
          <span className="px-2 py-[2px] bg-gray-900 border border-[#333333] rounded-sm ml-2 mr-2">
          pip install deeprefactorCLI
          </span>
        </li>
        <li className="mt-4">
          Login to your account and generate an API key in your Settings from the Dashboard
        </li>
        <li className="mt-4">
          Authenticate your account using the <span className="px-2 py-[2px] bg-gray-900 border border-[#333333] rounded-sm ml-2 mr-2">
            deep-refactor login
          </span> command, using your Deep Refactor credentials
        </li>
        <li className="mt-4">Start analyzing your codebase right from your terminal.</li>
      </ol>
    </div>
  );
}

export default DocumentationIntro;

import { Flex, Title } from "@mantine/core";
import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import PreDisplay from "../Components/PreDisplay";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Integrations from "./Integrations";
import { useAuth0 } from "@auth0/auth0-react";
import Overview from "../Components/Overview";
import HexLoader from "../Components/HexLoader";
import ProfileSettings from "./ProfileSettings";

function Dashboard() {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("overview");
  const [repos, setRepos] = useState([]);
  const [languages, setLanguages] = useState({});
  const [githubTokenUpdated, setGithubTokenUpdated] = useState(false);
  const [analyzedRepos, setAnalyzedRepos] = useState([]);
  const [activeJob, setActiveJob] = useState(null);
  const { isLoading, error, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get("code");

    const fetchTokenAndData = async (token) => {
      try {
        if (urlToken && !githubTokenUpdated) {
          updateToken(token);
        }
        const userDetailsResponse = await getUserDetails(token);
        setUserData(userDetailsResponse);
        if (userDetailsResponse?.hasGithubAccessToken) {
          const repos = await getRepos(token);
          setRepos(repos);
          const analytics = await getAnalytics(token);
          setAnalyzedRepos(analytics);
          const activeJob = await fetchActiveJob(token, analytics);
          setActiveJob(activeJob);
        }
      } catch (e) {
        console.error(e);
      }
    };

    async function getRepos(token) {
      try {
        const response = await axios.get(
          "https://api.deeprefactor.dev/github/repos",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Check for specific error
          console.error("Invalid GitHub token", error);
          // Notify the user
          alert("Your GitHub token is invalid. Please reset it.");
          // Call a function to set the user's token to null in the database
          resetUserGithubToken(token);
        } else {
          console.error(error);
          throw error;
        }
      }
    }

    async function resetUserGithubToken(token) {
      try {
        await axios.post(
          "https://api.deeprefactor.dev/github/reset-token",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // refresh page
        window.location.reload();
      } catch (error) {
        console.error("Error resetting GitHub token", error);
      }
    }

    async function getUserDetails(token) {
      try {
        const response = await axios.get("https://api.deeprefactor.dev/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("User Info", response.data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    //CHANGE
    async function fetchActiveJob(token, repos) {
      try {
        const response = await axios.get(
          "https://api.deeprefactor.dev/active-job",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.queueType === "reanalysis") {
          console.log("Repos", repos);
          const newRepos = repos.filter(
            (repo) => repo.repo_id !== response.data.data.repoID
          );
          console.log("newRepos", newRepos);
          setAnalyzedRepos(newRepos);
        }
        console.log("Active Job", response.data);
        return response.data;
      } catch (error) {
        console.error("Failed to fetch active job:", error);
      }
    }

    async function getAnalytics(token) {
      try {
        const response = await axios.get(
          "https://api.deeprefactor.dev/model/repos",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function updateToken(token) {
      try {
        const response = await axios.post(
          "https://api.deeprefactor.dev/github/callback",
          {
            githubToken: urlToken,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setGithubTokenUpdated(true);
      } catch (err) {
        console.error(err);
      }
      window.history.replaceState(null, null, window.location.pathname);
    }

    const fetchDashboardData = async () => {
      const token = await getAccessTokenSilently();
      await fetchTokenAndData(token);
    };

    fetchDashboardData();
  }, [user]);

  if (error) {
    console.error(error);
    return (
      <Flex w={"100%"} h={"100%"} bg={"#121212"}>
        Oops... {error.message}
      </Flex>
    );
  }

  if (isLoading || !repos || !analyzedRepos) {
    return (
      <Flex
        w={"100%"}
        h={"100%"}
        bg={"#121212"}
        align={"center"}
        justify={"center"}
        direction={"column"}
        gap={"2em"}
      >
        <HexLoader />
      </Flex>
    );
  }

  let displayedComponent;
  switch (activeTab) {
    case "overview":
      if (userData?.hasGithubAccessToken) {
        displayedComponent = (
          <Overview
            setActiveTab={setActiveTab}
            repos={repos}
            analyzedRepos={analyzedRepos}
            setAnalyzedRepos={setAnalyzedRepos}
            setUserData={setUserData}
            userData={userData}
            activeJob={activeJob?.data}
          />
        );
      } else {
        displayedComponent = <PreDisplay />;
      }

      break;
    case "integrations":
      displayedComponent = (
        <Integrations
          languages={languages}
          repos={repos}
          analyzedRepos={analyzedRepos}
          userData={userData}
        />
      );
      break;
    case "settings":
      displayedComponent = (
        <ProfileSettings setUserData={setUserData} userData={userData} />
      );
      break;
    default:
      displayedComponent = (
        <Overview
          setActiveTab={setActiveTab}
          repos={repos}
          analyzedRepos={analyzedRepos}
          setAnalyzedRepos={setAnalyzedRepos}
          setUserData={setUserData}
          userData={userData}
          activeJob={activeJob?.data}
        />
      );
  }

  if (isAuthenticated) {
    return (
      <Flex direction={"column"} bg={"#121212"} w={"100%"} h={"100%"}>
        <Header
          userData={userData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          showButton={true}
        />
        <>{displayedComponent}</>
      </Flex>
    );
  } else {
    navigate("/");
  }
}

export default Dashboard;

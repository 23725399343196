import { Collapse, Flex, Text } from "@mantine/core";
import { AiOutlineFile, AiOutlineFolder} from 'react-icons/ai'
import React, { useState } from "react";

function FileTree({ tree, level = 0 }) {
  const [openKeys, setOpenKeys] = useState({});

  const handleToggle = (key) => {
    setOpenKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <div style={{ marginLeft: level * 10 }}>
      {Object.keys(tree).map((key, index) => {
        const isFile = Object.keys(tree[key]).length === 0;

        return (
          <Flex direction={"column"} mt={"sm"} key={key}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: isFile ? "default" : "pointer",
              }}
              onClick={() => !isFile && handleToggle(key)}
            >
              {isFile ? (
                <span style={{ marginRight: "8px" }}>
                  <AiOutlineFile color="#A0A0A0" size={'1.25em'} />
                </span>
              ) : (
                <span style={{ marginRight: "8px" }}>
                  <AiOutlineFolder color="#A0A0A0" size={'1.25em'} />
                </span>
              )}
              <Text color="#A0A0A0" size={'sm'}>{key}</Text>
            </div>
            {!isFile && (
              <Collapse in={openKeys[key]}>
                <FileTree tree={tree[key]} level={level + 1} />
              </Collapse>
            )}
          </Flex>
        );
      })}
    </div>
  );
}

export default FileTree;

import { Badge, Collapse, Flex, Text } from "@mantine/core";
import { AiOutlineFile, AiOutlineFolder } from "react-icons/ai";
import React, { useState } from "react";

function FileTreeSelectable({
  tree,
  level = 0,
  onFileSelect,
  currentFile,
  path = "",
}) {
  const [openKeys, setOpenKeys] = useState({});

  const forbiddenExtensions = [
    ".json",
    ".jsonl",
    ".git",
    ".css",
    ".ttf",
    ".gitignore",
    ".md",
    ".lock",
    ".yml",
    ".yaml",
    ".xml",
    ".ini",
    ".env",
    ".txt",
    ".log",
    ".csv",
    ".dat",
    ".db",
    ".sql",
    ".sqlite",
    ".dump",
    ".svg",
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".ico",
    ".pdf",
    ".LICENSE",
    ".README",
    ".DS_Store",
    ".classpath",
    ".project",
    ".settings",
    ".idea",
    ".vscode",
    ".css",
    "otf",
  ];

  const forbiddenPaths = [
    "node_modules",
    ".git", // .git folder for git version control
    "vendor", // Common folder name for third-party packages, e.g., in PHP projects
    "dist", // Common distribution folder, contains compiled files
    "build", // Common build folder, contains compiled files
    "__pycache__", // Python cache folder
    ".pytest_cache", // Python pytest cache folder
    ".vscode", // Visual Studio Code settings folder
    ".github", // GitHub specific configuration and workflow folder
    "assets", // Often contains media files rather than code
    "bin", // Often contains binary files
    "fonts",
  ];

  const handleToggle = (key) => {
    setOpenKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const getTotalComments = (node) => {
    // If it's a file, return its comment count
    if (node.isFile) {
      return node.comments || 0;
    }

    // If it's a directory, recurse and sum up the comments
    return Object.keys(node).reduce((acc, key) => {
      return acc + getTotalComments(node[key]);
    }, 0);
  };

  const handleFileSelect = (key, file, path) => {
    const isForbidden =
      forbiddenExtensions.some((ext) => path.endsWith(ext)) ||
      forbiddenPaths.some((forbiddenPath) => path.includes(forbiddenPath));

    if (isForbidden) {
      return;
    }

    if (file.isFile && file.content !== null) {
      onFileSelect({ ...file, path });
    } else {
      handleToggle(key);
    }
  };

  return (
    <Flex w={"100%"} direction={"column"} style={{ marginLeft: level * 10 }}>
      {Object.keys(tree).map((key, index) => {
        const isFile = tree[key].isFile;
        const currentItemPath = `${path}/${key}`.startsWith("/")
          ? `${path}/${key}`.slice(1)
          : `${path}/${key}`;

        const totalComments = getTotalComments(tree[key]);
        const isOpen = openKeys[key];

        return (
          <Flex direction={"column"} mt={"sm"} key={key}>
            <Flex
              w={"100%"}
              align={"center"}
              style={{
                borderRadius: "0 7.5px 7.5px 0",
                cursor: isFile
                  ? forbiddenExtensions.some((ext) => key.endsWith(ext)) ||
                    forbiddenPaths.some((forbiddenPath) =>
                      key.includes(forbiddenPath)
                    ) ||
                    tree[key].content === null
                    ? "not-allowed"
                    : "pointer"
                  : forbiddenPaths.some((forbiddenPath) =>
                      key.includes(forbiddenPath)
                    )
                  ? "not-allowed"
                  : "pointer",
                borderLeft:
                  currentFile && currentFile.path === currentItemPath
                    ? "solid 2px #7521BC"
                    : undefined,
              }}
              pl={"sm"}
              miw={"100%"}
              pt={"2px"}
              pb={"2px"}
              bg={
                currentFile && currentFile.path === currentItemPath
                  ? "violet.8"
                  : undefined
              }
              onClick={() => handleFileSelect(key, tree[key], currentItemPath)}
            >
              {isFile ? (
                <span style={{ marginRight: "8px" }}>
                  <AiOutlineFile color="white" size={"20px"} />
                </span>
              ) : (
                <span style={{ marginRight: "8px" }}>
                  <AiOutlineFolder color="white" size={"20px"} />
                </span>
              )}
              <Flex gap={"md"} align={"center"}>
                <Text color="white" size={"sm"}>
                  {key}
                </Text>
                {!isOpen && totalComments > 0 && (
                  <Badge color="gray.0" variant="outline">
                    {totalComments}
                  </Badge>
                )}
              </Flex>
            </Flex>
            {!isFile && (
              <Collapse in={isOpen}>
                <FileTreeSelectable
                  tree={tree[key]}
                  level={level + 1}
                  onFileSelect={onFileSelect}
                  currentFile={currentFile}
                  path={currentItemPath}
                />
              </Collapse>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
}

export default FileTreeSelectable;

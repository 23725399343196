import React, { useState } from "react";
import { Flex, Button, Card, Text, Title, Divider } from "@mantine/core";

function UIAnalysis({ UIData }) {
  console.log(UIData);

  return (
    <Flex
      bg={"#1E1E1E"}
      w={"100%"}
      h={"100%"}
      mah={"calc(100vh - 125px)"}
      align={"center"}
      justify={"space-evenly"}
      style={{ fontFamily: "Inter, sans-serif" }}
    >
      <Flex
        p={"lg"}
        w={"30%"}
        h={"100%"}
        style={{
          borderRight: "solid 1px #333333",
          overflow: "auto",
          color: "#E0E0E0",
        }}
        direction={"column"}
        gap={"1em"}
      >
        {UIData.map((item) => {
          let severityColor;
          if (item.severity === "High" || item.severity === "Critical") {
            severityColor = "#E33333";
          } else if (item.severity === "Medium") {
            severityColor = "#E6A33E";
          } else if (item.severity === "Low") {
            severityColor = "#13A010";
          } else if (item.severity === "Info") {
            severityColor = "#7521BC";
          }

          return (
            <Flex
              style={{
                borderRadius: "5px",
                cursor: "default",
              }}
              w="100%"
              bg="#030C1B"
            >
              <Flex
                style={{ borderRadius: "4px 0 0 4px" }}
                miw="7.5px"
                h={"100%"}
                bg={"#333333"}
              />
              <Flex p="md" w="100%" direction="column" flexGrow={1}>
                <Flex w="100%" justify="space-between">
                  <Title fz={"1em"} fw={600} color="#E0E0E0" order={5}>
                    {item.section}
                  </Title>
                  <Title fz={"1em"} fw={500} order={5} color={severityColor}>
                    {item.severity == "High" ? "Critical" : item.severity}
                  </Title>
                </Flex>
                <Text fz={"1em"} mt="1em" color="dimmed" fw={300} w="100%">
                  {item.suggestion}
                </Text>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      <Flex
        p={"1em"}
        w={"70%"}
        h={"100%"}
        style={{
          overflow: "auto",
          color: "#E0E0E0",
        }}
        direction={"column"}
        gap={"1em"}
        bg={"#121212"}
      >
        <iframe
          style={{ borderRadius: "5px" }}
          src={UIData?.[0].url}
          width="100%"
          height="100%"
        ></iframe>
      </Flex>
    </Flex>
  );
}

export default UIAnalysis;

import { Button, Flex } from "@mantine/core";
import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import CodeView from "../Components/CodeView";
import AnalyticsOverview from "../Components/AnalyticsOverview";
import { useAuth0 } from "@auth0/auth0-react";
import Settings from "../Components/Settings";
import HexLoader from "../Components/HexLoader";
import AutoDocOverview from "../Components/AutoDocOverview";
import ArchitectScale from "../Components/ArchitectScale";
import UIAnalysis from "../Components/UIAnalysis";

function Analytics() {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("overview");
  const { id } = useParams();
  const [repoData, setRepoData] = useState(null);

  let displayedComponent;
  switch (activeTab) {
    case "overview":
      displayedComponent = (
        <AnalyticsOverview repoData={repoData} userData={userData} />
      );
      break;

    case "autodoc":
      displayedComponent = (
        <AutoDocOverview repoData={repoData} userData={userData} />
      );
      break;

    case "architect":
      displayedComponent = (
        <ArchitectScale repoData={repoData} userData={userData} />
      );
      break;
    
    case "webpage":
      displayedComponent = (
        <UIAnalysis UIData={repoData.webpageFeedback}  />
      );
      break;

    case "code":
      displayedComponent = (
        <CodeView id={id} repoData={repoData} userData={userData} />
      );
      break;

    case "settings":
      displayedComponent = (
        <Settings id={id} repoData={repoData} userData={userData} />
      );
      break;

    default:
      displayedComponent = (
        <AnalyticsOverview repoData={repoData} userData={userData} />
      );
  }

  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchTokenAndData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const userDetailsResponse = await getUserDetails(token);
        setUserData(userDetailsResponse);
        if (userDetailsResponse?.hasGithubAccessToken) {
          const repos = await getUserRepo(token);
          setRepoData(repos);
          console.log("Repo Data w/ Feedback", repos);
        }
      } catch (e) {
        console.error(e);
      }
    };

    async function getUserDetails(token) {
      try {
        const response = await axios.get("https://api.deeprefactor.dev/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function getUserRepo(token) {
      try {
        const response = await axios.get(
          `https://api.deeprefactor.dev/model/repo/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    fetchTokenAndData();
  }, [user, getAccessTokenSilently]);

  return (
    <Flex direction={"column"} bg={"#121212"} w={"100%"} h={"100%"}>
      <Header
        userData={userData}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        showButton={false}
      />
      {!repoData ? (
        <Flex
          style={{ borderTop: "1px #333333 solid" }}
          bg={"#121212"}
          w={"100%"}
          h={"100%"}
          align={"center"}
          justify={"center"}
        >
          <HexLoader/>
        </Flex>
      ) : (
        <>
          <Flex
            w={"100%"}
            h={65}
            bg={"#1D1D1D"}
            style={{ borderBottom: "1px #333333 solid" }}
            align={"center"}
            p={"sm"}
            gap={"md"}
          >
            <Button
              variant={"subtle"}
              color={"dark.1"}
              leftIcon={<IconArrowNarrowLeft />}
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Back
            </Button>
            <Button
              style={{
                borderBottom:
                  activeTab === "overview" ? "solid 2px #E0E0E0" : undefined,
              }}
              radius={0}
              size={"md"}
              color={"dark"}
              variant={"unstyled"}
              onClick={() => setActiveTab("overview")}
            >
              Overview
            </Button>
            <Button
              style={{
                borderBottom:
                  activeTab === "code" ? "solid 2px #E0E0E0" : undefined,
              }}
              radius={0}
              size={"md"}
              color={"dark"}
              variant={"unstyled"}
              onClick={() => setActiveTab("code")}
            >
              Code Editor
            </Button>
           {repoData?.readme && <Button
              style={{
                borderBottom:
                  activeTab === "autodoc" ? "solid 2px #E0E0E0" : undefined,
              }}
              radius={0}
              size={"md"}
              color={"dark"}
              variant={"unstyled"}
              onClick={() => setActiveTab("autodoc")}
            >
              Documentation
            </Button>}
            {(repoData?.webpageFeedback && repoData?.webpageFeedback.length > 0) && <Button
              style={{
                borderBottom:
                  activeTab === "webpage" ? "solid 2px #E0E0E0" : undefined,
              }}
              radius={0}
              size={"md"}
              color={"dark"}
              variant={"unstyled"}
              onClick={() => setActiveTab("webpage")}
            >
              UI Analysis
            </Button>}
            {repoData?.fileStructureRecommendations.length > 0 && <Button
              style={{
                borderBottom:
                  activeTab === "architect" ? "solid 2px #E0E0E0" : undefined,
              }}
              radius={0}
              size={"md"}
              color={"dark"}
              variant={"unstyled"}
              onClick={() => setActiveTab("architect")}
            >
              Structure
            </Button>}
            <Button
              style={{
                borderBottom:
                  activeTab === "settings" ? "solid 2px #E0E0E0" : undefined,
              }}
              radius={0}
              size={"md"}
              color={"dark"}
              variant={"unstyled"}
              onClick={() => setActiveTab("settings")}
              ml={"auto"}
            >
              Settings
            </Button>
          </Flex>
          {displayedComponent}
        </>
      )}
    </Flex>
  );
}

export default Analytics;

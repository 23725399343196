import { Button, Flex, Menu, TextInput } from "@mantine/core";
import React, { useState } from "react";
import RepositoryCard from "../Components/RepositoryCard";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import ActiveJobCard from "./ActiveJobCard";
import HexLoader from "./HexLoader";

function Overview({
  repos,
  analyzedRepos,
  setAnalyzedRepos,
  setActiveTab,
  userData,
  setUserData,
  activeJob
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  let filteredComments = analyzedRepos;

  if (searchTerm) {
    filteredComments = analyzedRepos?.filter(
      (comment) =>
        comment.project_name &&
        comment.project_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  const isMobile = useMediaQuery("(max-width: 50em)");

  return (
    <Flex
      direction={"column"}
      bg={"#121212"}
      h={"calc(100% - 125px)"}
      w={"100%"}
    >
      <Flex
        justify={"space-evenly"}
        w={"100%"}
        gap={"md"}
        p={"xl"}
        pb={'lg'}
        pl={"3em"}
        pr={"3em"}
        mt={"md"}
      >
        <TextInput
          placeholder={"Search..."}
          w={"90%"}
          icon={<IconSearch size={"50%"} />}
          onChange={(e) => setSearchTerm(e.target.value)}
          color={'#2A2A2A'}
          variant="filled"
        />
        <Menu>
          <Menu.Target>
            <Button
              color="violet.8"
              onClick={() => setMenuOpen(!menuOpen)}
              rightIcon={isMobile ? "" : <IconChevronDown />}
              className="bg-violet-800"
            >
              Add New
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Repositories</Menu.Label>
            <Menu.Item
              onClick={() => {
                setActiveTab("integrations");
              }}
            >
              GitHub Repository
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <Flex
        gap={"md"}
        wrap={"wrap"}
        maw={"100vw"}
        mah={"100%"}
        w={"100%"}
        h={"100%"}
        pb={"2em"}
        pl={"3em"}
        pr={"3em"}
        pt={"2em"}
        style={{ overflow: "auto" }}
      >
        {activeJob && <ActiveJobCard data={activeJob} />}
        {filteredComments?.map((comment) => {
          return (
            <RepositoryCard
              data={comment}
              setAnalyzedRepos={setAnalyzedRepos}
              setUserData={setUserData}
              userData={userData}
              activeJob={activeJob}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

export default Overview;

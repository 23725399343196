import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  MultiSelect,
  Switch,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconPoint, IconPointFilled } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import analysisData from "./Analysis.json";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function Settings({ id, repoData, userData }) {
  const { getAccessTokenSilently } = useAuth0();

  const generalRef = useRef(null);
  const workflowsRef = useRef(null);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  function getUserFriendlyTitle(promptId) {
    const formattedId = promptId
      .split(/(?=[A-Z])/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");

    return formattedId;
  }

  const [data, setData] = useState([]);
  const [webflowsChecked, setWebflowsChecked] = useState(false);

  const handleSwitchChange = async (checked) => {
    setWebflowsChecked(checked);

    if (checked) {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.post(
          "https://api.deeprefactor.dev/github/createWebhook",
          {
            repoFullName: repoData?.fullName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Reponse Data:", response);
      } catch (error) {
        console.error("Error in making POST request:", error);
      }
    }
  };

  return (
    <Flex
      gap={"1em"}
      p={"lg"}
      align={"center"}
      direction={"column"}
      w={"100%"}
      mah={"100%"}
      style={{ overflow: "hidden" }}
      bg={"#121212"} 
    >
      <Flex
        gap={"2em"}
        justify={"center"}
        align={"center"}
        w={"100%"}
        mah={"100%"}
      >
        <Flex  p={"xl"} w={"20%"} h={"100%"} direction={"column"} gap={"sm"}>
          <Title color="#E0E0E0" mb={"1em"} fw={400} order={3} align="center">
            Project Settings
          </Title>
          <Button
            onClick={() => scrollToRef(generalRef)}
            color="dark.1"
            variant="subtle"
          >
            General
          </Button>
          <Button
            onClick={() => scrollToRef(workflowsRef)}
            color="dark.1"
            variant="subtle"
          >
            Workflows
          </Button>
        </Flex>
        <Flex
          p={"xl"}
          w={"60%"}
          mah={"100%"}
          style={{
            overflow: "auto",
          }}
          direction={"column"}
        >
          <Flex gap={"2em"} ml={"md"} mt={"md"} direction={"column"}>
            <Flex
              direction={"column"}
              w={"100%"}
              style={{
                borderRadius: "10px",
                border: "solid 1px #373737",
                boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
              }}
              p={"2em"}
              pb={"4em"}
              ref={generalRef}
              bg={"#1E1E1E"}
            >
              <Title color="#E0E0E0" fw={500}>General</Title>
              <Flex mt={"2em"} w={"100%"} direction={"column"}>
                <Text color="#E0E0E0" fw={500} fz={"lg"}>
                  Project Name
                </Text>
                <Text mt={"xs"} color="dimmed">
                  Used to identify your project on the dashboard.
                </Text>

                <TextInput
                  disabled={true}
                  mt={"sm"}
                  fw={600}
                  fz={"xl"}
                  value={repoData?.project_name}
                ></TextInput>
              </Flex>
              <Flex mt={"2em"} w={"100%"} direction={"column"}>
                <Text color="#E0E0E0" fw={500} fz={"lg"}>
                  Analysis Options
                </Text>
                <Text  mt={"xs"} color="dimmed">
                  Analytical choices used when analyzing the repository.
                </Text>
                <Checkbox.Group mt={"sm"} defaultValue={repoData?.prompts}>
                  <Flex gap={"md"} direction={"column"} mt="xs">
                    {analysisData.map((analysis) => {
                      return (
                        <Checkbox
                          disabled={true}
                          value={analysis.type}
                          label={getUserFriendlyTitle(analysis.type)}
                        />
                      );
                    })}
                  </Flex>
                </Checkbox.Group>
              </Flex>
              <Flex mt={"2em"} w={"100%"} direction={"column"}>
                <Text color="#E0E0E0" fw={500} fz={"lg"}>
                  Languages
                </Text>
                <Text mt={"xs"} color="dimmed">
                  Language information found in your repository.
                </Text>

                {repoData?.languages.map((language, index) => {
                  return (
                    <Flex gap={"sm"} mt={"xs"} ml={"sm"}>
                      <Text color="#E0E0E0">{index + 1}.</Text>
                      <Text color="#E0E0E0">{language}</Text>
                    </Flex>
                  );
                })}
              </Flex>
              <Button variant="outline" color="violet.8" mt={"4em"} disabled={true} w={150}>
                Save Changes
              </Button>
            </Flex>
            <Flex
              direction={"column"}
              w={"100%"}
              bg={"#1E1E1E"}
              style={{
                borderRadius: "10px",
                border: "solid 1px #373737",
                boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
              }}
              p={"2em"}
              pb={"4em"}
              ref={workflowsRef}
            >
              <Flex align={"center"} justify={"space-between"}>
              <Flex align={'center'} gap={'sm'}>
                <Title color="#E0E0E0" fw={500}>Workflows</Title>
                <Text className="text-violet-800">(Cooming Soon)</Text>
                </Flex>
                <Title
                  variant="gradient"
                  gradient={{ from: "violet", to: "indigo", deg: 45 }}
                  order={4}
                  fw={500}
                >
                  Premium Feature
                </Title>
              </Flex>
              <Flex mt={"2em"} w={"100%"} direction={"column"}>
                <Text color="#E0E0E0" fw={500} fz={"lg"}>
                  Emails
                </Text>
                <Text  mt={"xs"} color="dimmed">
                  Notification emails will be sent to these accounts whenever
                  the repository is analyzed.
                </Text>
                <MultiSelect
                  disabled={ true ||userData?.account_type == "free"}
                  mt={"lg"}
                  creatable
                  data={data}
                  searchable
                  getCreateLabel={(query) => `+ Add ${query}`}
                  onCreate={(query) => {
                    if (isValidEmail(query)) {
                      const item = { value: query, label: query };
                      setData((current) => [...current, item]);
                      return item;
                    } else {
                      alert("Please enter a valid email address.");
                      return null;
                    }
                  }}
                />
              </Flex>
              <Flex mt={"2em"} w={"100%"} direction={"column"}>
                <Text color="#E0E0E0" fw={500} fz={"lg"}>
                  Webhooks
                </Text>
                <Text mt={"xs"} color="dimmed">
                  Enabling webhooks will allow Deep Refactor to automatically
                  analyze your repository whenever a new commit is pushed.
                </Text>
                <Switch
                  disabled={true || userData?.account_type == "free"}
                  mt={"xl"}
                  color="violet.8"
                  checked={webflowsChecked}
                  onChange={(event) =>
                    handleSwitchChange(event.currentTarget.checked)
                  }
                />
              </Flex>
              <Button
                disabled={true || userData?.account_type == "free"}
                variant="outline"
                color="violet.8"
                mt={"4em"}
                w={150}
              >
                Save Changes
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Settings;

import React from "react";

function DocumentationPlatformAnalysis() {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden pb-[6em]">
      <header className="text-[2.5em] mt-4 text-white">
        Platform Analysis Workflow
      </header>
      <p className="text-gray-400 mt-8 w-3/4">
        The Platform Analysis workflow is integral for developers looking to 
        seamlessly integrate Deep Refactor's insights into their development cycle.
      </p>
      <header className="text-[1.5em] text-white mt-8">
        Step by Step Workflow:
      </header>
      <ol className="text-gray-400 mt-4 list-decimal list-inside">
        <li className="mt-4">
          Log into the Deep Refactor platform and navigate to the analysis section for your repository.
        </li>
        <li className="mt-4">
          Review the detailed feedback provided and prioritize issues based on the provided severity and categories.
        </li>
        <li className="mt-4">
          Open your code editor, navigate to the files highlighted in the feedback, and begin addressing the issues.
        </li>
        <li className="mt-4">
          Commit changes iteratively to your version control system, ensuring each commit is focused and well-documented.
        </li>
        <li className="mt-4">
          Reanalyze your repository on the platform after changes to validate fixes and check for new potential issues.
        </li>
        <li className="mt-4">
          Integrate Deep Refactor analysis into your CI/CD pipeline for ongoing quality assurance.
        </li>
      </ol>
      <p className="text-violet-700 mt-8 w-3/4">
        This workflow is designed to help maintain a high standard of code quality and to catch and fix issues promptly.
      </p>
    </div>
  );
}

export default DocumentationPlatformAnalysis;

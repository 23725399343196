import { ActionIcon, Card, Flex, Image, Text, Title } from "@mantine/core";
import React from "react";
import WarningImage from "../Images/Warning.png";
import GitHubLogo from "../Images/GithubLogo.svg";
import BitBucketLogo from "../Images/BitBucket.png";
import GitLabLogo from "../Images/GitLabLogo.png";
import '../App.css'

function PreDisplay() {
  function connectToGithub() {
    window.location.href =
      "https://github.com/login/oauth/authorize?client_id=492923e71cdb1be73b0a&scope=repo%20admin:repo_hook";
  }

  return (
    <Flex w={"100%"} h={"100%"} align={"center"} justify={"center"}>
      <Card bg={'#1E1E1E'} withBorder shadow={"xs"} w={"450px"} h={"275px"} mb={"5em"}>
        <Flex w={"100%"} h={"100%"} align={"center"} direction={"column"}>
          <Flex
            w={"95%"}
            mt={'0.45em'}
            align={"center"}
            gap={"1.5em"}
          >
            <Title w={'100%'} align="center" fw={600} order={2}>
              Link Your First Repository
            </Title>
          </Flex>
          <Flex
            pt={"xs"}
            w={"100%"}
            h={"100%"}
            align={"center"}
            direction={"column"}
          >
            <Text w={'100%'} align="center" color="violet.8" fw={400} fz={"md"}>
              Click the button below to link your GitHub account
            </Text>
            <Flex
              w={"100%"}
              h={"100%"}
              justify={"center"}
              align={"center"}
              gap={"md"}
            >
              <ActionIcon
              className="LinkAccountBorder"
                size={"6em"}
                p={"sm"}
                onClick={() => connectToGithub()}
                radius={"50%"}
                style={{ filter: "invert(1)" }}
              >
                <Image src={GitHubLogo} maw={"100%"} />
              </ActionIcon>

              {/* <ActionIcon disabled size={"4em"} p={"sm"}>
                <Image src={BitBucketLogo} maw={"100%"} />
              </ActionIcon>
              <ActionIcon disabled size={"4em"} p={"sm"}>
                <Image src={GitLabLogo} maw={"100%"} />
              </ActionIcon> */}
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}

export default PreDisplay;

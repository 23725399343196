import { Flex, Text, Divider, Title } from "@mantine/core";
import React, { useState, useEffect, useMemo } from "react";
import "../App.css";
import { FaCode, FaBug, FaLock } from "react-icons/fa";
import { SlDocs } from "react-icons/sl";
import { GoPackageDependencies } from "react-icons/go";
import { TbBrandSpeedtest } from "react-icons/tb";
import { FaSitemap, FaSyncAlt } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { BsGraphUpArrow } from "react-icons/bs";
import FileTreeSelectable from "./FileTreeSelectable";
import CodeEditor from "./CodeEditor";

function CodeView({ id, repoData, userData }) {
  const [selectedFile, setSelectedFile] = useState(() => {
    const validFile = repoData?.files.find(
      (file) => file.content && file.content.length > 0
    );
    return validFile || { content: "", comments: [] };
  });

  const [activeComment, setActiveComment] = useState(null);

  const treeData = transformToTreeData(repoData);

  function getUserFriendlyTitle(promptId) {
    if (!promptId) return "";

    const formattedId = promptId
      .split(/(?=[A-Z])/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");

    return formattedId;
  }

  const feedbackItems = useMemo(() => {
    const comments = selectedFile?.comments || [];
    const suggestions = repoData?.documentationSuggestions
      .filter((suggestion) => suggestion.file_path === selectedFile?.path && suggestion.start_line !== -1)
      .map((suggestion) => ({
        ...suggestion,
        line_start: suggestion.start_line,
        line_end: suggestion.start_line,
        type: "DocumentationSuggestion",
        severity: "Info",
        recommendation: "Insert documentation here",
      }));

    return [...comments, ...suggestions].sort(
      (a, b) => a.line_start - b.line_start
    );
  }, [selectedFile, repoData?.documentationSuggestions]);

  console.log(JSON.stringify(feedbackItems, null, 2))

  function transformToTreeData(repoData) {
    const tree = {};

    repoData?.files.forEach((item) => {
      let node = tree;
      const paths = item.path.split("/");

      paths.forEach((path, index) => {
        if (!node[path]) {
          if (index === paths.length - 1) {
            // It's a file
            node[path] = {
              isFile: true,
              ...item.file,
              comments: item.comments.length,
            };
          } else {
            // It's a directory
            node[path] = {};
          }
        }
        node = node[path];
      });
    });

    return tree;
  }

  const handleMouseOver = (comment) => {
    setHoveredComment(comment);
  };

  const handleMouseOut = () => {
    setHoveredComment(null);
  };

  const handleCommentClick = (comment) => {
    if (activeComment === comment) {
      setActiveComment(null);
    } else {
      setActiveComment(comment);
    }
  };

  const colorMapper = {
    CodeQuality: "#E53935", // Red
    DocumentationQuality: "#1E88E5", // Blue
    BugDetection: "#43A047", // Green
    SecurityAnalysis: "#8E24AA", // Purple
    TestCoverage: "#FB8C00", // Orange
    PerformanceAnalysis: "#00ACB9", // Cyan
    AccessibilityAnalysis: "#FDD835", // Yellow
    ArchitectureAnalysis: "#6D4C41", // Brown
    ContinuousIntegration: "#3949AB", // Indigo
    ResponsivenessAnalysis: "#FF5722", // Deep Orange
  };

  useEffect(() => {
    if (repoData && repoData.files) {
      for (const file of repoData.files) {
        if (file.content && file.content.length > 0) {
          file.comments = file.comments.filter(
            (comment) => comment.comment_text && comment.type
          );
          setSelectedFile(file);
          break;
        }
      }
    }
  }, [repoData]);

  function getFileDetails(selectedFile, project) {
    console.log(selectedFile);
    if (!selectedFile.isFile) {
      return {
        content: "",
        comments: [],
      };
    }

    for (let file of project.files) {
      console.log("File path", file.path);
      if (file.path === selectedFile.path) {
        console.log("File", file);
        return file;
      }
    }
    return null;
  }

  const [hoveredComment, setHoveredComment] = useState(null);

  return (
    <Flex w={"100%"} h={"100%"} style={{ overflow: "hidden" }}>
      <Flex
        direction={"column"}
        w={"17.5%"}
        h={"100%"}
        style={{ borderRight: "1px black #333333" }}
        bg={"#121212"}
        p={"md"}
        justify={"space-between"}
      >
        <Flex direction={"column"} w={"100%"} h={"10%"}>
          <Text color="white" fw={800}>
            Explorer
          </Text>
          <Divider mt={"xs"} mb={"md"} w={"35%"} />
        </Flex>
        <Flex
          w={"100%"}
          mih={"80%"}
          mah={"600px"}
          h={"80%"}
          style={{ overflow: "auto" }}
        >
          <FileTreeSelectable
            tree={treeData}
            onFileSelect={(file) => {
              const details = getFileDetails(file, repoData);
              console.log("Current file details", details);
              setSelectedFile(details);
              setActiveComment(null);
            }}
            currentFile={selectedFile}
          />
        </Flex>
        <Flex w={"100%"} h={"10%"} align={"center"} gap={"sm"}>
          {/* <Button color="blue.8" variant="outline">
            Submit
          </Button>
          <Button color="blue.8" variant="outline">
            Analyze
          </Button> */}
        </Flex>
      </Flex>
      <Flex p={"md"} bg={"#1e1e1e"} direction={"column"} w={"55%"} h={"100%"}>
        <CodeEditor
          comments={selectedFile?.comments || []}
          code={selectedFile?.content}
          hoveredComment={hoveredComment}
          path={selectedFile?.path}
          activeComment={activeComment}
        />
      </Flex>
      <Flex
        direction={"column"}
        w={"27.5%"}
        style={{ borderLeft: "solid 1px #333333" }}
        bg={"#121212"}
        p={"md"}
        h={"100%"}
      >
        <Flex direction={"column"} w={"100%"} h={"10%"}>
          <Flex align={"flex-start"} justify={"space-between"}>
            <Flex w={"50%"} direction={"column"}>
              <Text color="white" fw={800}>
                Feedback
              </Text>
              <Divider mt={"0.5em"} mb={"md"} w={"60%"} />
            </Flex>
            {/* <Button color="blue.8" variant="outline">
              Enable
            </Button> */}
          </Flex>
        </Flex>
        <Flex
          pb={"md"}
          direction={"column"}
          gap={"lg"}
          w={"100%"}
          style={{ overflow: "auto" }}
        >
          {feedbackItems.length > 0 ? (
            feedbackItems.map((item, index) => {
              let severityColor;
              if (item.severity === "Critical") {
                severityColor = "#E33333";
              } else if (item.severity === "Medium") {
                severityColor = "#E6A33E";
              } else if (item.severity === "Low") {
                severityColor = "#13A010";
              } else if (item.severity === "Info") {
                severityColor = "#7521BC";
              }

              return (
                <Flex
                  style={{
                    borderRadius: "5px",
                    cursor: "pointer",
                    border: item === activeComment ? "solid 1px white" : "",
                  }}
                  w="100%"
                  bg="#030C1B"
                  onMouseOver={
                    item.severity !== "Info"
                      ? () => handleMouseOver(item)
                      : undefined
                  }
                  onMouseOut={
                    item.severity !== "Info" ? handleMouseOut : undefined
                  }
                  onClick={
                    item.severity !== "Info"
                      ? () => handleCommentClick(item)
                      : undefined
                  }
                >
                  <Flex
                    style={{ borderRadius: "4px 0 0 4px" }}
                    miw="7.5px"
                    h={"100%"}
                    bg={colorMapper[item.type] || "#333333"} // Default color if not found
                  />
                  <Flex p="md" w="100%" direction="column" flexGrow={1}>
                    <Flex w="100%" justify="space-between">
                      <Title fz={"1em"} fw={300} color="#E0E0E0" order={5}>
                        {getUserFriendlyTitle(item.type)}
                      </Title>
                      <Title
                        fz={"1em"}
                        fw={500}
                        order={5}
                        color={severityColor}
                      >
                        {item.severity}
                      </Title>
                    </Flex>
                    <Title fz={"1.1em"} mt="0.5em" color="#E0E0E0" order={4}>
                      {item.severity == "Info" ? item.recommendation : item.comment_text}
                    </Title>
                    <Text fz={"1em"} mt="1em" color="#E0E0E0" fw={300} w="100%">
                    {item.severity == "Info" ? item.comment_text : item.recommendation}
                    </Text>
                    <Flex mt="1em" w="100%" mih="20px">
                      <Text color="#E0E0E0" fz={"xs"} fw={300}>
                        {item.line_end === item.line_start
                          ? `Line ${item.line_start}`
                          : `Lines: ${item.line_start} - ${item.line_end}`}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })
          ) : (
            <Text color="white" fw={"300"} fz={"xs"}>
              No feedback found for this file.
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CodeView;

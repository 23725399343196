import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from '@auth0/auth0-react';
import App from "./App";
import config from "./auth_config.json";
import { MantineProvider } from "@mantine/core";
import './index.css'

if (process.env.NODE_ENV === 'production') {
  console.log = function() {};
}

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams: {
    redirect_uri: process.env.NODE_ENV === 'production' ? 
                  'https://www.deeprefactor.dev/dashboard' : 
                  'http://localhost:3000/dashboard',
    ...(config.audience ? { audience: config.audience } : null),
  },
};

console.log(providerConfig)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MantineProvider theme={{
    colorScheme: 'dark'}}>
  <Auth0Provider
  {...providerConfig}
    >
    <App />
  </Auth0Provider>
  </MantineProvider>
);
import React, { useState, useEffect } from "react";
import {
  Text,
  Paper,
  Divider,
  Group,
  Box,
  Badge,
  Title,
  Flex,
} from "@mantine/core";
import FileTreeAnimated from "./FileTreeAnimated";

function transformFilesToTree(files, hoveredRecommendation) {
  const tree = {};
  files.forEach(({ path }) => {
    if (hoveredRecommendation && path === hoveredRecommendation.original_path) {
      path = hoveredRecommendation.recommended_path;
    }
    const parts = path.split("/");
    let currentLevel = tree;
    parts.forEach((part, index) => {
      if (!currentLevel[part]) {
        currentLevel[part] = index === parts.length - 1 ? {} : {};
      }
      currentLevel = currentLevel[part];
    });
  });
  return tree;
}

function ArchitectScale({ repoData }) {
  const [hoveredRecommendation, setHoveredRecommendation] = useState(null);
  const [fileTreeStructure, setFileTreeStructure] = useState(
    transformFilesToTree(repoData?.files || [])
  );

  useEffect(() => {
    setFileTreeStructure(
      transformFilesToTree(repoData?.files || [], hoveredRecommendation)
    );
  }, [repoData?.files, hoveredRecommendation]);

  const recommendations = repoData?.fileStructureRecommendations;

  const handleMouseEnter = (recommendation) => {
    setHoveredRecommendation(recommendation);
  };

  const handleMouseLeave = () => {
    setHoveredRecommendation(null);
  };

  return (
    <Flex h={"100%"} w={"100%"} style={{ overflow: "auto" }}>
      <Flex
        p={"2em"}
        w={"30%"}
        h={"100%"}
        direction={"column"}
        style={{ borderRight: "solid 1px #333333" }}
      >
        <Title order={2} color="#E0E0E0">
          Current Structure
        </Title>
        <Divider mt={"0.75em"} mb={"1em"} />
        <FileTreeAnimated
          tree={fileTreeStructure}
          hoveredRecommendation={hoveredRecommendation}
        />
      </Flex>
      <Flex p={"2em"} w={"70%"} h={"100%"} direction={"column"}>
        <Title order={2} color="#E0E0E0">
          Recommended Improvements
        </Title>
        <Divider mt={"0.75em"} mb={"1em"} />
        <Flex
          direction={"column"}
          w={"100%"}
          h={"100%"}
          style={{ overflow: "auto" }}
          gap={"1em"}
        >
          {recommendations.map((recommendation, index) => (
            <Paper
              shadow="xs"
              p="md"
              withBorder
              key={index}
              onMouseEnter={() => handleMouseEnter(recommendation)}
              onMouseLeave={handleMouseLeave}
            >
              <Group position="apart">
                <Badge color="green" variant="light">
                  Current Path
                </Badge>
                <Text weight={500}>{recommendation.original_path}</Text>
              </Group>
              <Divider my="sm" label="Recommended" labelPosition="center" />
              <Group position="apart">
                <Badge color="blue" variant="light">
                  Recommended Path
                </Badge>
                <Text weight={500} style={{ wordBreak: "break-word" }}>
                  {recommendation.recommended_path}
                </Text>
              </Group>
              <Text mt="md" size="sm" color="dimmed">
                {recommendation.recommendation_reason}
              </Text>
            </Paper>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ArchitectScale;

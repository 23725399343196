import { Flex, Text } from "@mantine/core";
import React from "react";
import {
  TbBrandSwift,
  TbBrandCpp,
  TbBrandCSharp,
  TbBrandJavascript,
  TbBrandPython,
  TbBrandPhp,
  TbBrandTypescript,
  TbBrandCss3,
  TbBrandHtml5,
} from "react-icons/tb";
import { FaJava } from "react-icons/fa";
import { BsCode } from "react-icons/bs";

function LanguageTab({ language, percentage }) {
  let Icon;

  switch (language) {
    case "JavaScript":
      Icon = TbBrandJavascript;
      break;
    case "Python":
      Icon = TbBrandPython;
      break;
    case "Java":
      Icon = FaJava;
      break;
    case "PHP":
      Icon = TbBrandPhp;
      break;
    case "TypeScript":
      Icon = TbBrandTypescript;
      break;
    case "C#":
      Icon = TbBrandCSharp;
      break;
    case "C++":
      Icon = TbBrandCpp;
      break;
    case "CSS":
      Icon = TbBrandCss3;
      break;
    case "HTML":
      Icon = TbBrandHtml5;
      break;
    case "Swift":
      Icon = TbBrandSwift;
      break;
    default:
      Icon = BsCode;
  }

  return (
    <Flex w={"100%"} h={"30px"} align={"center"} justify={"space-between"}>
      <Flex align={"center"}>
        <Icon color="#A0A0A0" size={'1.25em'}/>
        <Text color="#A0A0A0" ml={'md'}>{language}</Text>
      </Flex>
      <Text color="#A0A0A0">{percentage}%</Text>
    </Flex>
  );
}

export default LanguageTab;

import {
  ActionIcon,
  Flex,
  Title,
  Text,
  Card,
  Badge,
  rem,
  Image,
  Divider,
  Button,
  Accordion,
  Switch,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import LandingHeader from "../Components/LandingHeader";
import "../App.css";
import { useMediaQuery } from "@mantine/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import {
  FaRocket,
  FaShieldAlt,
  FaLightbulb,
  FaCheck,
  FaCheckCircle,
} from "react-icons/fa";
import VideoPlayer from "../Components/VideoPlayer";
import LandingIcon from "../Images/LandingIcon.png";

import CodeAnalysisImage from "../Images/CodeAnalysisLanding.svg";
import CodeHealthImage from "../Images/CodeHealthLanding.png";
import CommandLineImage from "../Images/CLILanding.png";
import ArchitectureScaleImage from "../Images/ArchitectLanding.png";
import AutoDocImage from "../Images/AutoDocLanding.png";

import DeepRefactorMini from "../Images/DeepRefactorMini.png";

function Landing() {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const { loginWithRedirect } = useAuth0();

  const [hoveredCardIndex, setHoveredCardIndex] = useState(0);

  const handleMouseEnter = (index) => {
    setHoveredCardIndex(index);
  };

  const aboutData = [
    {
      icon: FaRocket,
      title: "Tailored for Startup Growth",
      description:
        "Deep Refactor grows with you. Start with essential code analysis and scale seamlessly with our advanced features as your startup expands. We are committed to being the most valuable tool in your development arsenal.",
    },
    {
      icon: FaLightbulb,
      title: "Commitment to Developer Empowerment",
      description:
        "We're dedicated to enhancing developer expertise, not replacing it. Our AI-driven suggestions are designed to refine your coding process, not dictate it. This ensures your platform's integrity while keeping your code distinctly yours",
    },
    {
      icon: FaShieldAlt,
      title: "Future-Proofing Your Code",
      description:
        "Our tool is built to ensure your codebase is not just robust today, but adaptable for the technologies of tomorrow. We prioritize future-proofing your platform by focusing on maintainability, scalability, and security",
    },
  ];

  const images = {
    codeAnalysis: CodeAnalysisImage,
    codeHealth: CodeHealthImage,
    commandLine: CommandLineImage,
    architectScale: ArchitectureScaleImage,
    autoDoc: AutoDocImage,
  };

  const [activeTab, setActiveTab] = useState("codeAnalysis");
  const [opacity, setOpacity] = useState(1);
  const [image, setImage] = useState(images[activeTab]);

  useEffect(() => {
    if (activeTab !== null || activeTab !== undefined) {
      setOpacity(0);
      const timeoutId = setTimeout(() => {
        setImage(images[activeTab]);
        setOpacity(1);
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      setActiveTab("codeQuality");
      setImage(images["codeQuality"]);
    }
  }, [activeTab]);

  const [isYearly, setIsYearly] = useState(false);

  const [pricing, setPricing] = useState({
    standard: { price: 0, cycle: "/ month" },
    premium: { price: 35, cycle: "/ month" },
  });

  useEffect(() => {
    if (isYearly) {
      setPricing({
        standard: { price: 0 * 12, cycle: "/ year" },
        premium: { price: 35 * 12 - 20, cycle: "/ year" },
      });
    } else {
      setPricing({
        standard: { price: 0, cycle: "/ month" },
        premium: { price: 35, cycle: "/ month" },
      });
    }
  }, [isYearly]);

  return (
    <Flex
      style={{ overflow: "auto" }}
      direction={"column"}
      bg={"#050505"}
      w={"100%"}
      h={"100vh"}
      pt={"65px"}
    >
      <LandingHeader />
      <Flex
        w={"100%"}
        align={"center"}
        justify={"flex-start"}
        pt={"2em"}
        direction={"column"}
        className="Landing-Background"
        pb="7em"
      >
        <Title align="center" color="gray.6" fz={isMobile ? "2em" : "3em"}>
          Code Smarter, Innovate Faster {!isMobile && <br />} AI-Powered
          Analysis for Startups
        </Title>
        <Title
          mt={"1em"}
          fw={400}
          w={isMobile ? "85%" : "60%"}
          align="center"
          size={isMobile ? "1.25em" : "1.5em"}
          color="gray.6"
        >
          Discover a robust platform designed for startups, offering
          comprehensive analysis to ensure your codebase is not just efficient,
          but future-proof.
        </Title>
        <Button
          color="violet.8"
          variant="default"
          size="lg"
          onClick={() => loginWithRedirect()}
          mt={"3em"}
          className="bg-gradient-to-r from-violet-600 to-indigo-800"
        >
          Elevate Your Codebase
        </Button>
        {!isMobile && (
          <Flex miw={"100%"} mt={"4rem"} mih={"600px"}>
            <Flex align={"center"} justify={"center"} w={"100%"} h={"100%"}>
              <VideoPlayer />
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex
        w={"100%"}
        align={"center"}
        justify={"flex-start"}
        pt={"2em"}
        direction={"column"}
      >
        <Title
          w={"85%"}
          align="start"
          color="gray.6"
          fz={isMobile ? "1.5em" : "2em"}
        >
          Redefining Code Analysis <br />
          Elevating Your Development Experience
        </Title>
        <Flex
          mt={isMobile ? "6em" : "4em"}
          w={"100%"}
          h={isMobile ? "auto" : "650px"}
          direction={isMobile ? "column" : "row"}
        >
          <Flex
            w={isMobile ? "100%" : "50%"}
            h={isMobile ? "55%" : "100%"}
            direction={"column"}
            align={"center"}
            justify={"center"}
            gap={isMobile ? "2em" : "0"}
          >
            {aboutData.map((data, index) => {
              const IconComponent = data.icon;
              return (
                <Flex
                  direction={"column"}
                  w={isMobile ? "85%" : "72%"}
                  h={isMobile ? "auto" : "250px"}
                  key={data.title}
                  onMouseEnter={() => handleMouseEnter(index)}
                  style={{ cursor: "default" }}
                >
                  <Flex
                    gap={"1em"}
                    w={"100%"}
                    pl={isMobile ? "0" : "md"}
                    align={"center"}
                  >
                    {!isMobile && (
                      <div
                        style={{
                          minWidth: "45px",
                          width: "45px",
                          height: "45px",
                          minHeight: "45px",
                          background: "#1a1a1a",
                          borderRadius: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IconComponent
                          size={"25px"}
                          color={
                            hoveredCardIndex === index ? "#7521BC" : "#333333"
                          }
                        />
                      </div>
                    )}
                    <Title
                      color={hoveredCardIndex === index ? "#7521BC" : "#333"}
                      fz={"1.5em"}
                    >
                      {data.title}
                    </Title>
                  </Flex>
                  <Text
                    size={"1em"}
                    pl={isMobile ? "0" : "calc(45px + 2em)"}
                    color={"dimmed"}
                    mt={"1rem"}
                  >
                    {data.description}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
          <Flex
            align={"flex-end"}
            justify={"center"}
            w={isMobile ? "100%" : "50%"}
            h={isMobile ? "45%" : "100%"}
          >
            <Image src={LandingIcon} maw={"600px"} mx={"auto"} />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w={"100%"}
        align={"center"}
        justify={"flex-start"}
        pt={"2em"}
        direction={"column"}
        mt={isMobile ? "2em" : "8em"}
      >
        <Title
          w={"85%"}
          align="start"
          color="gray.6"
          fz={isMobile ? "1.5em" : "2em"}
        >
          A Development System that Innovates
        </Title>
        <Flex
          mt={isMobile ? "1em" : "4em"}
          w={"100%"}
          h={isMobile ? "auto" : "650px"}
        >
          <Flex
            w={isMobile ? "100%" : "40%"}
            align={isMobile ? "center" : "flex-end"}
            direction={"column"}
          >
            <Accordion
              w={isMobile ? "90%" : "80%"}
              defaultValue="codeAnalysis"
              onChange={(value) => {
                setActiveTab(value);
              }}
            >
              <Accordion.Item pt={"md"} pb={"md"} value="codeAnalysis">
                <Accordion.Control>Code Analysis</Accordion.Control>
                <Accordion.Panel>
                  Unlock the full potential of your startup with our meticulous
                  Line-by-Line Code Analysis. By exploring 7 crucial metrics, we
                  empower you to not only establish a robust foundation but also
                  pave the way for scalable growth that investors love. Make
                  your codebase an asset, not a liability.{" "}
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item pt={"md"} pb={"md"} value="codeHealth">
                <Accordion.Control>Code Health Monitor</Accordion.Control>
                <Accordion.Panel>
                  Stay ahead of the game with our Code Health Monitor. Real-time
                  insights reveal opportunities to enhance your code's health
                  and maintainability, ensuring your platform remains
                  cutting-edge.{" "}
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item pt={"md"} pb={"md"} value="commandLine">
                <Accordion.Control>Command Line Interface</Accordion.Control>
                <Accordion.Panel>
                  Streamline your workflow with our intuitive Command Line
                  Interface. Dive into a seamless code analysis experience right
                  from your terminal, where real-time file analysis meets
                  actionable insights. It's your coding environment,
                  supercharged for efficiency.
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item pt={"md"} pb={"md"} value="architectScale">
                <Accordion.Control
                  icon={
                    <Badge
                      variant={"gradient"}
                      gradient={{ from: "violet", to: "indigo", deg: 60 }}
                    >
                      Premium
                    </Badge>
                  }
                >
                  Architecture Scale
                </Accordion.Control>
                <Accordion.Panel>
                  Our premium Architecture Scale feature is your blueprint for
                  success. It ensures your project’s architecture can handle
                  rapid growth and adapt with agility. Benefit from a structure
                  that's designed for efficiency, ready to scale as quickly as
                  your ambitions.
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item pt={"md"} pb={"md"} value="autoDoc">
                <Accordion.Control
                  icon={
                    <Badge
                      variant={"gradient"}
                      gradient={{ from: "violet", to: "indigo", deg: 60 }}
                    >
                      Premium
                    </Badge>
                  }
                >
                  Auto Documenation
                </Accordion.Control>
                <Accordion.Panel>
                  With our Auto Documentation tool, your project stays
                  comprehensively documented, effortlessly. It's premium
                  efficiency: updates are synchronized in real-time, ensuring
                  your team and stakeholders are always aligned with the latest
                  enhancements
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Flex>
          {!isMobile && (
            <Flex
              className="Feature-Background"
              w={"60%"}
              align={"center"}
              justify={"center"}
            >
              <Image
                src={image}
                style={{ opacity: opacity, transition: "opacity 300ms" }}
                maw={"500px"}
                mx={"auto"}
              />{" "}
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex
        w={"100%"}
        align={"center"}
        justify={"flex-start"}
        pt={"2em"}
        direction={"column"}
        mt={isMobile ? "4em" : "8em"}
        className="Pricing-Background"
        pb={"10em"}
      >
        <Title
          w={"85%"}
          align="start"
          color="gray.6"
          fz={isMobile ? "1.5em" : "2em"}
        >
          Pricing & Subscriptions
        </Title>
        <Title
          mt={"0.5em"}
          fw={300}
          w={"85%"}
          size={isMobile ? "1em" : "1.25em"}
          color="gray.6"
        >
          Start with our Free Plan for essential code analysis or upgrade to
          Premium <br /> for expanded access and exclusive features.
        </Title>
        <Flex mt={"1.25em"} w={"85%"} align={"center"} gap={"1em"}>
          <Text color="gray.2">Monthly</Text>
          <Switch
            color="violet.8"
            checked={isYearly}
            onChange={(event) => setIsYearly(event.target.checked)}
          />

          <Text color="gray.2">Yearly</Text>
        </Flex>
        <Flex
          align={isMobile ? "center" : "normal"}
          mt={"4em"}
          w={isMobile ? "100%" : "85%"}
          h={isMobile ? "auto" : "500px"}
          gap={"2em"}
          direction={isMobile ? "column" : "row"}
        >
          <Flex
            p={"xl"}
            direction={"column"}
            bg={"#1a1a1a"}
            style={{ borderRadius: "5px", border: "solid 1px #333333" }}
            w={"360px"}
            h={"100%"}
          >
            <Flex w={"100%"} gap={"0.5em"} align={"flex-end"}>
              <Title fz={"3.5em"} color="gray.2">
                ${pricing.standard.price}
              </Title>
              <Title fw={300} fz={"1.25em"} color="gray.2">
                {pricing.standard.cycle}
              </Title>
            </Flex>
            <Title mt={"0.25em"} fz={"2.75em"} color="gray.2">
              Standard
            </Title>
            <Text mt={"0.75em"} color="gray.2">
              Get started with our fundamental code analysis suite. Ideal for
              individual developers and small projects.
            </Text>
            <Divider mt={"1.5em"} mb={"1em"} />
            <Flex mt={"0.25em"} gap={"1em"} align={"center"}>
              <FaCheckCircle size={"20px"} color="lightgray" />
              <Text color="gray.2">
                Manage <b>2</b> Active Repositories
              </Text>
            </Flex>
            <Flex mt={"1em"} gap={"1em"} align={"center"}>
              <FaCheckCircle size={"20px"} color="lightgray" />
              <Text color="gray.2">
                <b>10</b> Monthly Codebase Reanalysis
              </Text>
            </Flex>
            <Flex mt={"1em"} gap={"1em"} align={"center"}>
              <FaCheckCircle size={"20px"} color="lightgray" />
              <Text color="gray.2">
                <b>15</b> Command Line Analysis Monthly
              </Text>
            </Flex>
          </Flex>
          <Flex
            p={"xl"}
            direction={"column"}
            style={{
              borderRadius: "5px",
              background: "linear-gradient(#7C01DD, #3396FF)",
              border: "solid 1px #333333",
            }}
            w={"360px"}
            h={"100%"}
          >
            <Flex w={"100%"} gap={"0.5em"} align={"flex-end"}>
              <Title fz={"3.5em"} color="gray.2">
                ${pricing.premium.price}
              </Title>
              <Title fw={300} fz={"1.25em"} color="gray.2">
                {pricing.premium.cycle}
              </Title>
            </Flex>
            <Title mt={"0.25em"} fz={"2.75em"} color="gray.2">
              Premium
            </Title>
            <Text mt={"0.75em"} color="gray.2">
              Elevate your code with advanced analytics and features, perfect
              for growing teams and complex projects.
            </Text>
            <Divider color="gray.2" mt={"1.5em"} mb={"1em"} />
            <Flex mt={"0.25em"} gap={"1em"} align={"center"}>
              <FaCheckCircle size={"20px"} color="lightgray" />
              <Text color="gray.2">
                <b>Unlimited</b> Active Repositories
              </Text>
            </Flex>
            <Flex mt={"0.75em"} gap={"1em"} align={"center"}>
              <FaCheckCircle size={"20px"} color="lightgray" />
              <Text color="gray.2">
                <b>Unlimited</b> Repository Reanalysis
              </Text>
            </Flex>
            <Flex mt={"0.75em"} gap={"1em"} align={"center"}>
              <FaCheckCircle size={"20px"} color="lightgray" />
              <Text color="gray.2">
                <b>200</b> Command Line Analysis Monthly
              </Text>
            </Flex>
            <Flex mt={"0.75em"} gap={"1em"} align={"center"}>
              <FaCheckCircle size={"20px"} color="lightgray" />
              <Text color="gray.2">Advanced Analytical Capabilities</Text>
            </Flex>
            <Flex mt={"0.75em"} gap={"1em"} align={"center"}>
              <FaCheckCircle size={"20px"} color="lightgray" />
              <Text color="gray.2">Priority Access to Latest Tools</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w={"100%"}
        align={"center"}
        justify={"space-between"}
        pt={"2em"}
        pb={"3em"}
        bg={"#050505"}
        style={{ borderTop: "solid 1px #333333" }}
        pl={"10%"}
        pr={"10%"}
        className="Footing-Background"
      >
        <Flex
          w={isMobile ? "100%" : "45%"}
          h={"100%"}
          direction={"column"}
          gap={"1em"}
        >
          <Flex align={"center"} gap={isMobile ? "10px" : "0.75em"} w={"100%"}>
            <Image maw={"20px"} src={DeepRefactorMini} />
            <Title order={isMobile ? 5 : 3} color="gray.2">
              Deep Refactor
            </Title>
            <Text fz={isMobile ? "xs" : ""} color="gray.2">
              {" "}
              // AI Driven Code Analysis
            </Text>
          </Flex>
          <Text fz={"sm"} color="dimmed">
            At Deep Refactor, we harness the power of artificial intelligence to
            streamline your development process, ensuring your codebase is not
            only efficient today but primed for the challenges of tomorrow.{" "}
            <br /> <br /> Our suite of tools is designed to enhance code
            quality, reduce technical debt, and accelerate time to market for
            startups and agile teams. Join a community where innovation meets
            efficiency, and start revolutionizing the way you code.
          </Text>
        </Flex>
        {!isMobile && (
          <Flex w={"40%"} h={"100%"} direction={"column"}>
            <Title color="gray.2" fw={400} fz={"1.5em"}>
              Code with Confidence
            </Title>
            <Title mt={"0.25em"} color="gray.2" fw={300} fz={"1.2em"}>
              Power up your startup with cutting-edge AI analysis.
            </Title>
            <Flex mt={"1.5em"}>
              <Button
                color="violet.8"
                style={{ color: "lightgray" }}
                variant="default"
                size="sm"
                onClick={() => loginWithRedirect()}
              >
                Start Optimizing Now
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default Landing;

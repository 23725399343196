import React from "react";
import Backbone from "../Images/IconSVGs/Backbone.svg";
import Boost from "../Images/IconSVGs/Boost.svg";
import Bulma from "../Images/IconSVGs/Bulma.svg";
import CodeIgniter from "../Images/IconSVGs/CodeIgniter.svg";
import Deno from "../Images/IconSVGs/Deno.svg";
import Express from "../Images/IconSVGs/Express.svg";
import FastAPI from "../Images/IconSVGs/FastAPI.svg";
import Flask from "../Images/IconSVGs/Flask.svg";
import Foundation from "../Images/IconSVGs/Foundation.svg";
import Hanami from "../Images/IconSVGs/Hanami.svg";
import Hibernate from "../Images/IconSVGs/Hibernate.svg";
import JavaFX from "../Images/IconSVGs/JavaFX.svg";
import Laravel from "../Images/IconSVGs/Laravel.svg";
import Materialize_CSS from "../Images/IconSVGs/Materialize_CSS.svg";
import MFC from "../Images/IconSVGs/MFC.svg";
import NET from "../Images/IconSVGs/NET.svg";
import Phalcon from "../Images/IconSVGs/Phalcon.svg";
import Pyramid from "../Images/IconSVGs/Pyramid.svg";
import Qt from "../Images/IconSVGs/Qt.svg";
import Rails from "../Images/IconSVGs/Rails.svg";
import Sinatra from "../Images/IconSVGs/Sinatra.svg";
import Spring from "../Images/IconSVGs/Spring.svg";
import Struts from "../Images/IconSVGs/Struts.svg";
import Symfony from "../Images/IconSVGs/Symfony.svg";
import Unity from "../Images/IconSVGs/Unity.svg";
import Xamarin from "../Images/IconSVGs/Xamarin.svg";
import Yii from "../Images/IconSVGs/Yii.svg";
import { Image } from "@mantine/core";

const brandIcons = {
  Backbone,
  Boost,
  Bulma,
  CodeIgniter,
  Deno,
  Express,
  FastAPI,
  Flask,
  Foundation,
  Hanami,
  Hibernate,
  JavaFX,
  Laravel,
  Materialize_CSS,
  MFC,
  NET,
  Phalcon,
  Pyramid,
  Qt,
  Rails,
  Sinatra,
  Spring,
  Struts,
  Symfony,
  Unity,
  Xamarin,
  Yii,
};

function Icon({ brand }) {
  const SvgIcon = brandIcons[brand];
  if (!SvgIcon) {
    console.error(`Icon not found: ${brand}`);
    return null;
  }

  return (
    <Image
      maxHeight={12.5}
      maxWidth={12.5}
      style={{ filter: "grayscale(100%)", maxHeight: "12.5px", maxWidth: "12.5px" }}
      src={SvgIcon}
      alt={`${brand} logo`}
    />
  );
}
export default Icon;

import {
  Avatar,
  Button,
  Card,
  Divider,
  Flex,
  Text,
  Title,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import GitHubLogo from "../Images/GithubLogo.svg";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import FileTree from "../Components/FileTree";
import LanguageTab from "../Components/LanguageTab";
import RepositoryForm from "../Components/RepositoryForm";
import RepoLoading from "../Components/RepoLoading";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "@mantine/hooks";
import HexLoader from "../Components/HexLoader";

function parseFilesToTree(files) {
  const tree = {};

  files.forEach((filePath) => {
    const pathParts = filePath.split("/");
    let currentLevel = tree;

    pathParts.forEach((part) => {
      if (!currentLevel[part]) {
        currentLevel[part] = {};
      }
      currentLevel = currentLevel[part];
    });
  });

  return tree;
}

function NewRepo() {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [repoData, setRepoData] = useState(null);
  const [fileTree, setFileTree] = useState(null);
  const [url, setURL] = useState(null);
  const [selectedValues, setSelectedValues] = useState(["CodeQuality"]);
  const [projectName, setProjectName] = useState("");
  const [selectedFrameworks, setSelectedFrameworks] = useState([""]);
  const [isAnalysisStarted, setIsAnalysisStarted] = useState(false);
  const [error, setError] = useState(null);
  const [loadingError, setLoadingError] = useState(null);

  const { user, getAccessTokenSilently } = useAuth0();
  const [activeJob, setActiveJob] = useState(null);
  const [activatedFeatures, setActivatedFeatures] = useState({});

  const toggleFeatureActivation = (featureId) => {
    setActivatedFeatures((prevState) => ({
      ...prevState,
      [featureId]: !prevState[featureId],
    }));
    console.log(activatedFeatures);
  };

  useEffect(() => {
    const fetchRepoData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `https://api.deeprefactor.dev/github/repos/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRepoData(response.data);
        setProjectName(response.data.details.name);
        const userDetailsResponse = await getUserDetails(token);
        setUserData(userDetailsResponse);
        const fileTreeData = parseFilesToTree(response.data.files);
        setFileTree(fileTreeData);
        await fetchActiveJob(token);
      } catch (err) {
        console.error("Failed to fetch repository data", err);
        setLoadingError("Failed to fetch repository data");
      }
    };

    async function fetchActiveJob(token) {
      try {
        const response = await axios.get(
          "https://api.deeprefactor.dev/active-job",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setActiveJob(response.data);
        if (response?.data) {
          setIsAnalysisStarted(true);
        }
      } catch (error) {
        console.error("Failed to fetch active job:", error);
      }
    }

    fetchRepoData();
  }, [id]);

  const handleSubmit = async () => {
    const designInsightActivated = activatedFeatures && activatedFeatures["Design Insight"];
    if (projectName && selectedValues.length > 0) {
      if (designInsightActivated) {
        // Validate URL with regex only if Design Insight is activated
        const regex = new RegExp(/^(https?:\/\/www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/);
        if (!regex.test(url)) {
          alert("Invalid URL, please enter a valid URL in the form of http(s)://www.example.com");
          return;
        }
      }
      setIsAnalysisStarted(true);
      try {
        setError(null);
        const selectedActivatedFeatures = Object.entries(activatedFeatures)
          .filter(([_, value]) => value === true)
          .map(([key, _]) => key);
        const token = await getAccessTokenSilently();
        await axios.post(
          "https://api.deeprefactor.dev/start-job", //Fix back to https
          {
            repoID: id,
            languages: Object.keys(repoData.languages),
            projectName: projectName,
            frameworks: selectedFrameworks,
            choices: selectedValues,
            premiumChoices: selectedActivatedFeatures,
            url: url,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Analysis started");
      } catch (error) {
        console.error("Failed to start analysis", error);
        setError("Failed to start analysis");
      }
    } else {
      console.error("Invalid inputs");
      if (!projectName) {
        console.error("Project name is invalid");
      }
      if (!selectedValues.length) {
        console.error("No analytical task selected");
      }
    }
  };

  async function getUserDetails(token) {
    try {
      const response = await axios.get("https://api.deeprefactor.dev/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("User Info", response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const isMobile = useMediaQuery("(max-width: 50em)");

  return (
    <Flex direction={"column"} bg={"#121212"} w={"100%"} h={"100%"}>
      <Header userData={userData} showButton={false} />
      {!repoData ? (
        <Flex
          style={{ borderTop: "1px #333333 solid" }}
          w={"100%"}
          h={"100%"}
          align={"center"}
          justify={"center"}
          direction={"column"}
          bg={"#121212"}
        >
          {loadingError ? (
            <>
              <Title maw={"50%"} color="red.8" order={1} align="center">
                We encountered an issue loading the repository data. This might
                be due to the repository being empty, containing only ineligible
                files, or a network issue.
              </Title>
              <Button
                mt={"md"}
                onClick={() => navigate("/dashboard")}
                variant="outline"
              >
                Return to Dashboard
              </Button>
            </>
          ) : (
            <HexLoader/>
          )}
        </Flex>
      ) : (
        <Flex
          direction={"column"}
          w={"100%"}
          h={"100%"}
          style={{ position: "relative" }}
          bg={"#121212"}
        >
          <Flex
            w={"100%"}
            mih={"20%"}
            h={"20%"}
            style={{ borderBottom: "1px #333333 solid" }}
            align={"flex-end"}
            direction={"column"}
            bg={"#1D1D1D"}
          >
            <Flex
              w={"100%"}
              h={"45%"}
              align={"center"}
              justify={"flex-start"}
              p={"md"}
            >
              <Button
                variant={"subtle"}
                color={"dark.1"}
                leftIcon={<IconArrowNarrowLeft />}
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Back
              </Button>
            </Flex>
            <Flex
              w={"100%"}
              h={isMobile ? "100%" : "55%"}
              align={"center"}
              justify={"space-evenly"}
            >
              <Flex w={isMobile ? "100%" : "25%"} h={"65%"} p={"sm"}>
                <Flex
                  w={"100%"}
                  h={"100%"}
                  style={{ borderRadius: "5px" }}
                  align={"center"}
                  justify={"flex-start"}
                  gap={"sm"}
                >
                  <Avatar
                    radius={"50%"}
                    style={{ border: "solid 1px #CDCDCD", filter: "invert(1)" }}
                    size={"2em"}
                    src={GitHubLogo}
                  />
                  <Title color="#A0A0A0" order={4}>
                    {repoData.details.name}
                  </Title>
                </Flex>
              </Flex>
              <Flex w={"70%"} h={"50%"} />
            </Flex>
          </Flex>
          <Flex justify={"space-evenly"} w={"100%"} bg={"#121212"}>
            {!isMobile && (
              <Flex direction={"column"} w={"25%"} h={"95%"} p={"sm"}>
                <Flex mt={"lg"} direction={"column"} w={"100%"}>
                  <Text color="#A0A0A0" fz={"lg"} fw={600} mb={"sm"}>
                    Languages
                  </Text>
                  {repoData ? (
                    Object.keys(repoData.languages).length > 0 ? (
                      Object.entries(repoData.languages).map(
                        ([language, value]) => {
                          const totalLanguageLines = Object.values(
                            repoData.languages
                          ).reduce((acc, val) => acc + val, 0);
                          const percentage = (
                            (value / totalLanguageLines) *
                            100
                          ).toFixed(2);
                          return (
                            <LanguageTab
                              key={language}
                              language={language}
                              percentage={percentage}
                            />
                          );
                        }
                      )
                    ) : (
                      <Text color="#A0A0A0" fz={"sm"} italic>
                        No languages found
                      </Text>
                    )
                  ) : (
                    <Text color="#A0A0A0">Loading...</Text>
                  )}
                </Flex>
                <Divider mt={"md"} mb={"md"} w={"100%"} color="#333333" />
                <Flex direction={"column"}>
                  <Text color="#A0A0A0" fz={"lg"} fw={600} mb={"sm"}>
                    File Structure
                  </Text>
                  {fileTree && <FileTree tree={fileTree} />}
                </Flex>
              </Flex>
            )}
            <Card
              w={isMobile ? "95%" : "70%"}
              h={isMobile ? "100%" : "auto"}
              withBorder
              shadow={"sm"}
              mt={isMobile ? "1em" : "-7em"}
              style={{ overflow: "auto" }}
              mb={"lg"}
            >
              {isAnalysisStarted ? (
                <RepoLoading id={id} error={error} />
              ) : (
                <RepositoryForm
                  id={id}
                  projectName={projectName}
                  setProjectName={setProjectName}
                  selectedFrameworks={selectedFrameworks}
                  setSelectedFrameworks={setSelectedFrameworks}
                  repoData={repoData}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  handleSubmit={handleSubmit}
                  userData={userData}
                  activatedFeatures={activatedFeatures}
                  toggleFeatureActivation={toggleFeatureActivation}
                  url={url}
                  setURL={setURL}
                />
              )}
            </Card>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default NewRepo;

import {
  Flex,
  Avatar,
  Title,
  Card,
  Badge,
  Divider,
  Text,
  Checkbox,
  Button,
  ActionIcon,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import GitHubLogo from "../Images/GithubLogo.svg";
import {
  IconAnalyze,
  IconChevronDown,
  IconChevronUp,
  IconFile,
} from "@tabler/icons-react";
import "../App.css";

function AnalyticsOverview({ repoData, userData }) {
  console.log("Repo Data", repoData);
  function getUserFriendlyTitle(promptId) {
    const formattedId = promptId
      .split(/(?=[A-Z])/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");

    return formattedId;
  }

  const [showTasks, setShowTasks] = useState([]);

  function handleShowTasks(type) {
    if (showTasks.includes(type)) {
      setShowTasks(showTasks.filter((task) => task !== type));
    } else {
      setShowTasks([...showTasks, type]);
    }
  }

  // Helper function to get unique comment types
  const getCommentTypes = () => {
    const types = new Set();
    repoData.files.forEach((file) => {
      file.comments.forEach((comment) => {
        types.add(comment.type);
      });
    });
    return Array.from(types);
  };

  // Helper function to count issues by severity and type
  const countIssues = (type, severity) => {
    return repoData.files.reduce((count, file) => {
      return (
        count +
        file.comments.filter(
          (comment) => comment.type === type && comment.severity === severity
        ).length
      );
    }, 0);
  };

  // Helper function to count LoC and files analyzed for a particular comment type
  const countLinesAndFiles = (type) => {
    const filesWithComments = repoData.files.filter((file) =>
      file.comments.some((comment) => comment.type === type)
    );
    const linesCount = filesWithComments.reduce((total, file) => {
      return total + (file.content ? file.content.split("\n").length : 0);
    }, 0);
    return { linesCount, filesCount: filesWithComments.length };
  };

  // Helper function to count action items for a particular comment type
  const countActionItems = (type) => {
    return repoData.actionItems.filter((item) => item.feedback_type === type)
      .length;
  };

  // Get unique comment types
  const commentTypes = getCommentTypes();

  const calculateScore = (type) => {
    const weights = { Critical: 3, Medium: 2, Low: 1 };
    const totalScore = repoData.files.reduce((score, file) => {
      file.comments.forEach((comment) => {
        if (comment.type === type) {
          score += weights[comment.severity];
        }
      });
      return score;
    }, 0);

    return totalScore;
  };

  const getIndicatorColors = (
    score,
    thresholds = { critical: 10, medium: 5 }
  ) => {
    if (score >= thresholds.critical) {
      return ["#FF4C4C", "#CCCCCC", "#CCCCCC"]; // Critical
    } else if (score >= thresholds.medium) {
      return ["#FFA534", "#FFA534", "#CCCCCC"]; // Medium
    } else {
      return ["#29CC97", "#29CC97", "#29CC97"]; // Low
    }
  };

  return (
    <Flex
      direction={"column"}
      bg={"#121212"}
      w={"100%"}
      h={"100%"}
      mah={"calc(100vh - 125px)"}
    >
      <Flex
        pl={"xl"}
        pr={"xl"}
        align={"center"}
        justify={"space-between"}
        w={"100%"}
        h={80}
        bg={"#1D1D1D"}
        style={{ borderBottom: "1px #333333 solid" }}
      >
        <Flex
          w={"100%"}
          h={"100%"}
          style={{ borderRadius: "5px" }}
          align={"center"}
          justify={"flex-start"}
          gap={"xl"}
        >
          <Avatar
            radius={"50%"}
            style={{ border: "solid 1px #CDCDCD", filter: "invert(1)" }}
            size={"3em"}
            src={GitHubLogo}
          />
          <Title color="#E0E0E0" order={2} fw={500}>
            {repoData?.project_name}
          </Title>
        </Flex>
        {/* <Button color={"blue.8"} variant={"outline"}>
          Generate Report
        </Button> */}
      </Flex>
      <Flex
        w={"100%"}
        h={"100%"}
        align={"flex-start"}
        justify={"center"}
        p={"xl"}
        pt={"3em"}
        maw={"100vw"}
        wrap={"wrap"}
        style={{ overflow: "auto" }}
      >
        {commentTypes.map((type) => {
          const criticalCount = countIssues(type, "Critical");
          const mediumCount = countIssues(type, "Medium");
          const lowCount = countIssues(type, "Low");
          const { linesCount, filesCount } = countLinesAndFiles(type);
          const actionItemsCount = countActionItems(type);
          const score = calculateScore(type);
          const indicatorColors = getIndicatorColors(score);

          return (
            <Flex
              p={"2.5em"}
              pt={"2em"}
              pb={"0em"}
              direction={"column"}
              style={{ border: "solid 1px #333333", borderRadius: "10px" }}
              bg={"#0D0D0D"}
              w={"47.5%"}
              miw={"600px"}
              mih={"380px"}
              h={"auto"}
              key={type}
              mt={"1em"}
              ml={"1em"}
              mr={"1em"}
            >
              <Flex
                align={"center"}
                w={"100%"}
                gap={"2em"}
                justify={"space-between"}
              >
                <Title w={"100%"} fz={"1.45em"} color={"#E0E0E0"}>
                  {getUserFriendlyTitle(type)}
                </Title>
                <Flex
                  align={"center"}
                  justify={"flex-end"}
                  gap={"1em"}
                  h={"100%"}
                >
                  {indicatorColors.map((color, index) => (
                    <Flex
                      key={index}
                      w={"50px"}
                      h={"17.5px"}
                      bg={color}
                      style={{
                        borderRadius: "10px",
                        border: "solid 0.25px #333333",
                      }}
                    />
                  ))}
                </Flex>
              </Flex>
              <Flex mt={"sm"} w={"100%"} gap={"2em"}>
                <Flex gap={"0.5em"}>
                  <Text size={"md"} color={"#E0E0E0"}>
                    LoC Analyzed:
                  </Text>
                  <Text size={"md"} color="#E0E0E0" fw={300}>
                    {linesCount}
                  </Text>
                </Flex>
                <Flex gap={"0.5em"}>
                  <Text size={"md"} color={"#E0E0E0"}>
                    Files:
                  </Text>
                  <Text size={"md"} color="#E0E0E0" fw={300}>
                    {filesCount}
                  </Text>
                </Flex>
              </Flex>
              <Flex
                align={"center"}
                justify={"space-between"}
                w={"100%"}
                h={"70%"}
                pt={"sm"}
                pb={"sm"}
                style={{ borderBottom: "solid 3px #1D2737" }}
              >
                <Flex
                  direction={"column"}
                  gap={"1em"}
                  align={"center"}
                  justify={"center"}
                  maw={"200px"}
                  w={"22.5%"}
                  h={"100%"}
                >
                  <Flex
                    align={"center"}
                    justify={"center"}
                    w={"80px"}
                    h={"80px"}
                    style={{ borderRadius: "50%", border: "solid 5px #E33333" }}
                  >
                    <Title
                      w={"100%"}
                      h={"100%"}
                      align="center"
                      color="#E0E0E0"
                      fz={"3em"}
                    >
                      {criticalCount}
                    </Title>
                  </Flex>
                  <Text fz={"md"} fw={400} color="#E0E0E0">
                    Critical Issues
                  </Text>
                </Flex>
                <Flex
                  direction={"column"}
                  gap={"1em"}
                  align={"center"}
                  justify={"center"}
                  maw={"200px"}
                  w={"22.5%"}
                  h={"100%"}
                >
                  <Flex
                    align={"center"}
                    justify={"center"}
                    w={"80px"}
                    h={"80px"}
                    style={{ borderRadius: "50%", border: "solid 5px #E6A33E" }}
                  >
                    <Title
                      w={"100%"}
                      h={"100%"}
                      align="center"
                      color="#E0E0E0"
                      fz={"3em"}
                    >
                      {mediumCount}
                    </Title>
                  </Flex>
                  <Text fz={"md"} fw={400} color="#E0E0E0">
                    Medium Issues
                  </Text>
                </Flex>
                <Flex
                  direction={"column"}
                  gap={"1em"}
                  align={"center"}
                  justify={"center"}
                  maw={"200px"}
                  w={"22.5%"}
                  h={"100%"}
                >
                  <Flex
                    align={"center"}
                    justify={"center"}
                    w={"80px"}
                    h={"80px"}
                    style={{ borderRadius: "50%", border: "solid 5px #13A010" }}
                  >
                    <Title
                      w={"100%"}
                      h={"100%"}
                      align="center"
                      color="#E0E0E0"
                      fz={"3em"}
                    >
                      {lowCount}
                    </Title>
                  </Flex>
                  <Text fz={"md"} fw={400} color="#E0E0E0">
                    Low Issues
                  </Text>
                </Flex>
                <Flex
                  direction={"column"}
                  gap={"1em"}
                  align={"center"}
                  justify={"center"}
                  maw={"250px"}
                  w={"30%"}
                  h={"100%"}
                  pt={"lg"}
                  pb={"lg"}
                >
                  <Flex
                    align={"center"}
                    justify={"center"}
                    w={"100px"}
                    h={"80px"}
                    style={{
                      borderRadius: "50%",
                      border: "solid 5px transparent",
                    }}
                  >
                    <Title
                      w={"100%"}
                      h={"100%"}
                      align="center"
                      color="#E0E0E0"
                      fz={"3.5em"}
                    >
                      {actionItemsCount}
                    </Title>
                  </Flex>
                  <Text fz={"md"} fw={400} color="#E0E0E0">
                    Recommended Tasks
                  </Text>
                </Flex>
              </Flex>
              <Flex
                align={"flex-start"}
                mt={"0.5em"}
                gap={"1em"}
                w={"100%"}
                h={"20%"}
                direction={"column"}
                pt={"lg"}
              >
                {repoData?.actionItems.length > 0 && (
                  <>
                    <Flex w={"100%"} justify={"space-between"} align={"center"}>
                      <Text fw={600} fz={"lg"} color={"#E0E0E0"}>
                        Recommended Tasks
                      </Text>
                      <ActionIcon
                        onClick={() => {
                          handleShowTasks(type);
                        }}
                      >
                        {showTasks.includes(type) ? (
                          <IconChevronUp size={20} color={"#E0E0E0"} />
                        ) : (
                          <IconChevronDown size={20} color={"#E0E0E0"} />
                        )}
                      </ActionIcon>
                    </Flex>
                    {showTasks.includes(type) &&
                      repoData?.actionItems
                        .filter((item) => item.feedback_type === type)
                        .map((item, index) => {
                          console.log(item);
                          return (
                            <Flex
                              gap="sm"
                              align={"flex-startt"}
                              key={index}
                              w={"100%"}
                            >
                              <Text fw={600} color={"#7521BC"}>{index + 1}.</Text>
                              <Text pt={0} color={"dimmed"}>
                                {item.task_description}
                              </Text>
                            </Flex>
                          );
                        })}
                    ;
                  </>
                )}
              </Flex>
            </Flex>
          );
        })}
        ;
      </Flex>
    </Flex>
  );
}

export default AnalyticsOverview;

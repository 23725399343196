import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Card,
  Flex,
  Menu,
  Modal,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import GitHub from "../Images/GithubLogo.svg";
import "../App.css";
import { useAuth0 } from "@auth0/auth0-react";
import io from "socket.io-client";
import { Ring } from "@uiball/loaders";
import { IconCheck, IconX } from "@tabler/icons-react";

function ActiveJobCard({ data }) {
  const [mostRecentLog, setMostRecentLog] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const [status, setStatus] = useState("analyzing");

  console.log('Data woooooo', data)

  useEffect(() => {
    const socket = io("https://api.deeprefactor.dev");

    getAccessTokenSilently().then((token) => {
      socket.emit("join", token);
    });

    socket.on("status-update", (data) => {
        if (data && data.status) {
          let message = data.status;
          if (message.includes("[ERROR-S]")) {
            message = message.replace("[ERROR-S]", "").trim();
            setStatus("failed");
          } else if (message.includes("[FINISHED]")) {
            message = message.replace("[FINISHED]", "").trim();
            setStatus("complete");
          }
          setMostRecentLog(message);
        } else {
          console.error("Invalid status update received", data);
        }
      });
      

    socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });

    return () => {
      socket.disconnect();
    };
  }, [getAccessTokenSilently]);

  const renderStatus = () => {
    switch (status) {
      case "analyzing":
        return (
          <Flex align="center" justify="flex-start" gap="sm">
            <Ring size={25} color="#7521BC" />
            <Text>Analyzing...</Text>
          </Flex>
        );
      case "failed":
        return (
          <Flex align="center" justify="flex-start" gap="sm">
            <IconX color="red"/>
            <Text>Process Failed</Text>
          </Flex>
        );
      case "complete":
        return (
          <Flex align="center" direction={'column'} justify="flex-start" gap="md">
            <Flex w={'100%'} align={'center'} gap={'sm'}><IconCheck color="green"/>
            <Text>Analysis Complete</Text>
            </Flex>
            <Button variant="default" onClick={() => window.location.href = `/analytics/${data?.repoID}`}>
              View Project
            </Button>
          </Flex>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Card
        style={{ position: "relative", overflow: "visible" }}
        radius={"md"}
        shadow={"sm"}
        miw={410}
        w={435}
        h={250}
        withBorder
        className="Card"
      >
        <Flex
          direction={"column"}
          align={"center"}
          justify={"space-between"}
          w={"100%"}
          h={"100%"}
        >
          <Flex align={"center"} w={"100%"} h={"35%"}>
            <Avatar
              src={GitHub}
              radius={"50%"}
              color={"violet"}
              size={"3.75em"}
              style={{ border: 'solid 1px #CDCDCD', filter: 'invert(1)' }}
            />
            <Flex
              justify={"center"}
              direction={"column"}
              w={"100%"}
              h={"100%"}
              pl={"lg"}
            >
              <Text fz={"1.2em"} truncate="end" fw={800} lineClamp={1} maw={'82.5%'}>
                {data?.projectName && data?.projectName || data?.project_name && data?.project_name}
              </Text>
              <Flex mt={"xs"} align={"center"} gap={"xs"} w={"100%"}>
                {data?.languages && data?.languages.map((language) => (
                  <Badge key={language} variant="outline" color="violet.8">
                    <Text>{language}</Text>
                  </Badge>
                ))}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            pl={"xl"}
            w={"100%"}
            h={"60%"}
            gap={"sm"}
            direction={"column"}
            justify={"flex-start"}
            align={"flex-start"}
          >
            {renderStatus()}
            <Text mt={"sm"} fz={"sm"}>
              {mostRecentLog ? mostRecentLog : "No recent logs available"}
            </Text>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}

export default ActiveJobCard;

import React, { useRef, useEffect } from "react";
import MonacoEditor from "react-monaco-editor";
import "../App.css";

function CodeEditor({ code, comments, hoveredComment, path, activeComment }) {
  const editorRef = useRef(null);
  const previousDecorationsRef = useRef([]); // New ref to store previous decorations

  const currentLanguage = path
    ? getLanguageFromPath(path)
    : "plaintext";

  function getLanguageFromPath(filePath) {
    const extension = filePath.split(".").pop().toLowerCase();

    const languageMap = {
      js: "javascript",
      jsx: "javascript",
      ts: "typescript",
      tsx: "typescript",
      py: "python",
      java: "java",
      c: "c",
      cpp: "cpp",
      cs: "csharp",
      rb: "ruby",
      php: "php",
      html: "html",
      css: "css",
      scss: "scss",
      md: "markdown",
      json: "json",
      xml: "xml",
      yml: "yaml",
      yaml: "yaml",
      sh: "shell",
      bash: "shell",
      txt: "plaintext",
    };

    return languageMap[extension] || "plaintext";
  }

  useEffect(() => {
    if (!editorRef.current?.editor) return; // Guarding for editor

    const editorInstance = editorRef.current.editor;
    const monacoInstance = editorRef.current.monaco;
    const model = editorInstance.getModel();

    if (!model) return; // Guarding for model

    // Clear any previous decorations
    const oldDecorationIds = previousDecorationsRef.current;
    editorInstance.deltaDecorations(oldDecorationIds, []);

    // Add new decorations for comments
    const newDecorations = (comments).map((comment) => {
      const classNameBasedOnType = `commentType-${comment.type}`;
      return {
        range: new monacoInstance.Range(
          comment.line_start,
          1,
          comment.line_end,
          1
        ),
        options: {
          isWholeLine: true,
          className: classNameBasedOnType,
          colorDecorations: true,
        },
      };
    });

    if (hoveredComment) {
      newDecorations.push({
        range: new monacoInstance.Range(
          hoveredComment.line_start,
          1,
          hoveredComment.line_end,
          1
        ),
        options: {
          isWholeLine: true,
          className: "myHoveredLineDecoration",
        },
      });
      editorInstance.revealLine(hoveredComment.line_start);
    }

    if (activeComment) {
      newDecorations.push({
        range: new monacoInstance.Range(
          activeComment.line_start,
          1,
          activeComment.line_end,
          1
        ),
        options: {
          isWholeLine: true,
          className: "myHoveredLineDecoration",
        },
      });
      editorInstance.revealLine(activeComment.line_start);
    }

    const newDecorationIds = editorInstance.deltaDecorations(
      [],
      newDecorations
    );
    previousDecorationsRef.current = newDecorationIds; // Storing the new decorations
  }, [comments, hoveredComment, activeComment]);

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = { editor, monaco };
  };

  const editorValue =
    code?.length > 0
      ? code
      : "Select a file from the explorer to start viewing comments and editing your code!";

  return (
    <MonacoEditor
      width="100%"
      height="650px"
      language={currentLanguage ? currentLanguage : "plaintext"}
      theme="vs-dark"
      value={editorValue}
      options={{
        lineNumbers: "on",
        roundedSelection: false,
        scrollBeyondLastLine: true,
        readOnly: false,
      }}
      editorDidMount={handleEditorDidMount}
    />
  );
}

export default CodeEditor;

import { Button, Flex, Image } from "@mantine/core";
import React from "react";
import DeepRefactorLogo from "../Images/DeepRefactorLogo.png";
import { useAuth0 } from "@auth0/auth0-react";

function LandingHeader() {

  const { loginWithRedirect } = useAuth0();

  return (
    <Flex
      w={"100%"}
      h={65}
      direction={"column"}
      align={"center"}
      justify={"center"}
      style={{position: 'fixed', top: 0, zIndex: 999}}
    >
      <Flex
        p={"md"}
        w={"100%"}
        h={65}
        direction={"row"}
        align={"space-between"}
        justify={"center"}
      >
        <Flex
          w={"75%"}
          h={"100%"}
          direction={"row"}
          align={"center"}
          justify={"flex-start"}
        >
          <Image maw={225} radius="md" src={DeepRefactorLogo} />
        </Flex>
        <Flex
          w={"25%"}
          h={"100%"}
          direction={"row"}
          align={"center"}
          justify={"flex-end"}
          gap={"sm"}
        >
            <Button onClick={() => loginWithRedirect()} variant={'outline'} color="gray.1">Login</Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LandingHeader;

import { Code, Flex } from '@mantine/core'
import React from 'react'

function DocumentationLogout() {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden pb-[6em]">
    <header className="text-[2.5em] mt-4 text-white overflow-hidden">
      <span className="bg-[#1a1a1a] p-4 px-6 text-gray-400 border-[#333333]">
        deep-refactor logout
      </span>
    </header>
    <text className="text-gray-400 mt-8 w-3/4">
      The <span className="font-bold">logout</span> command securely terminates your session with the Deep Refactor CLI, ensuring that your API key and session data are cleared from the current terminal.
    </text>
    <header className="text-[1.5em] text-white mt-8">Usage:</header>
    <Flex>
      <Code className="mt-4 p-2 px-4">deep-refactor logout</Code>
    </Flex>
    <header className="text-[1.5em] text-white mt-8">Steps for Logging Out:</header>
    <ol className="text-gray-400 mt-4 list-decimal list-inside">
      <li className="mt-4">
        Execute the
        <span className="px-2 py-[2px] bg-gray-900 border border-[#333333] rounded-sm ml-2 mr-2">
          logout
        </span>
        command in your terminal.
      </li>
      <li className="mt-4">
        The CLI will clear any stored session data.
      </li>
    </ol>
    <text className="text-violet-700 mt-8 w-3/4">
      <span className='font-bold'>Note:</span> Logging out does not revoke your API key. If necessary, you can do this from the Deep Refactor dashboard.
    </text>
  </div>
  )
}

export default DocumentationLogout
import { Button, Divider, Flex, Text, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Ring } from "@uiball/loaders";
import axios from "axios";
import io from "socket.io-client";
import { IconCheck, IconExclamationCircle, IconX } from "@tabler/icons-react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

function RepoLoading({
  id,
  error,
}) {
  const navigate = useNavigate();

  const viewReport = () => {
    navigate(`/analytics/${id}`);
  };

  const [countdown, setCountdown] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [statusUpdates, setStatusUpdates] = useState([]);
  const [analysisComplete, setAnalysisComplete] = useState(false);


  useEffect(() => {
    const socket = io("https://api.deeprefactor.dev");

    getAccessTokenSilently().then((token) => {
      socket.emit("join", token);
    });

    socket.on("status-update", (data) => {
      console.log("Status Update:", data);
      if (data && data.status) {
        const currentTime = new Date().toLocaleTimeString();
        const matches = data.status.match(/\[(\d+:\d+)\]\s*(.+)/);
        const timestamp = matches ? matches[1] : "";
        const message = matches ? matches[2] : data.status;

        if (data.status.includes("GitHub API rate limit hit")) {
          const matches = data.status.match(/(\d+)\sminutes/);
          if (matches) {
            const minutes = parseInt(matches[1]);
            setCountdown(minutes * 60);
            setIsTimerActive(true);
          }
        }

        const isFinished = data.status.includes('[FINISHED]');
        if (isFinished) {
          setAnalysisComplete(true);
        }

        setStatusUpdates((prevStatusUpdates) => {
          return [
            ...prevStatusUpdates,
            { timestamp, text: message, timestampRaw: currentTime },
          ];
        });
      } else {
        console.error("Invalid status update received", data);
      }
    });

    socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });

    return () => {
      socket.disconnect();
    };
  }, [getAccessTokenSilently]);

  const checkForActiveJob = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get("https://api.deeprefactor.dev/active-job", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.jobId) {
        console.log("Response", response.data);
        fetchStatusMessages();
      }
    } catch (error) {
      console.error("Error checking for active job:", error);
    }
  };

  const fetchStatusMessages = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`https://api.deeprefactor.dev/job-status/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.logs) {
        setStatusUpdates(
          response.data.logs.map((log) => ({
            text: log,
            timestamp: new Date().toLocaleTimeString(),
          }))
        );
      }
    } catch (error) {
      console.error("Failed to fetch status messages:", error);
    }
  };

  useEffect(() => {
    checkForActiveJob();
  }, []);

  useEffect(() => {
    let interval;
    if (isTimerActive && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((countdown) => countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, countdown]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  const getIcon = (message, isLatest) => {
    if (message.includes("[ERROR-S]")) {
      return <IconX color="red"/>;
    }
    if (message.includes("[ERROR-R]")) {
      return <Ring size={25} color="#0F52BA" />;
    }
    if (message.includes("[FINISHED]")) {
      return <IconCheck color="green"/>;
    }
    return isLatest ? <Ring size={25} color="#0F52BA" /> : <IconCheck color="green"/>;
  };

  return (
    <Flex gap={"sm"} direction={"column"} w={"100%"} h={"100%"} p={"md"}>
      <Title order={3} fw={500}>
        Analyzing Repository
      </Title>
      <Flex align={"center"} gap={"sm"}>
        <IconExclamationCircle size={22.5} color={"#7521BC"} />
        <Text>Don't leave the page or refresh!</Text>
      </Flex>
      <Flex mt={'2em'} direction="column" gap="md">
        {statusUpdates.map((update, index) => (
          <Flex key={index} justify="space-between">
            <Text>
            <Flex gap={'lg'} justify={'flex-start'} align={'center'}>
              {getIcon(update.text, index === statusUpdates.length - 1)}{" "}
              {update.text}
              </Flex>
            </Text>
            <Text>{update.timestampRaw}</Text>
          </Flex>
        ))}
      </Flex>

      {isTimerActive && (
        <Text>Time until process continues: {formatTime(countdown)}</Text>
      )}

      {error && <Text color="red">{error}</Text>}
      <Divider mt={"lg"} />
      <Flex mt={"lg"} align={"center"} justify={"center"}>
        {analysisComplete && (
          <Button
            onClick={() => {
              viewReport();
            }}
            color={"violet.8"}
            w={"100%"}
          >
            View Report
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default RepoLoading;

import React, { useState } from "react";
import { Flex, Button, Card, Text, Title, Divider } from "@mantine/core";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { IconCopy, IconDownload } from "@tabler/icons-react";

function AutoDocOverview({ repoData, userData }) {
  const [showCopiedNotification, setShowCopiedNotification] = useState(false);

  const mapDocumentationSuggestions = (suggestions) => {
    return suggestions
      .filter((suggestion) => suggestion.start_line === -1)
      .map((suggestion, index) => (
        <Card
          key={index}
          p="md"
          mb="md"
          style={{ borderBottom: "solid 3px #7521BC", overflow: "visible" }}
          radius={0}
          h={"auto"}
          
        >
          <Text fw={500} size="sm" color="dimmed">
            {suggestion.file_path}
          </Text>
          <Text mt={"0.5em"}>{suggestion.comment_text}</Text>
        </Card>
      ));
  };

  const copyReadMeToClipboard = () => {
    if (navigator.clipboard && repoData?.readme) {
      navigator.clipboard
        .writeText(repoData.readme)
        .then(() => {
          setShowCopiedNotification(true);
          setTimeout(() => {
            setShowCopiedNotification(false);
          }, 2000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };

  const downloadReadMe = () => {
    const element = document.createElement("a");
    const file = new Blob([repoData?.readme || ""], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "README.md";
    document.body.appendChild(element);
    element.click();
  };

  return (
    <Flex
      bg={"#1E1E1E"}
      w={"100%"}
      h={"100%"}
      mah={"calc(100vh - 125px)"}
      align={"center"}
      justify={"space-evenly"}
      style={{ fontFamily: "Inter, sans-serif" }}
    >
      <Flex
        p={"lg"}
        w={"30%"}
        h={"100%"}
        style={{
          borderRight: "solid 1px #333333",
          overflow: "hidden",
          color: "#E0E0E0",
        }}
        direction={"column"}
      >
        <Flex
          style={{
            overflow: "auto",
            color: "#E0E0E0",
            borderBottom: "solid 1px #333333",
          }}
          h={"90%"}
          mah={"90%"}
          gap={"1em"}
          direction={"column"}
        >
          <Title color="#E0E0E0" order={3} fw={"300"} mb={0}>
            Project Insights & Documentation
          </Title>

          <Divider mt={0} />
          {mapDocumentationSuggestions(
            repoData?.documentationSuggestions || []
          )}
        </Flex>
        <Flex
          h={"10%"}
          w={"100%"}
          align={"center"}
          justify={"flex-end"}
          gap={"1em"}
        >
          <Button
            color="violet.8"
            variant="default"
            onClick={copyReadMeToClipboard}
            leftIcon={<IconCopy size={"1em"} />}
          >
            {showCopiedNotification ? "Copied!" : "Copy ReadMe"}
          </Button>
          <Button
            leftIcon={<IconDownload size={"1em"} />}
            color="violet.8"
            variant="default"
            onClick={downloadReadMe}
          >
            Download ReadMe
          </Button>
        </Flex>
      </Flex>
      <Flex
        p={"2em"}
        w={"70%"}
        h={"100%"}
        style={{
          overflow: "auto",
          color: "#E0E0E0",
        }}
        direction={"column"}
        gap={"1em"}
        bg={'#121212'}
      >
        <ReactMarkdown
          children={repoData?.readme || ""}
          remarkPlugins={[remarkGfm]}
        />
      </Flex>
    </Flex>
  );
}

export default AutoDocOverview;

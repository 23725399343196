import {
  Button,
  Checkbox,
  Flex,
  MultiSelect,
  Switch,
  Card,
  Group,
  Tooltip,
  Text,
  TextInput,
  Title,
  Badge,
} from "@mantine/core";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import "../App.css";
import HexLoader from "../Components/HexLoader";
import { FaCheck, FaCopy, FaTimes } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import confetti from "canvas-confetti";
import ProfileCard from "../Components/ProfileCard";

function ProfileSettings({ userData, setUserData }) {
  const navigate = useNavigate();
  const [isRegenerating, setIsRegenerating] = useState(false);

  const triggerConfetti = () => {
    confetti({
      angle: 90,
      spread: 360,
      startVelocity: 40,
      elementCount: 200,
      dragFriction: 0.12,
      duration: 3000, // duration of the confetti effect
      stagger: 0,
      width: "10px",
      height: "10px",
      colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
    });
  };

  const generalRef = useRef(null);
  const commandLineRef = useRef(null);
  const paymentRef = useRef(null);
  const cardRef = useRef(null);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const { getAccessTokenSilently } = useAuth0();

  const handleRegenerateToken = async () => {
    setIsRegenerating(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        "https://api.deeprefactor.dev/user/regenerate-token",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the global userData state with the new token
      setUserData({ ...userData, api_key: response.data.api_key });
      setIsRegenerating(false);
    } catch (error) {
      console.error("Error regenerating token:", error);
      setIsRegenerating(false);
    }
  };

  const [isCopying, setIsCopying] = useState(false);

  const handleCopyToken = async () => {
    if (userData.api_key) {
      await navigator.clipboard.writeText(userData.api_key);
      setIsCopying(true);
      setTimeout(() => {
        setIsCopying(false);
      }, 2000);
    }
  };

  const [inputCode, setInputCode] = useState("");
  const [codeMessage, setCodeMessage] = useState("")

  function handleAccessCode(inputCode) {
    console.log(inputCode);
    if (inputCode == "") {
      console.log("No code entered");
      setCodeMessage("No code entered")
      return;
    }
    if (inputCode.length != 8) {
      console.log("Invalid code", inputCode);
      setCodeMessage("Invalid code")
      return;
    }

    try {
      axios
        .post(`https://api.deeprefactor.dev/start-trial-expiration`, {
          email: userData.email,
          code: inputCode,
          userId: userData.user_id,
        })
        .then((response) => {
          console.log("Congrats! Your trial has started.");
          setCodeMessage("Congrats! Your trial has started.")
          triggerConfetti(); // Trigger confetti here
          setUserData({ ...userData, account_type: "pro", referred: true });
        })
        .catch((error) => {
          console.error(error.response.data.error || "Something went wrong.");
          setCodeMessage("Something went wrong.")
        });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Flex
      direction={"column"}
      w={"100%"}
      h={"calc(100% - 125px)"}
      bg={"#121212"}
    >
      {!userData ? (
        <Flex
          style={{ borderTop: "1px #CDCDCD solid" }}
          w={"100%"}
          h={"100%"}
          align={"center"}
          justify={"center"}
        >
          <HexLoader />
        </Flex>
      ) : (
        <Flex
          gap={"2em"}
          justify={"center"}
          align={"center"}
          w={"100%"}
          mah={"100%"}
        >
          <Flex p={"xl"} w={"30%"} h={"100%"} direction={"column"} gap={"sm"}>
            <Title color="#E0E0E0" mb={"1em"} fw={400} order={3} align="center">
              Settings
            </Title>
            <Button
              onClick={() => scrollToRef(generalRef)}
              color="dark.1"
              variant="subtle"
            >
              General
            </Button>
            <Button
              onClick={() => scrollToRef(commandLineRef)}
              color="dark.1"
              variant="subtle"
            >
              API Token
            </Button>
            <Button
              onClick={() => scrollToRef(paymentRef)}
              color="dark.1"
              variant="subtle"
            >
              Subscription
            </Button>
            {userData.referred == true && (
              <Button
                onClick={() => scrollToRef(cardRef)}
                color="dark.1"
                variant="subtle"
              >
                Early Access Card
              </Button>
            )}
          </Flex>
          <Flex
            p="xl"
            w="70%"
            mah="100%"
            mih={"100%"}
            style={{ overflow: "auto" }}
            direction="column"
            gap="2em"
          >
            {/* General Information Section */}
            <Card
              style={{ overflow: "visible" }}
              ref={generalRef}
              p="xl"
              pb={"2em"}
              radius="md"
              withBorder
            >
              <Flex style={{ overflow: "visible" }} w={"100%"} h={"100%"}>
                <Flex direction={"column"} w={"50%"} h={"100%"}>
                  <Title order={4}>General</Title>
                  <Text color="dimmed" size="sm">
                    Your account details and preferences.
                  </Text>
                  <Flex direction="column" gap="sm" mt="md">
                    <Flex align={"center"} gap={"sm"}>
                      <Text fw={800} size="md">
                        Email:{" "}
                      </Text>
                      <Text size="md">{userData.email}</Text>
                    </Flex>
                    <Flex align={"center"} gap={"sm"}>
                      <Text fw={800} size="md">
                        Repositories:{" "}
                      </Text>
                      <Text size="md">
                        {userData.repo_count}{" "}
                        {userData.account_type == "free" ? "/ 2" : ""}
                      </Text>
                    </Flex>
                    <Flex align={"center"} gap={"sm"}>
                      <Text fw={800} size="md">
                        Account Type:{" "}
                      </Text>
                      <Badge
                        variant={"gradient"}
                        gradient={{ from: "cyan.5", to: "violet.5", deg: 120 }}
                        size="md"
                      >
                        {userData.account_type}
                      </Badge>
                    </Flex>
                    {/* Add more user info fields if necessary */}
                  </Flex>
                </Flex>
              </Flex>
            </Card>

            {/* API Token Section */}
            <Card
              style={{ overflow: "visible" }}
              ref={commandLineRef}
              p="xl"
              pb={"2em"}
              radius="md"
              withBorder
              mt="md"
            >
              <Title order={4}>API Token</Title>
              <Text color="dimmed" size="sm" mt="xs">
                Use this token to authenticate with the Deep Refactor Command
                Line Interface. Manage your repositories, run analyses, and get
                feedback directly from your terminal.
              </Text>
              <TextInput
                mt={"lg"}
                color="violet.8"
                value={
                  userData.api_key
                    ? `${userData.api_key.substring(0, 4)}****`
                    : "No token generated"
                }
                disabled
              />
              <Group mt={"md"}>
                <Button
                  onClick={handleRegenerateToken}
                  leftIcon={<FiRefreshCw size={16} />}
                  color="violet.8"
                  loading={isRegenerating}
                >
                  Regenerate Token
                </Button>
                <Button
                  onClick={handleCopyToken}
                  leftIcon={<FaCopy size={16} />}
                  disabled={!userData.api_key || isCopying}
                  color="violet.8"
                >
                  {isCopying ? "Copied!" : "Copy Token"}
                </Button>
              </Group>
              <Text size="xs" mt="md">
                <a
                  href="https://deeprefactor.dev/documentation"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-violet-500 underline"
                >
                  Learn more about using the Deep Refactor Command Line
                  Interface
                </a>
              </Text>
            </Card>

            {/* Subscription Section */}
            <Card
              ref={paymentRef}
              style={{ overflow: "visible" }}
              p="xl"
              pb="2em"
              radius="md"
              withBorder
              mt="md"
              h="auto"
            >
              <Flex w={"100%"} align={"center"} justify={"space-between"}>
                <Title order={4}>Subscription</Title>
                <Badge
                  size="md"
                  gradient={{ from: "cyan.5", to: "violet.5", deg: 120 }}
                  variant="gradient"
                >
                  {userData?.account_type}
                </Badge>
              </Flex>
              <Text color="dimmed" size="sm" mt="xs">
                Manage your subscription plan and payment details
              </Text>

              <Flex direction="column" mt="xl">
                <Text size="md" mb="xs">
                  Access Code
                </Text>
                <Flex gap={"md"} align="center" justify="space-between">
                  <TextInput
                    placeholder="Enter access code"
                    style={{ width: "100%" }}
                    value={inputCode}
                    onChange={(e) => setInputCode(e.target.value)}
                  />
                  <Button
                    color="violet.8"
                    onClick={() => handleAccessCode(inputCode)}
                  >
                    Apply
                  </Button>
                </Flex>
                <Text size="xs" fw={300} mt="12px">
                  {codeMessage}
                </Text>
              </Flex>

              <div class="relative mt-8 overflow-x-auto shadow-lg sm:rounded-lg">
                <table class="w-full text-sm text-left rtl:text-right text-gray-200 dark:text-gray-400">
                  <thead class="text-xs uppercase bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-gray-100">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        Feature
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Free
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Premium
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      { name: "Repositories", free: "2", premium: "Unlimited" },
                      {
                        name: "Codebase Analysis",
                        free: "10 / month",
                        premium: "Unlimited",
                      },
                      {
                        name: "Command Line Analysis",
                        free: "15 / month",
                        premium: "200 / month",
                      },
                      {
                        name: "Architecture Analysis",
                        free: <FaTimes />,
                        premium: <FaCheck />,
                      },
                      {
                        name: "Auto Documentattion",
                        free: <FaTimes />,
                        premium: <FaCheck />,
                      },
                      {
                        name: "User Interface Analysis",
                        free: <FaTimes />,
                        premium: <FaCheck />,
                      },
                    ].map((product, index) => (
                      <tr
                        key={index}
                        className={`${
                          index % 2 === 0 ? "bg-gray-800" : "bg-gray-700"
                        } border-b border-gray-600`}
                      >
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium whitespace-nowrap"
                        >
                          {product.name}
                        </th>
                        <td className="px-6 py-4">{product.free}</td>
                        <td className="px-6 py-4">{product.premium}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>

            {/* Premium Card Section */}
            {userData.referred == true && (
              <Card
                ref={cardRef}
                style={{ overflow: "visible" }}
                p="xl"
                pb="2em"
                radius="md"
                withBorder
                mt="md"
                h="auto"
              >
                <Flex w={"100%"} align={"center"} justify={"space-between"}>
                  <Title order={4}>Your Personal Card</Title>
                </Flex>
                <Text color="dimmed" size="sm" mt="xs">
                  Thanks for taking a chance on us, this is just a small way to
                  show our appreciation
                </Text>

                <Flex
                  w={"100%"}
                  h={"100%"}
                  mt={"2em"}
                  align={"center"}
                  justify={"flex-start"}
                >
                  <ProfileCard
                    userInfo={{
                      name: userData.name,
                      email: userData.email,
                      repo_count: userData.repo_count,
                      score: userData.score,
                    }}
                  />
                </Flex>
              </Card>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default ProfileSettings;

import { Code, Flex } from "@mantine/core";
import React from "react";

function DocumentationRepo() {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden pb-[3em]">
      <header className="text-[2.5em] mt-4 text-white">
        <span className="bg-[#1a1a1a] p-2 px-6 text-gray-400 border-[#333333]">
          deep-refactor feedback &lt;project_name&gt;
        </span>
      </header>
      <text className="text-gray-400 mt-8 w-3/4">
        Retrieve detailed information about a specific project using the{" "}
        <span className="font-bold">repo</span> command. You will need to
        provide the project's name as an argument.
      </text>
      <header className="text-[1.5em] text-white mt-8">
        Required Arguments:
      </header>
      <ol className="text-gray-400 mt-4 list-decimal list-inside">
        <li className="mt-4">
          <span className="font-bold">&lt;project_name&gt;:</span> The unique
          identifier or name of your project within Deep Refactor.
        </li>
      </ol>
      <header className="text-[1.5em] text-white mt-8">Usage:</header>
      <Flex>
        <Code className="mt-4 p-2 px-4">
          deep-refactor repo &lt;project_name&gt;
        </Code>
      </Flex>
      <header className="text-[1.5em] text-white mt-8">Example:</header>
      <Flex>
        <Code className="mt-4 p-2 px-4">deep-refactor repo ProjectX</Code>
      </Flex>
      <header className="text-[1.5em] mt-6 text-white">Output Example:</header>
      <div className="mt-4 p-2 px-4 bg-gray-900 border border-[#333333] rounded-sm">
        <text className="text-gray-400">
          Project Name: ProjectA <br/> Languages: JavaScript, HTML, CSS <br/> Creation Date:
          2023-01-01T12:00:00Z <br/> Last Modified: 2023-01-15T16:30:00Z <br/> <br/> Code Quality:
          45 <br/> Performance Analysis: 3 <br/><br/>File: src/app.js <br/>- Low Issues: 2 <br/>- Medium
          Issues: 1 <br/><br/>File: src/components/Navbar.jsx <br/>- Low Issues: 3 <br/>- High
          Issues: 1
        </text>
      </div>
      <text className="text-violet-700 mt-8 w-3/4">
        Replace &lt;project_name&gt; with the actual name of your project to get
        the required information.
      </text>
    </div>
  );
}

export default DocumentationRepo;

import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Image,
  Menu,
  Text,
} from "@mantine/core";
import React from "react";
import DeepRefactorLogo from "../Images/DeepRefactorLogo.png";
import { useAuth0 } from "@auth0/auth0-react";
import { IconAnalyze, IconDatabase } from "@tabler/icons-react";

function Header({ activeTab, setActiveTab, showButton, userData }) {
  const { logout, user } = useAuth0();
  // console.log('Header User', user)
  const getInitials = (name) => {
    if (!name) return "";

    const names = name.split(" ");
    if (names.length < 2) return names[0][0];

    return names[0][0] + names[1][0];
  };

  const userInitials = getInitials(user?.nickname);

  return (
    <Flex
      bg={"#1D1D1D"}
      w={"100%"}
      direction={"column"}
      align={"center"}
      justify={"center"}
    >
      <Flex
        p={"md"}
        w={"100%"}
        h={60}
        direction={"row"}
        align={"space-between"}
        justify={"center"}
      >
        <Flex
          w={"75%"}
          h={"100%"}
          direction={"row"}
          align={"center"}
          justify={"flex-start"}
        >
          <Image maw={225} radius="md" src={DeepRefactorLogo} />
        </Flex>
        <Flex
          w={"25%"}
          h={"100%"}
          direction={"row"}
          align={"center"}
          justify={"flex-end"}
          gap={"sm"}
        >
          <Menu withArrow shadow="sm" width={250}>
            <Menu.Target>
              <ActionIcon
                size={"2.75em"}
                radius={"100%"}
                className="bg-gradient-to-tr from-blue-800 to-indigo-900"
              >
                {userInitials.toUpperCase()}
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown bg={"#1D1D1D"}>
              <Menu.Label>
                <Flex direction={"column"}>
                  <Flex
                    align={"center"}
                    justify={"space-between"}
                    pt={5}
                    pb={5}
                    gap={'1em'}
                  >
                    <Badge
                      variant={"gradient"}
                      gradient={{ from: "cyan.5", to: "violet.5", deg: 120 }}
                      miw={60}
                    >
                      {userData?.account_type}
                    </Badge>
                    <Text truncate={'true'} align={"end"}>{user?.email}</Text>
                  </Flex>
                  {userData?.account_type == "free" && (
                    <Flex
                      gap={"sm"}
                      mt={"md"}
                      align={"center"}
                      justify={"flex-start"}
                      pt={5}
                      pb={5}
                    >
                      <IconDatabase size={"1.2em"} />
                      <Text align={"end"}>
                        {userData?.repo_count} / 2 Active Repos
                      </Text>
                    </Flex>
                  )}
                  {userData?.account_type == "free" && (
                    <Flex
                      gap={"sm"}
                      align={"center"}
                      justify={"flex-start"}
                      pt={5}
                      pb={5}
                    >
                      <IconAnalyze size={"1.2em"} />
                      <Text align={"end"}>
                        {10 - userData?.analysis_count} analysis left
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Menu.Label>
              <Menu.Divider style={{color: "#333333"}} bg={'#333333'} />
              <Menu.Item>
                <Button
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                  style={{ color: "#E0E0E0" }}
                  radius={0}
                  color={"grey.1"}
                  variant={"unstyled"}
                >
                  Logout
                </Button>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Flex>
      {showButton && (
        <Flex
          p={"md"}
          w={"100%"}
          h={65}
          style={{ borderBottom: "1px #333333 solid" }}
          direction={"row"}
          align={"space-between"}
          justify={"center"}
        >
          <Flex
            w={"80%"}
            h={"100%"}
            direction={"row"}
            align={"center"}
            justify={"flex-start"}
            gap={"sm"}
          >
            <Button
              style={{
                color: "#E0E0E0",
                borderBottom:
                  activeTab === "overview" ? "solid 2px #E0E0E0" : undefined,
              }}
              radius={0}
              size={"md"}
              color={"grey.1"}
              variant={"unstyled"}
              onClick={() => setActiveTab("overview")}
            >
              Overview
            </Button>
            <Button
              size={"md"}
              radius={0}
              color={"grey.1"}
              variant="unstylzed"
              style={{
                color: "#E0E0E0",
                borderBottom:
                  activeTab === "integrations"
                    ? "solid 2px #E0E0E0"
                    : undefined,
              }}
              onClick={() => setActiveTab("integrations")}
            >
              Integrations
            </Button>
          </Flex>
          <Flex
            w={"20%"}
            h={"100%"}
            direction={"row"}
            align={"center"}
            justify={"flex-end"}
            gap={"md"}
          >
            <Button
               size={"md"}
              radius={0}
              color={"grey.1"}
              variant="unstylzed"
              style={{
                color: "#E0E0E0",
                borderBottom:
                  activeTab === "settings"
                    ? "solid 2px #E0E0E0"
                    : undefined,
              }}
              onClick={() => setActiveTab("settings")}
            >
              Settings
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default Header;

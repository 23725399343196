import React from "react";

function DocumentationLocalAnalysis() {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden pb-[6em]">
      <header className="text-[2.5em] mt-4 text-white">
        Local Analysis Workflow
      </header>
      <p className="text-gray-400 mt-8 w-3/4">
        Local Analysis provides a quick, offline approach to inspect and improve your code with Deep Refactor's AI-powered analysis.
      </p>
      <header className="text-[1.5em] text-white mt-8">
        Step by Step Workflow:
      </header>
      <ol className="text-gray-400 mt-4 list-decimal list-inside">
        <li className="mt-4">
          Open a terminal within your project's directory and prepare to run an analysis on your desired files.
        </li>
        <li className="mt-4">
          Execute the local analysis by specifying the file path and analysis types you wish to run.
        </li>
        <li className="mt-4">
          Review the feedback provided in the terminal, and apply the suggestions directly in your local development environment.
        </li>
        <li className="mt-4">
          Iterate on your code, re-running the analysis as needed to ensure all new changes maintain or improve code quality.
        </li>
        <li className="mt-4">
          Once satisfied with updates, use the 'clear-feedback' command to reset the local feedback data.
        </li>
      </ol>
      <p className="text-violet-700 mt-8 w-3/4">
        This workflow enables developers to maintain and enhance code quality independently of network constraints or platform availability.
      </p>
    </div>
  );
}

export default DocumentationLocalAnalysis;

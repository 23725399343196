import React from "react";
import { Code, Flex } from "@mantine/core";

function DocumentationLocalFeedback() {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden pb-[6em]">
      <header className="text-[2.5em] mt-4 text-white">
        <span className="bg-[#1a1a1a] p-2 px-6 text-gray-400 border-[#333333]">
          deep-refactor local-feedback &lt;file_path&gt;
        </span>
      </header>
      <text className="text-gray-400 mt-8 w-3/4">
        Retrieve locally stored feedback for a specified file. This feedback is
        not stored on our platform and can be used for files outside of our
        platform as well.
      </text>
      <header className="text-[1.5em] text-white mt-8">Usage:</header>
      <Flex>
        <Code className="mt-4 p-2 px-4">
          deep-refactor local-feedback &lt;file_path&gt;
        </Code>
      </Flex>
      <header className="text-[1.5em] mt-6 text-white">Note:</header>
      <text className="text-gray-400 mt-4 w-3/4">
        Local feedback must be cleared manually. To delete local feedback, use
        the <span className="font-bold">clear-feedback</span> command.
      </text>
      <header className="text-[1.5em] text-white mt-8">Output Example:</header>
      <div className="mt-4 p-2 px-4 bg-gray-900 border border-[#333333] rounded-sm">
        <text className="text-gray-400">
          Type: Documentation Quality<br />
          Severity: Medium <br />
          Comment: The code is missing docstrings for modules, functions, and classes.{" "}
          <br />
          Recommendation: Add meaningful docstrings to modules, functions, and classes to provide proper documentation and improve maintainability<br />
          Line(s): 1 - 12 <br />
        </text>
      </div>
    </div>
  );
}

export default DocumentationLocalFeedback;

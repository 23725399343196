import "../App.css";

import CodeFork from "../Images/CodeFork.png";
import CurlyBrackets from "../Images/CurlyBrackets.png";
import Documents from "../Images/Documents.png";
import MultilineText from "../Images/MultilineText.png";
import Puzzle from "../Images/Puzzle.png";
import Python from "../Images/PythonIcon.png";
import Search from "../Images/Search.png";
import { useState, useEffect } from "react";

function VideoPlayer() {
  const codeLines = [
    // Import statement
    [
      { text: "from", color: "text-[#F19452]" },
      { text: "flask", color: "text-[#F19452]" },
      { text: "import", color: "text-[#F19452]" },
      { text: "Flask,", color: "text-[#CAAAF9]" },
      { text: "request,", color: "text-[#CAAAF9]" },
      { text: "jsonify", color: "text-[#CAAAF9]" },
    ],
    [],
    // Flask app instantiation
    [
      { text: "app", color: "text-[#F2B464]" },
      { text: "=", color: "text-white" },
      { text: "Flask(", color: "text-[#CAAAF9]" },
      { text: "__name__", color: "text-[#CAAAF9]" },
      { text: ")", color: "text-[#CAAAF9]" },
    ],
    // Empty line
    [],
    // Decorator for get_projects
    [
      { text: "@app.route", color: "text-[#F2B464]" },
      { text: "('/projects',", color: "text-yellow-500" },
      { text: "methods=", color: "text-[#F19452]" },
      { text: "['GET'])", color: "text-yellow-500" },
    ],
    // get_projects function definition
    [
      { text: "def", color: "text-[#F19452]" },
      { text: "get_projects():", color: "text-[#F2B464]" },
    ],
    // projects variable
    [
      { text: "projects", color: "text-[#F2B464] ml-4" },
      { text: "=", color: "text-white" },
      { text: "Project.query.all()", color: "text-yellow-500" },
    ],
    // project_list comprehension
    [
      { text: "project_list", color: "text-[#F2B464] ml-4" },
      { text: "=", color: "text-white" },
      { text: "[{'id':", color: "text-[#F2B464]" },
      { text: "project.id,", color: "text-[#F2B464]" },
      { text: "'name':", color: "text-[#F2B464]" },
      { text: "project.name,", color: "text-[#F2B464]" },
      { text: "'description':", color: "text-[#F2B464]" },
      { text: "project.description}", color: "text-[#F2B464]" },
      { text: "for", color: "text-[#F19452]" },
      { text: "project", color: "text-[#F2B464]" },
      { text: "in", color: "text-[#F19452]" },
      { text: "projects]", color: "text-[#F2B464]" },
    ],
    // jsonify return statement
    [
      { text: "return", color: "text-[#F19452] ml-4" },
      { text: "jsonify(project_list)", color: "text-yellow-500" },
    ],
    // Empty line
    [],
    // Decorator for get_project
    [
      { text: "@app.route", color: "text-[#F2B464]" },
      { text: "('/projects/", color: "text-yellow-500" },
      { text: "<int:project_id>',", color: "text-yellow-500" },
      { text: "methods=", color: "text-[#F19452]" },
      { text: "['GET'])", color: "text-yellow-500" },
    ],
    // get_project function definition
    [
      { text: "def", color: "text-[#F19452]" },
      { text: "get_project(project_id):", color: "text-[#F2B464]" },
    ],
    // project variable inside get_project
    [
      { text: "project", color: "text-[#F2B464] ml-4" },
      { text: "=", color: "text-white" },
      { text: "Project.query.get(project_id)", color: "text-yellow-500" },
    ],
    // if statement inside get_project
    [
      { text: "if", color: "text-[#F19452] ml-4" },
      { text: "project:", color: "text-white" },
    ],
    // project_info dictionary
    [
      { text: "project_info", color: "text-[#F2B464] ml-8" },
      { text: "=", color: "text-white" },
      { text: "{'id':", color: "text-[#F2B464]" },
      { text: "project.id,", color: "text-[#F2B464]" },
      { text: "'name':", color: "text-[#F2B464]" },
      { text: "project.name,", color: "text-[#F2B464]" },
      { text: "'description':", color: "text-[#F2B464]" },
      { text: "project.description}", color: "text-[#F2B464]" },
    ],
    // jsonify return statement inside get_project
    [
      { text: "return", color: "text-[#F19452] ml-8" },
      { text: "jsonify(project_info)", color: "text-yellow-500" },
    ],
    // else statement inside get_project
    [{ text: "else:", color: "text-[#F19452] ml-4" }],
    // jsonify error return inside get_project
    [
      { text: "return", color: "text-[#F19452] ml-8" },
      { text: "jsonify({'error':", color: "text-[#F2B464]" },
      { text: "'Project not found'}),", color: "text-[#F2B464]" },
      { text: "404", color: "text-[#CAAAF9]" },
    ],
    // Empty line
    [],
    // Decorator for create_project
    [
      { text: "@app.route", color: "text-[#F2B464]" },
      { text: "('/projects',", color: "text-yellow-500" },
      { text: "methods=", color: "text-[#F19452]" },
      { text: "['POST'])", color: "text-yellow-500" },
    ],
    // create_project function definition
    [
      { text: "def", color: "text-[#F19452]" },
      { text: "create_project():", color: "text-[#F2B464]" },
    ],
    // data variable inside create_project
    [
      { text: "data", color: "text-[#F2B464] ml-4" },
      { text: "=", color: "text-white" },
      { text: "request.json", color: "text-yellow-500" },
    ],
    [],
    // new_project instantiation inside create_project
    [
      { text: "new_project", color: "text-[#F2B464] ml-4" },
      { text: "=", color: "text-white" },
      { text: "Project(name=data['name'],", color: "text-yellow-500" },
      {
        text: "description=data.get('description'))",
        color: "text-yellow-500",
      },
    ],
    // db.session.add call inside create_project
    [{ text: "db.session.add(new_project)", color: "text-yellow-500 ml-4" }],
    // db.session.commit call inside create_project
    [{ text: "db.session.commit()", color: "text-yellow-500 ml-4" }],
    // jsonify return statement inside create_project
    [
      { text: "return", color: "text-[#F19452] ml-4" },
      { text: "jsonify({'message':", color: "text-[#F2B464]" },
      { text: "'Project created successfully'}),", color: "text-[#F2B464]" },
      { text: "201", color: "text-[#CAAAF9]" },
    ],
    [],
    [],
  ];

  const data = [
    {
      type: "Code Quality",
      border: "#E53935",
      severity: "Medium",
      message: "List comprehension is too complex and reduces readability",
      recommendation:
        "Refactor the list comprehension into a for loop with a separate list initialization and append statements",
      line: 8,
    },
    {
      type: "Performance Analysis",
      border: "#00ACB9",
      severity: "Medium",
      message: "Inefficient JSON serialization",
      recommendation:
        "Use a more efficient JSON serialization method like ujson or simplejson instead of the default Flask jsonify()",
      line: 18,
    },
    {
      type: "Security Analysis",
      border: "#8E24AA",
      severity: "Critical",
      message: "Missing Input Validation",
      recommendation:
        "Validate the input data to ensure it contains the required ‘name’ field before creating a new project",
      line: 22,
    },
  ];

  function renderCodeLine(line, index) {
    let highlightClass = "";
    if (index + 1 === 8) {
      highlightClass = highlightLines.includes(index + 1)
        ? "line-highlight bg-[#511E1C] pr-3"
        : "";
    } else if (index + 1 === 18) {
      highlightClass = highlightLines.includes(index + 1)
        ? "line-highlight bg-[#0D4044] pr-3"
        : "";
    } else {
      highlightClass = highlightLines.includes(index + 1)
        ? "line-highlight bg-[#371740] pr-3"
        : "";
    }

    return (
      <div key={index} className="flex items-center">
        <div className="text-[#333333] w-[30px] text-right select-none pr-3 pb-[1px] pt-[1px] border-r text-[10px] border-[#333333]">
          {index + 1}
        </div>
        <div
          className={`ml-4 ${highlightClass} overflow-hidden whitespace-nowrap text-overflow-ellipsis`}
        >
          {line.map((part, partIndex) => (
            <span key={partIndex} className={part.color + " text-[10px]"}>
              {part.text}
              {partIndex < line.length - 1 ? " " : ""}{" "}
            </span>
          ))}
        </div>
      </div>
    );
  }

  const [highlightLines, setHighlightLines] = useState([]);
  const [containerWidth, setContainerWidth] = useState("w-[65%]");
  const [showCards, setShowCards] = useState(false);

  useEffect(() => {
    // Trigger the animation after 1 second
    const timeoutId = setTimeout(() => {
      setContainerWidth("w-[86.5%]");
    }, 1000);

    // Trigger the cards to show after the container has finished expanding
    const cardsTimeoutId = setTimeout(() => {
      setShowCards(true);
    }, 3000); // 1 second delay + 2 seconds animation

    const highlightTimeout = setTimeout(() => {
      // This will trigger the CSS transition
      setHighlightLines([8, 18, 22]);
    }, 4000); // Start after the width expansion

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(highlightTimeout);
      clearTimeout(cardsTimeoutId);
    };
  }, []);

  return (
    <div className="w-full min-h-[600px] flex items-center justify-center">
      <div
        className={`h-[600px] shadow-md bg-[#121212] flex rounded-md border border-[#333333] transition-width ${containerWidth}`}
      >
        <div className="w-[65px] bg-[#333333]">
          <div className="text-white flex flex-col pt-[40px] items-center w-full h-full border-r border-[#1A1A1A]">
            <img src={Documents} className="h-[30px] w-[30px] mb-4" />
            <img src={Search} className="h-[30px] w-[30px] mb-4" />
            <img src={CodeFork} className="h-[30px] w-[30px] mb-4" />
            <img src={Puzzle} className="h-[30px] w-[30px] mb-4" />
          </div>
        </div>

        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <div className="flex pl-[30px] mt-1 pb-2 border-b border-[#1a1a1a]">
              <div className="px-4 text-[12px] border-b border-[#7521BC] py-2 text-white flex items-center">
                <img
                  src={Python}
                  alt="Python File"
                  className="h-[10px] w-[10px] mr-2"
                  style={{ objectFit: "contain" }}
                />
                Main.py
              </div>
              <div className="px-4 text-[12px] ml-[20px] border-b border-[#333333] py-1 text-white flex items-center">
                <img
                  src={MultilineText}
                  alt="Text File"
                  className="h-[10px] w-[10px] mr-2"
                />
                requirements.txt
              </div>
              <div className="px-4 text-[12px] ml-[20px] border-b border-[#333333] py-1 text-white flex items-center">
                <img
                  src={CurlyBrackets}
                  alt="JSON File"
                  className="h-[10px] w-[10px] mr-2"
                />
                Data.json
              </div>
            </div>
          </div>

          <div className="flex justify-between pl-[15px] text-sm relative bg-[#121212] p-4 overflow-auto">
            <div className="font-mono text-sm  text-white">
              {codeLines.map(renderCodeLine)}
            </div>
            <div
              className={`transition-all duration-500 ${
                showCards ? "w-[350px]" : "w-0"
              }`}
            >
              {/* Conditional rendering of cards with staggered animation */}
              {showCards && (
                <>
                  {data.map((item, index) => (
                    <CardComponent
                      key={index}
                      className="animate-fadeIn"
                      style={{ animationDelay: `${0.5 * (index + 1)}s` }}
                      data={item}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const CardComponent = ({ className, style, data }) => {
  return (
    <div
      className={`bg-[#040C19] w-[350px] border flex border-[#333333] rounded-md overflow-hidden mb-4 opacity-0 ${className}`}
      style={style}
    >
      <div
        className={`w-[10px] min-h-full`}
        style={{ background: `${data.border}` }}
      />
      <div className="flex flex-col p-4 w-full min-h-full text-white">
        <div className="flex items-center justify-between">
          <text>{data.type}</text>
          <text
            className={
              data.severity == "Critical" ? "text-red-800" : "text-orange-400"
            }
          >
            {data.severity}
          </text>
        </div>
        <text className="mt-3 text-[17px] font-semibold">{data.message}</text>
        <text className="mt-2 font-light">{data.recommendation}</text>
        <text className="mt-4 text-sm font-thin">Line: {data.line}</text>
      </div>
    </div>
  );
};

export default VideoPlayer;

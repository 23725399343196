import React, { useState, useEffect } from "react";
import { Collapse, Flex, Text, useMantineTheme } from "@mantine/core";
import { AiOutlineFile, AiOutlineFolder } from "react-icons/ai";

function FileTreeAnimated({ tree, level = 0, hoveredRecommendation }) {
  const [openKeys, setOpenKeys] = useState({});
  const theme = useMantineTheme();

  const isFile = (key) => {
    return tree[key] && Object.keys(tree[key]).length === 0;
  };

  const handleToggle = (key) => {
    setOpenKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const isPartOfHoveredPath = (key) => {
    if (!hoveredRecommendation) return false;
    const recommendedPathParts =
      hoveredRecommendation.recommended_path.split("/");
    return recommendedPathParts.includes(key);
  };

  useEffect(() => {
    const newOpenKeys = {};

    if (hoveredRecommendation) {
      const parts = hoveredRecommendation.recommended_path.split("/");
      let currentLevel = tree;
      parts.forEach((part, index) => {
        if (currentLevel[part] && !isFile(part)) {
          newOpenKeys[part] = true;
          currentLevel = currentLevel[part];
        }
      });
    }

    setOpenKeys(newOpenKeys);
  }, [hoveredRecommendation, tree]);

  return (
    <div style={{ marginLeft: level * 20 }}>
      {Object.keys(tree).map((key) => {
        return (
          <Flex direction="column" mt="sm" key={key}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: isFile(key) ? "default" : "pointer",
              }}
              onClick={() => !isFile(key) && handleToggle(key)}
            >
              {isFile(key) ? (
                <AiOutlineFile
                  color={isPartOfHoveredPath(key) ? "#7521BC" : "#A0A0A0"}
                  size="1.25em"
                />
              ) : (
                <AiOutlineFolder
                  color={isPartOfHoveredPath(key) ? "#7521BC" : "#A0A0A0"}
                  size="1.25em"
                />
              )}
              <Text
                ml={"10px"}
                color={isPartOfHoveredPath(key) ? "violet.8" : "#A0A0A0"}
                size="sm"
              >
                {key}
              </Text>
            </div>
            {!isFile(key) && openKeys[key] && (
              <Collapse in={openKeys[key]}>
                <FileTreeAnimated
                  tree={tree[key]}
                  level={level + 1}
                  hoveredRecommendation={hoveredRecommendation}
                />
              </Collapse>
            )}
          </Flex>
        );
      })}
    </div>
  );
}

export default FileTreeAnimated;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../App.css";
import WelcomeBackground from "../Images/WelcomeBackground.png";
import ProfileCard from "../Components/ProfileCard";
import { FaArrowRight } from "react-icons/fa";
import { css } from "@emotion/css";
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { useAuth0 } from "@auth0/auth0-react";

gsap.registerPlugin(TextPlugin);

function Invite() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const isMobile = window.innerWidth < 768;

  const [userInfo, setUserInfo] = useState(null);

  const titles = [
    "Innovation finds its form.",
    "Clarity empowers your code.",
    "Boundaries are redefined.",
  ];

  // Inside your component
  const [titleIndex, setTitleIndex] = useState(0);
  const [fadeStatus, setFadeStatus] = useState("title-fade-enter-active");

  useEffect(() => {
    const changeTitle = () => {
      setFadeStatus("title-fade-exit");
      setTimeout(() => {
        setTitleIndex(
          (currentTitleIndex) => (currentTitleIndex + 1) % titles.length
        );
        setFadeStatus("title-fade-enter");
      }, 1000); // corresponds to the exit transition duration
      setTimeout(() => {
        setFadeStatus("title-fade-enter-active");
      }, 2000); // corresponds to the enter transition duration
    };

    const intervalId = setInterval(changeTitle, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const titleRef = useRef(null);
  const introRef = useRef(null);
  const codeSectionRef = useRef(null);
  const inviteSectionRef = useRef(null);
  const cardSectionRef = useRef(null);

  useEffect(() => {
    const fetchInfo = async (id) => {
      try {
        const response = await axios.get(
          `https://api.deeprefactor.dev/user/trial/${id}`
        );
        console.log(JSON.stringify(response.data, null, 2));
        setUserInfo(response.data);
      } catch (error) {
        console.error(error);
        navigate("/");
      }
    };

    fetchInfo(id);
  }, [id]);

  useEffect(() => {
    // GSAP timeline for fading content
    const tl = gsap.timeline({ defaults: { duration: 1.25, ease: "power2.out" } });

    // Sequentially fading in the content
    tl.from(titleRef.current, { y: 30, autoAlpha: 0 })
      .from(introRef.current, { y: 30, autoAlpha: 0 }, "-=0.8")
      .from(codeSectionRef.current, { y: 30, autoAlpha: 0 }, "-=0.8")
      .from(inviteSectionRef.current, { y: 30, autoAlpha: 0 }, "-=0.8")
      .from(cardSectionRef.current, { y: 30, autoAlpha: 0 }, "-=0.8");
  }, []);

  const [inviteEmail, setInviteEmail] = useState("");
  const [popupMessage, setPopupMessage] = useState("");

  const handleInvite = async () => {
    try {
      const response = await axios.post(`https://api.deeprefactor.dev/user/invite`, {
        email: inviteEmail,
      });
      console.log(JSON.stringify(response.data, null, 2));
      setPopupMessage("Congrats! Invitation sent successfully.");
      setTimeout(() => {
        setPopupMessage("");
      }, 3000); // Wait for 3 seconds
    } catch (error) {
      setPopupMessage("Failed to send invitation. Try again.");
      setTimeout(() => {
        setPopupMessage("");
      }, 3000); // Wait for 3 seconds
    }
  };

  return (
    <div
      className="relative bg-cover overflow-scroll bg-center backdrop-blur-md backdrop-brightness-0 w-full h-screen flex items-center p-8"
      style={{ backgroundImage: `url(${WelcomeBackground})`, backgroundSize: 'cover', flexDirection: isMobile ? "column" : "row" }}
    >
      <div className="relative z-20 w-full max-w-[50%] flex flex-col rounded-lg p-8 text-white" style={{minWidth: isMobile ? "100%" : "50%"}}>
        {/* Introduction Section */}
        <div className="mb-12">
          <h1 ref={titleRef} className="text-4xl font-extrabold mb-4">
            Discover a platform where{" "}
            <span className={`text-indigo-500 inline-block`}>
              {titles[titleIndex]}
            </span>
          </h1>

          <div ref={introRef}>
            <p className="text-sm text-light text-gray-500 mt-8">
              It's not just about coding. It's about crafting with purpose, and
              someone noticed that spark in you. That's why you're here —
              because when talent meets the right tools, the possibilities are
              boundless.
            </p>
            <p className="text-sm text-light text-gray-500 mt-6">
              <b className="text-bold text-indigo-500">Deep Refactor</b> isn't
              just a service; it's a partnership. We provide the environment
              that lets you focus on what you do best: building the future, one
              line of code at a time.
            </p>
          </div>
        </div>

        {/* Access Code Section */}
        <div
          ref={codeSectionRef}
          className="pl-6 ml-6 mb-12 border-l-2 border-indigo-500"
        >
          <h2 className="text-xl text-indigo-500 font-semibold mb-2">
            Your Exclusive Code
          </h2>
          <p className="text-3xl font-bold text-white mb-4">{userInfo?.code}</p>
          <p className="text-xs text-gray-500">
            We believe in you and your potential. <br /> That's why we're giving
            you 2 weeks of premium access. Redeem in settings after{" "}
            <a
              onClick={() => {
                loginWithRedirect({
                  screen_hint: "signup",
                });
              }}
              className="hover:text-indigo-500 text-indigo-400 transition-all cursor-pointer"
            >
              signing up.
            </a>{" "}
            <br /> Need longer?{" "}
            <a className="hover:text-indigo-500 transition-all cursor-pointer" href="mailto:contact@deeprefactor.dev">
              Just ask us.
            </a>
          </p>
        </div>

        {/* Invitation Extension Section */}
        <div ref={inviteSectionRef}>
          <h3 className="text-md font-normal text-gray-400 mb-2">
            Spread the Word
          </h3>
          <div className="flex items-center gap-2">
            <input
              type="text"
              placeholder="Enter their email address"
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value)}
              className="flex-grow h-10 pl-4 rounded-lg bg-transparent border border-gray-400 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
            <button
              onClick={handleInvite}
              className="flex-shrink-0 flex items-center justify-center w-10 h-10 rounded-lg bg-indigo-500 hover:bg-indigo-600 transition-colors"
            >
              <FaArrowRight color="white" />
            </button>
          </div>
          <p className="text-xs text-gray-500 mt-2">{popupMessage}</p>
        </div>
      </div>

      <div
        ref={cardSectionRef}
        className="relative z-20 w-full max-w-[50%] h-full flex items-center justify-center flex-col rounded-lg p-8"
      >
        <p className="text-md w-[325px] text-end font-light mb-2 text-gray-600">
          Your personalized card
        </p>
        {userInfo && <ProfileCard userInfo={userInfo} />}
        <p className="text-md w-[325px] text-start font-light mt-2 text-white md:text-gray-600">
          Added to your profile upon code redemption. <br /> It will track your
          development progress.
        </p>
      </div>

      <div className="grain"></div>
      <div className="absolute inset-0 bg-black opacity-50"></div>
    </div>
  );
}

export default Invite;

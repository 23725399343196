import "./App.css";
import { Flex, Title } from "@mantine/core";
import Dashboard from "./Views/Dashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NewRepo from "./Views/NewRepo";
import Analytics from "./Views/Analytics";
import Landing from "./Views/Landing";
import { useAuth0 } from "@auth0/auth0-react";
import ProfileSettings from "./Views/ProfileSettings";
import DocumentationPage from "./Views/DocumentationPage";
import Invite from "./Views/Invite";

function App() {
  
  return (
    <Router>
      <Flex
        w={"100vw"}
        h={"100vh"}
        bg={"black"}
        align={"center"}
        justify={"center"}
      >
        <Routes>
          <Route path={"/"} element={<Landing />} />
          <Route path={"/dashboard"} element={<Dashboard />} />
          <Route path={"/repository/:id"} element={<NewRepo />} />
          <Route path={"/analytics/:id"} element={<Analytics />} />
          <Route path={"/settings"} element={<ProfileSettings />} />
          <Route path={"/documentation"} element={<DocumentationPage />} />
          <Route path={"/welcome/:id"} element={<Invite />} />
        </Routes>
      </Flex>
    </Router>
  );
}

export default App;

import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Divider,
  Flex,
  Text,
  Tooltip,
} from "@mantine/core";
import React from "react";
import GitHubLogo from "../Images/GithubLogo.svg";
import { FiExternalLink } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

function Integrations({ repos, languages, analyzedRepos, userData }) {
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width: 50em)");

  const uniqueOwners = Array.from(
    new Set(repos.map((repo) => repo.owner.login))
  );

  const ownerDetails = uniqueOwners.map((owner) => {
    const ownerRepo = repos.find((repo) => repo.owner.login === owner);
    return {
      login: owner,
      avatar_url: ownerRepo.owner.avatar_url,
    };
  });

  const navigateGitHub = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Flex
      direction={"column"}
      bg={"#121212"}
      w={"100%"}
      h={"100%"}
      align={"center"}
    >
      <Flex
        w={"90%"}
        h={"100%"}
        gap={"md"}
        p={"xl"}
        pl={isMobile ? "0.5em" : "3em"}
        pr={isMobile ? "0.5em" : "3em"}
        mb={"2em"}
        justify={"space-between"}
      >
        <Flex
          w={isMobile ? "100%" : "62.5%"}
          h={"100%"}
          direction={"column"}
          gap={"xs"}
        >
          <Text color="#E0E0E0" fw={600}>
            Repositories
          </Text>
          <Flex
            w={"100%"}
            h={"100%"}
            mah={"68vh"}
            bg={"#1E1E1E"}
            style={{
              borderRadius: "5px",
              border: "1px solid rgba(0,0,0,0.15)",
              overflow: "auto",
            }}
            direction={"column"}
            p={"md"}
          >
            {repos.map((repo, index) => {
              const isAnalyzed = analyzedRepos.some(
                (analyzedRepo) => parseInt(analyzedRepo.repo_id) === repo.id
              );

              return (
                <Flex
                  key={index}
                  direction={isMobile ? "column" : "row"}
                  align={isMobile ? "flex-start" : "center"}
                  justify={"space-between"}
                  p={"md"}
                  style={{ borderBottom: "1px solid #373737" }}
                  w={"100%"}
                  gap={isMobile ? "1em" : 0}
                >
                  <Flex
                    pb={"sm"}
                    pt={"sm"}
                    w={isMobile ? "100%" : "80%"}
                    align={"center"}
                    gap={"md"}
                  >
                    <Avatar
                    radius={"50%"}
                      style={{
                        border: "solid 1px #CDCDCD",
                        filter: "invert(1)",
                      }}
                      size={"3em"}
                      src={GitHubLogo}
                    />
                    <Flex direction={"column"} w={"100%"}>
                      <Text color="#E0E0E0" fw={500}>
                        {repo.name}
                      </Text>
                      <Flex mt={"xs"} gap={"sm"}>
                        {repo?.language ? (
                          <Badge
                            variant={"outline"}
                            color={"violet.8"}
                            key={repo?.language}
                          >
                            {repo?.language}
                          </Badge>
                        ) : (
                          <Text color="#E0E0E0" italic fz={"sm"}>
                            No language data available
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex align={"center"} gap={"sm"}>
                    <ActionIcon
                      size={"md"}
                      onClick={() => navigateGitHub(repo.html_url)}
                    >
                      <FiExternalLink />
                    </ActionIcon>

                    {isAnalyzed ? (
                      <Tooltip label={"Already analyzed"}>
                        <span>
                          <Button
                            p={"xs"}
                            variant={"light"}
                            size={"sm"}
                            color={"violet.8"}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/repository/${repo.id}`);
                            }}
                            disabled={isAnalyzed}
                          >
                            Import
                          </Button>
                        </span>
                      </Tooltip>
                    ) : userData?.account_type === "free" &&
                      userData?.repo_count >= 2 ? (
                      <Tooltip
                        label={
                          "You've reached the maximum number of active repositories."
                        }
                      >
                        <span>
                          <Button
                            p={"xs"}
                            variant={"light"}
                            size={"sm"}
                            color={"violet.8"}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/repository/${repo.id}`);
                            }}
                            disabled={true}
                          >
                            Import
                          </Button>
                        </span>
                      </Tooltip>
                    ) : (
                      <Button
                        p={"xs"}
                        variant={"light"}
                        size={"sm"}
                        color={"violet.8"}
                        onClick={() => {
                          navigate(`/repository/${repo.id}`);
                        }}
                      >
                        Import
                      </Button>
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        {!isMobile && (
          <Flex w={"35%"} h={"80%"} direction={"column"} gap={"xs"}>
            <Text color="#E0E0E0" fw={600}>
              Linked Accounts
            </Text>
            <Flex
              direction={"column"}
              justify={"space-between"}
              p={"lg"}
              w={"100%"}
              h={"100%"}
              bg={"#1E1E1E"}
              style={{
                borderRadius: "5px",
                border: "1px solid #373737",
              }}
            >
              <Flex w={"100%"} h={"75%"} direction={"column"} gap={"sm"}>
                {ownerDetails.map((owner, index) => (
                  <Flex
                    key={index}
                    style={{
                      borderRadius: "5px",
                      border: "1px solid #373737",
                    }}
                    w={"100%"}
                    p={"md"}
                    h={65}
                    align={"center"}
                  >
                    <Avatar
                      src={owner.avatar_url}
                      size={"2.5em"}
                      radius={"50%"}
                      style={{border: 'solid 1px #373737'}}
                    />
                    <Flex ml={"md"} direction={"column"}>
                      <Text color="#E0E0E0" fw={500}>
                        {owner.login}
                      </Text>
                      <Text color="#E0E0E0" fz={"sm"} fw={300}>
                        GitHub
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
              <Divider mt={"md"} mb={"md"} />
              {/* <Flex w={"100%"} h={"25%"} direction={"column"}>
              <Text fw={500}>Add Account</Text>
              <Flex align={"center"} gap={"md"} w={"100%"} h={"100%"}>
                <ActionIcon size={"3em"} p={"sm"}>
                  <Image src={GitHubLogo} maw={"100%"} />
                </ActionIcon>

                <ActionIcon disabled size={"3em"} p={"sm"}>
                  <Image src={BitBucketLogo} maw={"100%"} />
                </ActionIcon>
                <ActionIcon disabled size={"3em"} p={"sm"}>
                  <Image src={GitLabLogo} maw={"100%"} />
                </ActionIcon>
              </Flex>
            </Flex> */}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default Integrations;

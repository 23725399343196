import { Code, Flex } from "@mantine/core";
import React from "react";

function DocumentationLogin() {
  return (
    <div className="w-full h-full flex flex-col overflow-y-auto overflow-x-hidden pb-[6em]">
      <header className="text-[2.5em] mt-4 text-white overflow-hidden">
        <span className="bg-[#1a1a1a] p-4 px-6 text-gray-400 border-[#333333]">
          {" "}
          deep-refactor login
        </span>
      </header>
      <text className="text-gray-400 mt-8 w-3/4">
        The <span className="font-bold">login</span> command is your entry point
        to using the Deep Refactor CLI. It securely authenticates your session,
        allowing you to begin analyzing your projects with our suite of tools.
      </text>
      <header className="text-[1.5em] text-white mt-8">Usage:</header>
      <Flex>
        <Code className="mt-4 p-2 px-4">deep-refactor login</Code>
      </Flex>
      <header className="text-[1.5em] text-white mt-8">How to use:</header>
      <ol className="text-gray-400 gap-sm mt-4 list-decimal list-inside">
        <li className="mt-4">
          Run the
          <span className="px-2 py-[2px] bg-gray-900 border border-[#333333] rounded-sm ml-2 mr-2">
            login
          </span>
          command in your terminal.
        </li>
        <li className="mt-4">When prompted, enter your Deep Refactor API key.</li>
        <li className="mt-4">Once authenticated, you're ready to use all the features of the CLI!</li>
      </ol>
      <text className="text-violet-700 mt-8 w-3/4">
        If you regenerate your API key, you will need to re-authenticate your API key locally, which can be done by running the login command again.
      </text>
    </div>
  );
}

export default DocumentationLogin;

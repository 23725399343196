import {
  Button,
  Divider,
  Flex,
  MultiSelect,
  Box,
  rem,
  Text,
  TextInput,
  Title,
  CloseButton,
  Checkbox,
  Card,
  Image,
  Tooltip,
  Badge,
} from "@mantine/core";
import React, { forwardRef, useState } from "react";
import {
  FaAngular,
  FaEmber,
  FaMeteor,
  FaNodeJs,
  FaReact,
  FaVuejs,
} from "react-icons/fa";
import { FaCode, FaBug, FaLock, FaSitemap, FaSyncAlt } from "react-icons/fa";
import { SlDocs } from "react-icons/sl";
import { GoPackageDependencies } from "react-icons/go";
import { TbBrandSpeedtest } from "react-icons/tb";
import { TbBrandDjango, TbBrandNextjs, TbBrandTailwind } from "react-icons/tb";
import { BsBootstrap, BsGraphUpArrow } from "react-icons/bs";
import Icon from "../Components/Icon";
import analysisData from "./Analysis.json";
import { AiOutlineUser } from "react-icons/ai";
import TestGen from "../Images/TestGen.jpg";
import Architecture from "../Images/Architecture.jpg";
import AutoDoc from "../Images/AutoDoc.jpg";
import DesignInsight from "../Images/DesignInsight.jpg";
import { IconCheck, IconX } from "@tabler/icons-react";

const frameworks = [
  { value: "React", label: "React", group: "JavaScript", logo: <FaReact /> },
  {
    value: "Next.js",
    label: "Next.js",
    group: "JavaScript",
    logo: <TbBrandNextjs />,
  },
  {
    value: "Angular",
    label: "Angular",
    group: "JavaScript",
    logo: <FaAngular />,
  },
  { value: "Vue.js", label: "Vue.js", group: "JavaScript", logo: <FaVuejs /> },
  {
    value: "Node.js",
    label: "Node.js",
    group: "JavaScript",
    logo: <FaNodeJs />,
  },
  {
    value: "Ember.js",
    label: "Ember.js",
    group: "JavaScript",
    logo: <FaEmber />,
  },
  { value: "Meteor", label: "Meteor", group: "JavaScript", logo: <FaMeteor /> },
  {
    value: "Backbone.js",
    label: "Backbone.js",
    group: "JavaScript",
    logo: <Icon brand={"Backbone"} />,
  },
  {
    value: "Django",
    label: "Django",
    group: "Python",
    logo: <TbBrandDjango />,
  },
  {
    value: "Flask",
    label: "Flask",
    group: "Python",
    logo: <Icon brand={"Flask"} />,
  },
  {
    value: "Pyramid",
    label: "Pyramid",
    group: "Python",
    logo: <Icon brand={"Pyramid"} />,
  },
  {
    value: "FastAPI",
    label: "FastAPI",
    group: "Python",
    logo: <Icon brand={"FastAPI"} />,
  },
  {
    value: "Spring",
    label: "Spring",
    group: "Java",
    logo: <Icon brand={"Spring"} />,
  },
  {
    value: "Hibernate",
    label: "Hibernate",
    group: "Java",
    logo: <Icon brand={"Hibernate"} />,
  },
  {
    value: "Struts",
    label: "Struts",
    group: "Java",
    logo: <Icon brand={"Struts"} />,
  },
  {
    value: "JavaFX",
    label: "JavaFX",
    group: "Java",
    logo: <Icon brand={"JavaFX"} />,
  },
  {
    value: "Laravel",
    label: "Laravel",
    group: "PHP",
    logo: <Icon brand={"Laravel"} />,
  },
  {
    value: "Symfony",
    label: "Symfony",
    group: "PHP",
    logo: <Icon brand={"Symfony"} />,
  },
  {
    value: "CodeIgniter",
    label: "CodeIgniter",
    group: "PHP",
    logo: <Icon brand={"CodeIgniter"} />,
  },
  { value: "Yii", label: "Yii", group: "PHP", logo: <Icon brand={"Yii"} /> },
  {
    value: "Phalcon",
    label: "Phalcon",
    group: "PHP",
    logo: <Icon brand={"Phalcon"} />,
  },
  {
    value: "Express",
    label: "Express",
    group: "Node.js",
    logo: <Icon brand={"Express"} />,
  },
  {
    value: "Deno",
    label: "Deno",
    group: "Node.js",
    logo: <Icon brand={"Deno"} />,
  },
  { value: ".NET", label: ".NET", group: "C#", logo: <Icon brand={"NET"} /> },
  {
    value: "Unity",
    label: "Unity",
    group: "C#",
    logo: <Icon brand={"Unity"} />,
  },
  {
    value: "Xamarin",
    label: "Xamarin",
    group: "C#",
    logo: <Icon brand={"Xamarin"} />,
  },
  { value: "QT", label: "QT", group: "C++", logo: <Icon brand={"Qt"} /> },
  {
    value: "Boost",
    label: "Boost",
    group: "C++",
    logo: <Icon brand={"Boost"} />,
  },
  { value: "MFC", label: "MFC", group: "C++", logo: <Icon brand={"MFC"} /> },
  {
    value: "Bootstrap",
    label: "Bootstrap",
    group: "CSS",
    logo: <BsBootstrap />,
  },
  {
    value: "Tailwind CSS",
    label: "Tailwind CSS",
    group: "CSS",
    logo: <TbBrandTailwind />,
  },
  {
    value: "Foundation",
    label: "Foundation",
    group: "CSS",
    logo: <Icon brand={"Foundation"} />,
  },
  {
    value: "Bulma",
    label: "Bulma",
    group: "CSS",
    logo: <Icon brand={"Bulma"} />,
  },
  {
    value: "Materialize CSS",
    label: "Materialize CSS",
    group: "CSS",
    logo: <Icon brand={"Materialize_CSS"} />,
  },
  {
    value: "Ruby on Rails",
    label: "Ruby on Rails",
    group: "Ruby",
    logo: <Icon brand={"Rails"} />,
  },
  {
    value: "Sinatra",
    label: "Sinatra",
    group: "Ruby",
    logo: <Icon brand={"Sinatra"} />,
  },
  {
    value: "Hanami",
    label: "Hanami",
    group: "Ruby",
    logo: <Icon brand={"Hanami"} />,
  },
];

function Value({ value, label, onRemove, classNames, ...others }) {
  const logo = frameworks.find((framework) => framework.value === value).logo;

  return (
    <div {...others}>
      <Box
        sx={(theme) => ({
          display: "flex",
          cursor: "default",
          alignItems: "center",
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          border: `${rem(1)} solid ${
            theme.colorScheme === "dark"
              ? theme.colors.dark[7]
              : theme.colors.gray[4]
          }`,
          paddingLeft: theme.spacing.xs,
          borderRadius: theme.radius.sm,
        })}
      >
        <Box mr={10}>{logo}</Box>
        <Box sx={{ lineHeight: 1, fontSize: rem(12) }}>{label}</Box>
        <CloseButton
          onMouseDown={onRemove}
          variant="transparent"
          size={22}
          iconSize={14}
          tabIndex={-1}
        />
      </Box>
    </div>
  );
}

const Item = forwardRef(({ label, value, ...others }, ref) => {
  const logo = frameworks.find((framework) => framework.value === value).logo;
  return (
    <div ref={ref} {...others}>
      <Flex align="center">
        <Box mr={10}>{logo}</Box>
        <div>{label}</div>
      </Flex>
    </div>
  );
});

function RepositoryForm({
  repoData,
  id,
  selectedValues,
  setSelectedValues,
  projectName,
  setProjectName,
  selectedFrameworks,
  setSelectedFrameworks,
  handleSubmit,
  activatedFeatures,
  toggleFeatureActivation,
  userData,
  url,
  setURL
}) {
  const iconMapper = {
    CodeQuality: <FaCode color="#7521BC" />,
    DocumentationQuality: <SlDocs color="#7521BC" />,
    BugDetection: <FaBug color="#7521BC" />,
    SecurityAnalysis: <FaLock color="#7521BC" />,
    TestCoverage: <TbBrandSpeedtest color="#7521BC" />,
    PerformanceAnalysis: <BsGraphUpArrow color="#7521BC" />,
    AccessibilityAnalysis: <AiOutlineUser color="7521BC" />,
    ArchitectureAnalysis: <FaSitemap color="#7521BC" />,
    ContinuousIntegration: <FaSyncAlt color="#7521BC" />,
    DependencyAnalysis: <GoPackageDependencies color="#7521BC" />,
  };

  function getUserFriendlyTitle(promptId) {
    if (!promptId) return "";

    const formattedId = promptId
      .split(/(?=[A-Z])/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");

    return formattedId;
  }

  const premiumFeatures = [
    {
      title: "Auto Documentation",
      description:
        "Streamline code documentation process, keeping your project documentation synchronized with every update.",
      icon: Architecture,
      disabled: false,
    },
    {
      title: "Architecture Scale",
      description:
        "Keep your project's structure agile and efficient with our architectural analysis tool, guiding you towards a scalable and maintainable codebase.",
      icon: AutoDoc,
      disabled: false,
    },
    {
      title: "Design Insight",
      description:
        "Elevate your website's user experience. Our tool meticulously analyzes your UI and provides suggestions to improve your design.",
      icon: DesignInsight,
      disabled: false,
    },
    {
      title: "Test Generation",
      description:
        "Test case generation, ensuring every line of code functions as intended.",
      icon: TestGen,
      disabled: true,
    },
  ];

  const [hoveredCard, setHoveredCard] = useState(null);

  const handleCardHover = (type) => {
    setHoveredCard(type);
  };

  const handleChange = (values) => {
    if (values.length <= 2) {
      setSelectedValues(values);
    }
    console.log("values", values);
  };

  const isDisabled = (value) => {
    return selectedValues.length >= 2 && !selectedValues.includes(value);
  };

  return (
    <Flex
      direction={"column"}
      w={"100%"}
      mih={"100%"}
      h={"100%"}
      pb={"sm"}
      p={"md"}
    >
      <Title order={3} fw={500}>
        Import Repository
      </Title>
      <Divider mt={"lg"} mb={"xl"} w={"100%"} />
      <Title order={4} fw={400}>
        Repository Details
      </Title>
      <TextInput
        label={"Repository Name"}
        value={projectName}
        mt={"lg"}
        withAsterisk
        onChange={(event) => setProjectName(event.currentTarget.value)}
      />

      <MultiSelect
        clearable
        placeholder="Select all frameworks used in repository"
        searchable
        mt={"lg"}
        label={"Frameworks"}
        data={frameworks}
        groupBy={(item) => item.group}
        value={selectedFrameworks}
        onChange={(values) => setSelectedFrameworks(values)}
        valueComponent={Value}
        itemComponent={Item}
      />
      <Divider mt={"2em"} mb={"xl"} w={"100%"} />
      <Title order={4} fw={400}>
        Premium Features
      </Title>
      <Flex
        align={"center"}
        gap={"lg"}
        w={"100%"}
        mih={300}
        style={{ overflowY: "auto" }}
        p={"sm"}
      >
        {premiumFeatures.map((feature, index) => (
          <Flex
            style={{
              borderRadius: "5px",
              border: "solid 1px #373737",
              position: "relative",
              opacity:
                userData?.account_type === "free" || feature.disabled ? 0.5 : 1,
              transition: "transform 0.3s, box-shadow 0.3s",
              transform:
                hoveredCard === feature.title ? "scale(1.01)" : "scale(1)",
              boxShadow:
                hoveredCard === feature.title
                  ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                  : "none",
            }}
            onMouseEnter={() => handleCardHover(feature.title)}
            onMouseLeave={() => handleCardHover(null)}
            w={270}
            h={270}
            mih={270}
            miw={270}
            direction={"column"}
            p={"md"}
            key={index}
            bg={"#1E1E1E"}
          >
            <Flex
              w={45}
              h={45}
              align={"center"}
              justify={"center"}
              style={{
                borderRadius: "5px",
                overflow: "hidden",
                border: "solid 1px #373737",
              }}
            >
              <Image src={feature?.icon} maw={50} mah={50} />
            </Flex>
            <Title mt={"0.5em"} size={"1.2em"} fw={400}>
              {feature?.title}
            </Title>
            <Text mt={"0.5em"} size={"0.85em"} fw={300}>
              {feature?.description}
            </Text>
            <Button
              disabled={userData?.account_type === "free" || feature.disabled}
              color="violet.8"
              variant={"outline"}
              style={{ position: "absolute", bottom: "1.5em" }}
              mt={"0.5em"}
              onClick={() => toggleFeatureActivation(feature.title)}
              leftIcon={
                !feature.disabled &&
                (activatedFeatures[feature.title] ? <IconX /> : <IconCheck />)
              }
            >
              {feature.disabled
                ? "Under Construction"
                : activatedFeatures[feature.title]
                ? "Disable Feature"
                : "Enable Feature"}
            </Button>
          </Flex>
        ))}
      </Flex>

      {activatedFeatures["Design Insight"] && (
        <>
          <Divider mt={"1em"} mb={"xl"} w={"100%"} />

          <Title order={4} fw={400}>
            Design Insight
          </Title>
          <TextInput
            label={"Website URL"}
            placeholder="https://www.example.com"
            value={url}
            mt={"lg"}
            withAsterisk
            onChange={(event) => setURL(event.currentTarget.value)}
            mb={"lg"}
          />
        </>
      )}

      <Divider mt={"1em"} mb={"xl"} w={"100%"} />
      <Title order={4} fw={400}>
        Analytics
      </Title>
      <Text fw={300} fz={"sm"}>
        Choose up to <b>2</b> types of code assessments for your repository.{" "}
        <span style={{ color: "red" }}>*</span>
      </Text>
      <Checkbox.Group
        mt={"md"}
        defaultValue={["CodeQuality"]}
        onChange={handleChange}
      >
        <Flex p={"sm"} w={"100%"} h={"100%"} gap={"lg"} wrap={"wrap"}>
          {analysisData.map((analysis) => (
            <Card
              shadow="xs"
              w={290}
              h={190}
              withBorder
              bg={"#1E1E1E"}
              key={analysis.type}
              style={{
                borderColor: selectedValues.includes(analysis.type)
                  ? "#7521BC"
                  : "",
                transition: "transform 0.3s, box-shadow 0.3s",
                transform:
                  hoveredCard === analysis.type ? "scale(1.01)" : "scale(1)",
                boxShadow:
                  hoveredCard === analysis.type
                    ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onMouseEnter={() => handleCardHover(analysis.type)}
              onMouseLeave={() => handleCardHover(null)}
            >
              <Flex direction={"column"} gap={"md"}>
                <Flex align={"center"} gap={"sm"} justify={"space-between"}>
                  <Flex align={"center"} gap={"sm"}>
                    {iconMapper[analysis.type]}
                    <Title fw={400} order={5}>
                      {getUserFriendlyTitle(analysis.type)}
                    </Title>
                  </Flex>
                  <Checkbox
                    value={analysis.type}
                    isChecked={selectedValues.includes(analysis.type)}
                    disabled={isDisabled(analysis.type)}
                    color="violet.8"
                  />
                </Flex>
                <Divider w={"55%"} />
                <Text size={"sm"}>{analysis.Description}</Text>
              </Flex>
            </Card>
          ))}
        </Flex>
      </Checkbox.Group>
      <Divider mt={"2em"} mb={"xl"} w={"100%"} />
      <Flex
        gap={"sm"}
        direction={"column"}
        w={"100%"}
        mb={"2em"}
        justify={"center"}
      >
        <Text fw={300} fz={"sm"} color="red.8">
          {userData?.account_type == "free" &&
          (userData?.analysis_count >= 10 || userData?.repo_count >= 2)
            ? "You either have the maximum number of allowed repositories or no assessments left for the month."
            : selectedValues.length == 0
            ? "Select at least 1 code assessment."
            : ""}
        </Text>
        <Button
          w={"100%"}
          size={"md"}
          color={"violet.8"}
          disabled={
            selectedValues.length == 0 ||
            (userData?.account_type == "free" &&
              (userData?.analysis_count >= 10 || userData?.repo_count >= 2))
          }
          onClick={() => {
            handleSubmit();
          }}
          className="bg-violet-800"
        >
          Analyze
        </Button>
      </Flex>
    </Flex>
  );
}

export default RepositoryForm;
